import Document from "@tiptap/extension-document";
import Heading from "@tiptap/extension-heading";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import { EditorContent, useEditor } from "@tiptap/react";
import { Divider, styled } from "@mui/material";
import { Bold } from "@tiptap/extension-bold";
import { Italic } from "@tiptap/extension-italic";
import { BulletList } from "@tiptap/extension-bullet-list";
import { OrderedList } from "@tiptap/extension-ordered-list";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { ListItem } from "@tiptap/extension-list-item";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { Link } from "@tiptap/extension-link";
import { useCallback } from "react";

const Root = styled("div")(({ theme, openFullScreen, error }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  background: "#F0F0F0",
  borderRadius: "8px",
  "& .control-group": {
    display: "flex",
    padding: "4px 10px",
    borderBottom: "unset",
    "& .button-group": {
      display: "flex",
      gap: "4px",
      "& .heading-group": {
        display: "flex",
        gap: "4px",
        "& button": {
          fontSize: openFullScreen ? "12px" : "8px",
          padding: openFullScreen ? "4px 8px" : "2px 6px",
          border: "unset",
          borderRadius: "5px",
          background: "#3D251414",
        },
      },
      "& .bold-group": {
        display: "flex",
        "& button": {
          padding: openFullScreen ? "0px 8px" : "0px 6px",
          borderRadius: "5px",
          fontSize: openFullScreen ? "16px" : "14px",
          border: "unset",
          background: "unset",
        },
      },
      "& .italic-group": {
        display: "flex",
        "& button": {
          fontStyle: "italic",
          borderRadius: "5px",
          paddingLeft: openFullScreen ? "8px" : "6px",
          paddingRight: openFullScreen ? "11px" : "9px",
          fontSize: openFullScreen ? "16px" : "14px",
          border: "unset",
          background: "unset",
        },
      },
      "& .bullet-group": {
        "& button": {
          height: "100%",
          display: "flex",
          alignItems: "center",
          borderRadius: "5px",
          padding: openFullScreen ? "0px 4px" : "0px 2px",
          border: "unset",
          background: "unset",
          "& svg": {
            fontSize: openFullScreen ? "18px" : "16px",
          },
        },
      },
      "& .ordered-group": {
        "& button": {
          height: "100%",
          display: "flex",
          alignItems: "center",
          borderRadius: "5px",
          padding: openFullScreen ? "0px 4px" : "0px 2px",
          border: "unset",
          background: "unset",
          "& svg": {
            fontSize: openFullScreen ? "18px" : "16px",
          },
        },
      },
      "& .link-group": {
        "& button": {
          height: "100%",
          display: "flex",
          alignItems: "center",
          borderRadius: "5px",
          padding: openFullScreen ? "0px 4px" : "0px 2px",
          border: "unset",
          background: "unset",
          "& svg": {
            fontSize: openFullScreen ? "18px" : "16px",
          },
        },
      },
    },
    "& button": {
      cursor: "pointer",
    },
  },
  "& .editor-content-root": {
    height: "100%",
    maxHeight: openFullScreen ? "268px" : "140px",
  },
  "& .ProseMirror": {
    height: "100%",
    padding: openFullScreen ? "12px" : "12px",
    fontSize: openFullScreen ? "16px" : "12px",
    overflowY: "auto", // Enable vertical scrolling
    borderTop: "unset",

    /* Custom scrollbar styles */
    scrollbarWidth: "thin",
    scrollbarColor: "rgba(0, 0, 0, 0.5) transparent",

    "& div": {
      background: "black",
    },

    // Bullet List
    "& ul": {
      listStyleType: "disc",
      marginLeft: "20px",
    },

    // Ordered list
    "& ol": {
      listStyleType: "decimal",
      marginLeft: "20px",
    },
  },
  "& .ProseMirror-focused": {
    outline: "unset",
  },
  "& .is-active": {
    color: "#FFFFFF",
    background: "#3c8bff !important",
  },
}));

const TipTapEditor = ({
  openFullScreen,
  error,
  handleOnChange,
  defaultValue,
}) => {
  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Bold,
      Italic,
      BulletList,
      OrderedList,
      ListItem,
      Heading.configure({
        levels: [1, 2, 3],
      }),
      Link.configure({
        openOnClick: true,
        autolink: true,
        defaultProtocol: "https",
      }),
    ],
    content: defaultValue,
    onUpdate: ({ editor }) => {
      handleOnChange("message", editor.getHTML());
    },
  });

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <Root openFullScreen={openFullScreen} error={error}>
      <div className="control-group">
        <div className="button-group">
          <div className="heading-group">
            <button
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 1 }).run()
              }
              className={
                editor.isActive("heading", { level: 1 }) ? "is-active" : ""
              }
            >
              H1
            </button>
            <button
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
              className={
                editor.isActive("heading", { level: 2 }) ? "is-active" : ""
              }
            >
              H2
            </button>
            <button
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 3 }).run()
              }
              className={
                editor.isActive("heading", { level: 3 }) ? "is-active" : ""
              }
            >
              H3
            </button>
          </div>
          <div className="bold-group">
            <button
              onClick={() => editor.chain().focus().toggleBold().run()}
              className={editor.isActive("bold") ? "is-active" : ""}
            >
              B
            </button>
          </div>
          <div className="italic-group">
            <button
              onClick={() => editor.chain().focus().toggleItalic().run()}
              className={editor.isActive("italic") ? "is-active" : ""}
            >
              I
            </button>
          </div>
          <div className="bullet-group">
            <button
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              className={editor.isActive("bulletList") ? "is-active" : ""}
            >
              <FormatListBulletedIcon />
            </button>
          </div>
          <div className="ordered-group">
            <button
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              className={editor.isActive("orderedList") ? "is-active" : ""}
            >
              <FormatListNumberedIcon />
            </button>
          </div>
          <div className="link-group">
            <button
              onClick={setLink}
              className={editor.isActive("link") ? "is-active" : ""}
            >
              <InsertLinkIcon />
            </button>
          </div>
        </div>
      </div>

      <Divider sx={{ marginLeft: "10px", marginRight: "10px" }} />

      <EditorContent className={"editor-content-root"} editor={editor} />
    </Root>
  );
};

export default TipTapEditor;
