import axiosInstance from "../axios";

const PREFIX = "/User";

export const Registration = (body) => {
  return axiosInstance.post(`${PREFIX}/Register`, {
    ...body,
  });
};

export const Login = (body) => {
  return axiosInstance.post(`${PREFIX}/LogIn`, {
    ...body,
  });
};

export const CheckAuthorization = () => {
  return axiosInstance.get(`${PREFIX}/CheckAuthorization`);
};

export const LoginUsingFacebook = () => {
  return axiosInstance.get(`${PREFIX}/LoginUsingFacebook`);
};

export const FacebookResponse = () => {
  return axiosInstance.get(`${PREFIX}/FacebookResponse`);
};

export const LoginUsingGoogle = () => {
  return axiosInstance.get(`${PREFIX}/LoginUsingGoogle`);
};

export const GoogleResponse = () => {
  return axiosInstance.get(`${PREFIX}/GoogleResponse`);
};

export const LoginUsingLinkedIn = () => {
  return axiosInstance.get(`${PREFIX}/LoginUsingLinkedIn`);
};

export const LinkedInResponse = () => {
  return axiosInstance.get(`${PREFIX}/LinkedInResponse`);
};

export const LoginUsingX = () => {
  return axiosInstance.get(`${PREFIX}/LoginUsingX`);
};

export const XResponse = () => {
  return axiosInstance.get(`${PREFIX}/XResponse`);
};

export const LogOut = () => {
  return axiosInstance.post(`${PREFIX}/LogOut`);
};

export const GetUserFullInfo = () => {
  return axiosInstance.get(`${PREFIX}/GetUserFullInfo`);
};
