import "./App.css";
import ThemeWrapper from "./containers/ThemeWrapper";
import "./locale/i18n";
import SecondaryHeader from "./components/Header/SecondaryHeader";
import MainHeader from "./components/Header/MainHeader";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import AuthorizationModal from "./components/Auth/AuthorizationModal";
import Snackbar from "./components/Snackbar/Snackbar";
import ApplicationContainer from "./containers/ApplicationContainer";
import Chat from "./components/Chat/Chat";
import Gift from "./components/Gift/Gift";
import ScrollUp from "./components/ScrollUp/ScrollUp";
import { useEffect, useRef, useState } from "react";
import ShareToSocialNetworks from "./components/ShareToSocialNetworks/ShareToSocialNetworks";
import CookieComponent from "./components/CookieComponent";
import BonusWheelModal from "./components/BonusWheelModal/BonusWheelModal";
import MobileMainHeader from "./components/MobileMainHeader.tsx";
import { isMobile } from "react-device-detect";
import { BrowserRouter as Router } from "react-router-dom";
import MainLoader from "./components/MainLoader/MainLoader";
import Share from "./components/Share/Share";
import ShareModal from "./components/Share/ShareModal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import MobileFooter from "./components/MobileFooter";
import MaintenanceModal from "./components/MaintenanceModal";
import useAnimatedTitle from "./components/useAnimatedTitle";
import SideBar from "./components/SideBar/SideBar";
import Footer from "./components/Footer/Footer";

function App() {
  useAnimatedTitle();
  const [mainLoading, setMainLoading] = useState(true);
  const [showScrollUp, setShowScrollUp] = useState(false);
  const [openPrizeModal, setOpenPrizeModal] = useState(false);
  const [isMobileVersion, setIsMobileVersion] = useState(
    isMobile || window.innerWidth < 1000,
  );
  const cardContainerRef = useRef();
  const mainContainerRef = useRef();
  const footerRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollUp(window.scrollY >= 100);
    };

    const updateResizer = () => {
      setIsMobileVersion(isMobile || window.innerWidth < 1000);
    };

    setTimeout(() => {
      setMainLoading(false);
    }, 2000);

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateResizer);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateResizer);
    };
  }, []);

  return (
    <div>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeWrapper>
            <Snackbar>
              {mainLoading && <MainLoader isMobileVersion={isMobileVersion} />}
              <Router>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: isMobile ? "100dvh" : "100vh",
                    width: "100vw",
                  }}
                  ref={mainContainerRef}
                >
                  {!isMobileVersion && <SecondaryHeader />}
                  {!isMobileVersion && (
                    <MainHeader cardContainerRef={cardContainerRef} />
                  )}
                  {isMobileVersion && (
                    <MobileMainHeader isMobileVersion={isMobileVersion} />
                  )}
                  {/*{!isMobileVersion && <SideBar />}*/}
                  <div
                    style={{
                      maxWidth: "1240px",
                      padding: isMobileVersion ? "60px 20px 10px 20px" : "30px",
                      width: "100%",
                      height: "100%",
                      minHeight: "fit-content",
                    }}
                  >
                    <ApplicationContainer
                      setMainLoading={setMainLoading}
                      cardContainerRef={cardContainerRef}
                      isMobileVersion={isMobileVersion}
                      footerRef={footerRef}
                    />
                  </div>
                  <Footer
                    isMobileVersion={isMobileVersion}
                    footerRef={footerRef}
                  />

                  {!isMobileVersion && <ShareToSocialNetworks />}
                  <AuthorizationModal />
                  <BonusWheelModal
                    openPrizeModal={openPrizeModal}
                    setOpenPrizeModal={setOpenPrizeModal}
                  />
                  <Chat isMobileVersion={isMobileVersion} />
                  {!isMobileVersion && (
                    <Gift
                      openPrizeModal={openPrizeModal}
                      setOpenPrizeModal={setOpenPrizeModal}
                    />
                  )}
                  <Share isMobileVersion={isMobileVersion} />
                  {!isMobileVersion && <ScrollUp showScrollUp={showScrollUp} />}
                  <CookieComponent />
                  <ShareModal />
                  {isMobileVersion && (
                    <MobileFooter
                      openPrizeModal={openPrizeModal}
                      setOpenPrizeModal={setOpenPrizeModal}
                    />
                  )}
                  <MaintenanceModal />
                </div>
              </Router>
            </Snackbar>
          </ThemeWrapper>
        </LocalizationProvider>
      </Provider>
    </div>
  );
}

export default App;
