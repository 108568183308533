import { CircularProgress, LinearProgress, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";

const ButtonContainer = styled("div")(({ theme }) => ({
  height: "36px",
  padding: "5px",
  background: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "background 0.3s ease-in-out",
  cursor: "pointer",
  filter: "drop-shadow(rgba(0, 0, 0, 0.1) 0px 2px 10px)",
  width: "130px",
  gap: "10px",
  borderRadius: "12px",
  userSelect: "none",
  "&:hover": {
    background: "#c1c1c12e",
  },
  "& .MuiCircularProgress-root": {
    width: "20px !important",
    height: "20px !important",
  },
}));

const ButtonText = styled("span")(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "24px",
  color: "#6B7280",
}));

const SeeMoreButton = ({
  loading,
  currentAmount = 270,
  totalAmount = 300,
  onClick,
}) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (currentAmount) setProgress((currentAmount / totalAmount) * 100);
  }, [currentAmount]);

  const handleOnClick = () => {
    console.log("Click !");
    onClick();
  };

  return (
    <Box
      sx={{
        width: "160px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <LinearProgress variant="determinate" value={progress} />
        <span
          style={{
            fontFamily: "Trillium Web, sans-serif !important",
            fontSize: "10px",
            fontWeight: 600,
            lineHeight: "24px",
            color: "#6B7280",
            display: "flex",
            justifyContent: "center",
            margin: "8px 0px 24px",
          }}
        >
          {`Displaying `}
          <span style={{ color: "#007aff", margin: "0 3px" }}>
            {currentAmount}
          </span>
          {`of`}
          <span style={{ color: "#007aff", margin: "0 3px" }}>
            {totalAmount}
          </span>
          {`Products`}
        </span>
      </Box>
      {currentAmount !== totalAmount && (
        <ButtonContainer
          style={{ pointerEvents: loading && "none" }}
          onClick={handleOnClick}
        >
          {loading ? (
            <>
              <CircularProgress />{" "}
              <span
                style={{
                  color: "#6B7280",
                  fontSize: "12px",
                }}
              >
                {t("loading")}
              </span>
            </>
          ) : (
            <ButtonText>{t("seeMore")}</ButtonText>
          )}
        </ButtonContainer>
      )}
    </Box>
  );
};

export default SeeMoreButton;
