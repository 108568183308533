const PlayStationLogo = () => {
  return (
    <svg
      fill="#000000"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width="64px"
      height="64px"
      viewBox="0 0 37.951 37.951"
      xmlSpace="preserve"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g>
          {" "}
          <path d="M13.228,26.882l-0.045,2.895c0,0-1.778,0.918-5.747,0.873C3.469,30.603-0.043,29.501,0,27.108 c0.045-2.391,6.568-5.012,13.136-6.113v3.355l-6.979,1.838c0,0-1.194,1.521,0.411,1.564C10.22,27.858,13.228,26.882,13.228,26.882z M23.399,18.928c0,0,1.003,1.657,3.649,1.38c0.946-0.1,3.647-3.496,1.914-8.735c-1.731-5.239-14.321-7.354-14.321-7.354v27.033 l5.792,2.482l0.091-24.46l2.874,1.195L23.399,18.928L23.399,18.928z M29.283,21.962c0,0-3.828,0.188-7.298,1.701 c-0.032,0.016,0,2.852,0,2.852l8.803-2.068l3.103,1.379l-11.949,4.186v3.17c0,0,13.637-4.414,15.644-6.113 C39.589,25.364,32.886,21.136,29.283,21.962z"></path>{" "}
        </g>{" "}
      </g>
    </svg>
  );
};

export default PlayStationLogo;
