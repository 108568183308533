import PromotionPage from "../../components/Promotion/PromotionPage";
import { useState } from "react";

const PromotionContainer = ({ cardContainerRef }) => {
  const [data, setData] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]);
  return <PromotionPage data={data} cardContainerRef={cardContainerRef} />;
};

export default PromotionContainer;
