import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
}));

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  "& img": {
    borderRadius: "10px",
  },

  "& p": {
    fontSize: "20px",
    fontWeight: 700,
    textAlign: "center",
  },
}));

const ProductWillBeAddedSoon = () => {
  const { t } = useTranslation();
  return (
    <Root>
      <Container>
        <p>{t("productWillBeAddedSoon")}</p>
        <img
          src={"assets/product-will-be-add-soon.png"}
          alt={"product will be soon image"}
          width={"100%"}
        />
      </Container>
    </Root>
  );
};

export default ProductWillBeAddedSoon;
