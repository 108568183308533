import { styled } from "@mui/material";

const Root = styled("div")(({ theme }) => ({
  width: "38px",
  height: "38px",
  border: "1px solid #dadce0",
  background: "#fff",
  borderRadius: "100%",
  cursor: "pointer",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const ExternalAuthorizationContainer = ({ onClick, children }) => {
  return <Root onClick={() => onClick && onClick()}>{children}</Root>;
};

export default ExternalAuthorizationContainer;
