import { IconButton, styled, Typography } from "@mui/material";
import ChangeLanguageContainer from "./Language/ChangeLanguageContainer";
import { BasketIcon } from "../svgIcons";
import MainSearchField from "./MainSearchField";
import { useNavigate } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  position: "fixed",
  zIndex: 18,
  padding: "0px 20px",
  background: "rgba(233,239,249,0.8)",
  maxHeight: "50px",
  height: "50px",
  backdropFilter: "blur(2px)",
}));

const MainContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
}));

const MenuItem = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& svg": {
    color: "#000",
  },
}));

const LeftContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "start",
  flex: 1,
  gap: "10px",
}));

const RightContentContainer = styled(LeftContentContainer)(({ theme }) => ({
  justifyContent: "end",
  gap: "0px",
}));

const MobileMainHeader = ({ isMobileVersion }) => {
  const navigate = useNavigate();

  return (
    <Root>
      <MainContentContainer>
        <LeftContentContainer>
          {/*<MenuItem>
            <IconButton>
              <MenuIcon />
            </IconButton>
          </MenuItem>*/}
          <MenuItem>
            <Typography
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => navigate("/")}
            >
              <span
                style={{
                  fontWeight: 900,
                  color: "#000",
                  fontFamily: "cursive",
                }}
              >
                MOBILE
              </span>
              <span
                style={{
                  fontWeight: 900,
                  fontFamily: "cursive",
                  color: "#3281fed9",
                }}
              >
                STORE
              </span>
            </Typography>
          </MenuItem>
        </LeftContentContainer>
        <RightContentContainer>
          <MenuItem>
            <MainSearchField mobileMode={true} />
          </MenuItem>
          <MenuItem>
            <IconButton onClick={() => navigate("/cart")}>
              <BasketIcon style={{ stroke: "#313131" }} />
            </IconButton>
          </MenuItem>
          <MenuItem>
            <ChangeLanguageContainer isMobileVersion={isMobileVersion} />
          </MenuItem>
        </RightContentContainer>
      </MainContentContainer>
    </Root>
  );
};

export default MobileMainHeader;
