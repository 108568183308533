import { styled } from "@mui/material";
import { MenuItemsInfo } from "../SideBar/SideBar";
import { useNavigate } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  paddingTop: "20px",
}));

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

const Item = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  fontSize: "14px",

  "& svg": {
    width: "27px",
    height: "27px",
  },
}));

const CategoryMobilePage = () => {
  const navigate = useNavigate();

  return (
    <Root>
      <Container>
        {MenuItemsInfo.map((item, index) => {
          return (
            <Item key={index} onClick={() => navigate(item.path)}>
              {item.icon} {item.title}
            </Item>
          );
        })}
      </Container>
    </Root>
  );
};

export default CategoryMobilePage;
