const XBoxLogoIcon = () => {
  return (
    <svg
      width="64px"
      height="64px"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <circle
          opacity="0.852"
          cx="16"
          cy="16"
          r="14"
          fill="url(#paint0_radial_87_8329)"
        ></circle>
        <path
          d="M6.75137 26.5333C6.73227 26.5193 6.7127 26.5016 6.69312 26.4801C6.33073 26.1504 5.74045 25.6304 5.12362 24.8444C4.5909 23.6889 4.89287 21.748 6.20386 19.0075C8.05357 15.1409 11.0131 11.2151 12.2265 9.8225C11.8813 9.43732 10.8119 8.38253 9.29659 7.24477C7.7813 6.10701 6.67738 5.99047 6.29264 6.05961C6.64779 5.67443 7.47646 4.94852 8.17196 4.45964C10.3265 3.39299 14.3081 5.19543 16.0148 6.22257V13.2891C11.6642 16.6372 7.63924 21.8075 6.82536 23.8222C6.20418 25.36 6.44975 26.2128 6.69312 26.4801C6.71325 26.4984 6.73269 26.5161 6.75137 26.5333Z"
          fill="url(#paint1_linear_87_8329)"
        ></path>
        <path
          d="M6.75137 26.5331C6.73227 26.5191 6.7127 26.5014 6.69311 26.4799C6.33072 26.1502 5.74045 25.6302 5.12362 24.8442C4.5909 23.6887 4.89287 21.7477 6.20386 19.0073C8.35133 14.5183 12.2265 9.82227 12.2265 9.82227C12.2265 9.82227 12.9185 11.5405 14.4888 14.5183C10.4444 18.1034 7.63923 21.8072 6.82536 23.822C6.20418 25.3598 6.44974 26.2126 6.69311 26.4799C6.71325 26.4982 6.73268 26.5159 6.75137 26.5331Z"
          fill="url(#paint2_linear_87_8329)"
        ></path>
        <path
          d="M25.2779 26.5333C25.297 26.5193 25.3166 26.5016 25.3362 26.4801C25.6986 26.1504 26.2888 25.6304 26.9057 24.8444C27.4384 23.6889 27.1364 21.748 25.8254 19.0075C23.9757 15.1409 21.0162 11.2151 19.8028 9.8225C20.148 9.43732 21.2174 8.38253 22.7327 7.24477C24.248 6.10701 25.3519 5.99047 25.7367 6.05961C25.3815 5.67443 24.5528 4.94852 23.8573 4.45964C21.7028 3.39299 17.7212 5.19543 16.0145 6.22257V13.2891C20.3651 16.6372 24.3901 21.8075 25.2039 23.8222C25.8251 25.36 25.5796 26.2128 25.3362 26.4801C25.316 26.4984 25.2966 26.5161 25.2779 26.5333Z"
          fill="url(#paint3_linear_87_8329)"
        ></path>
        <path
          d="M25.2779 26.5331C25.297 26.5191 25.3166 26.5014 25.3362 26.4799C25.6986 26.1502 26.2888 25.6302 26.9057 24.8442C27.4384 23.6887 27.1364 21.7477 25.8254 19.0073C23.678 14.5183 19.8028 9.82227 19.8028 9.82227C19.8028 9.82227 19.1108 11.5405 17.5405 14.5183C21.5849 18.1034 24.3901 21.8072 25.2039 23.822C25.8251 25.3598 25.5796 26.2126 25.3362 26.4799C25.3161 26.4982 25.2966 26.5159 25.2779 26.5331Z"
          fill="url(#paint4_linear_87_8329)"
        ></path>
        <defs>
          <radialGradient
            id="paint0_radial_87_8329"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(16 14.5) rotate(90.0548) scale(15.5)"
          >
            <stop stopColor="#FAFAFA"></stop>
            <stop offset="0.499976" stopColor="#EFEFEF"></stop>
            <stop offset="0.828794" stopColor="#C0BEC0"></stop>
            <stop offset="1" stopColor="#879288"></stop>
          </radialGradient>
          <linearGradient
            id="paint1_linear_87_8329"
            x1="12.9925"
            y1="4.72589"
            x2="13.2296"
            y2="12.7852"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#008B00"></stop>
            <stop offset="1" stopColor="#6CC329"></stop>
          </linearGradient>
          <linearGradient
            id="paint2_linear_87_8329"
            x1="10.1481"
            y1="12.6667"
            x2="12.874"
            y2="16.4"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#008C00"></stop>
            <stop offset="1" stopColor="#48BF21"></stop>
          </linearGradient>
          <linearGradient
            id="paint3_linear_87_8329"
            x1="19.0368"
            y1="4.72589"
            x2="18.7997"
            y2="12.7852"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#008B00"></stop>
            <stop offset="1" stopColor="#6CC329"></stop>
          </linearGradient>
          <linearGradient
            id="paint4_linear_87_8329"
            x1="21.8812"
            y1="12.6667"
            x2="19.1553"
            y2="16.4"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#008C00"></stop>
            <stop offset="1" stopColor="#48BF21"></stop>
          </linearGradient>
        </defs>
      </g>
    </svg>
  );
};

export default XBoxLogoIcon;
