import { Divider, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const HeaderText = styled("p")({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
});

const CustomDivider = styled(Divider)(({ theme }) => ({
  marginTop: "16px",
  borderColor: "#cccccc",
  marginBottom: "60px",
}));

const CareerPage = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <HeaderText>{t("career")}</HeaderText>
      <CustomDivider />
      // TODO display additional texts
    </Root>
  );
};

export default CareerPage;
