import { styled } from "@mui/material";
import FacebookFooterIcon from "../../api/ui/svgIcons/FacebookFooterIcon";
import WhatsAppIcon from "../../api/ui/svgIcons/WhatsAppIcon";
import TelegramIcon from "../../api/ui/svgIcons/TelegramIcon";
import ViberIcon from "../../api/ui/svgIcons/ViberIcon";
import { ChangeShareModalInfo } from "../../redux/actions/mainAction";
import { useDispatch } from "react-redux";

const Root = styled("div")(({ theme }) => ({
  position: "fixed",
  zIndex: 20,
  right: 0,
  top: "calc(100vh / 2 - 160px)",
  display: "flex",
  flexDirection: "column",
}));

const Item = styled("div")(({ theme }) => ({
  width: "40px",
  height: "40px",
  cursor: "pointer",
  transition: "width 0.3s ease-in-out, transform 0.3s ease-in-out",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 30,
  "&:hover": {
    transform: "scale(1.1)",
  },
  "& svg": {
    width: "20px",
    height: "20px",
  },
}));

const ShareToSocialNetworks = () => {
  const dispatch = useDispatch();
  const onOpenShareModal = (network) => {
    dispatch(ChangeShareModalInfo(network));
  };
  return (
    <Root>
      <Item
        style={{
          background: "#365493",
          borderTopLeftRadius: "8px",
        }}
        onClick={() => onOpenShareModal("facebook")}
      >
        <FacebookFooterIcon />
      </Item>
      <Item
        style={{
          background: "#1EBEA5",
        }}
        onClick={() => onOpenShareModal("whatsapp")}
      >
        <WhatsAppIcon />
      </Item>
      <Item
        style={{
          background: "#37AEE2",
        }}
        onClick={() => onOpenShareModal("telegram")}
      >
        <TelegramIcon />
      </Item>
      <Item
        style={{
          background: "#834995",
          borderBottomLeftRadius: "8px",
        }}
        onClick={() => onOpenShareModal("viber")}
      >
        <ViberIcon />
      </Item>
    </Root>
  );
};

export default ShareToSocialNetworks;
