import axiosInstance from "../axios";

const PREFIX = "/Wheel";

export const GetAllWheelPrizes = () => {
  return axiosInstance.get(`${PREFIX}/GetAllWheelPrizesForUsers`);
};

export const SpinWheel = () => {
  return axiosInstance.post(`${PREFIX}/SpinWheel`);
};
