import Box from "@mui/material/Box";
import { Divider, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const HeaderText = styled("p")({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
});

const CustomDivider = styled(Divider)(({ theme }) => ({
  marginTop: "16px",
  borderColor: "#cccccc",
}));

const TermsAndConditionsPage = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <HeaderText>{t("termsAndConditions")}</HeaderText>
      <CustomDivider />
    </Box>
  );
};

export default TermsAndConditionsPage;
