import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const Root = styled("div")(({ theme }) => ({
  width: "300px",
  height: "100%",
  display: "flex",
  background: "rgba(237, 235, 235, 0.74)",
  padding: "10px",
  borderRadius: "5px",
}));

const UserPrizeCard = ({ key, item }) => {
  const { t } = useTranslation();

  const imageSrc = `data:${item.fileFormat};base64,${item.image}`;

  const formatDate = (infoDate) => {
    const date = new Date(infoDate);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const getText = (item) => {
    if (!item) return;
    if (item.type === "ticket") {
      return `${item.count} ${t("wonTicket")}`;
    } else if (item.type === "coin") {
      return `${item.count} ${t("wonCoins")}`;
    } else {
      return `${item.count}% ${t("wonOffer")}`;
    }
  };

  return (
    <Root key={key}>
      <div
        key={key}
        style={{
          display: "flex",
          gap: "20px",
        }}
      >
        <img src={imageSrc} alt={"image"} width={"40px"} height={"40px"} />
        <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <span style={{ fontWeight: 400 }}>{getText(item)}</span>
          <span style={{ fontWeight: 200, fontSize: "14px" }}>
            {formatDate(item.createdTime)}
          </span>
        </div>
      </div>
    </Root>
  );
};

export default UserPrizeCard;
