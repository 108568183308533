import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import CompareIcon from "../../api/ui/svgIcons/CompareIcon";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useDispatch } from "react-redux";
import { saveProduct } from "../../redux/actions/savedProductsAction";
import { getLocalStorageValue } from "../../util/appUtil";
import { useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { updateMaintenanceModalOpen } from "../../redux/actions/mainAction";

const Root = styled("div")(({ theme, viewMode }) => ({
  width: "160px",
  display: "flex",
  boxSizing: "border-box",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "column",
  /*  "&:hover": {
                                WebkitBoxShadow: "0px 0px 16px 4px rgba(161,166,255,1)",
                                MozBoxShadow: "0px 0px 16px 4px rgba(161,166,255,1)",
                                boxShadow: "0px 0px 16px 4px rgba(161,166,255,1)",
                              },*/
  ...(viewMode === "row" && {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  }),
}));

const ImgContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  marginBottom: "10px",
}));

const ContentContainer = styled("div")(({ theme, viewMode }) => ({
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  ...(viewMode === "row" && {
    marginLeft: "20px",
  }),
}));

const PriceContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "10px",
}));

const PriceText = styled("div")(({ theme, hasDiscount }) => ({
  color: hasDiscount ? "red" : "#000",
  fontWeight: 600,
  fontSize: "16px",
  display: "flex",
  alignItems: "center",
}));

const DiscountPriceText = styled("div")(({ theme }) => ({
  color: "rgba(0,0,0,.7)",
  fontSize: "12px",
  display: "flex",
  alignItems: "center",
  textDecoration: "line-through",
  textDecorationColor: "red",
}));

const WithBankContainer = styled("div")(({ theme }) => ({
  height: "15px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  "& span": {
    color: "#000",
    fontSize: "8px",
    fontWeight: 400,
  },
}));

const DescriptionContainer = styled("div")(({ theme }) => ({
  opacity: 0.8,
  height: "36px",
  fontSize: "12px",
  maxHeight: "36px",
  overflow: "hidden",
  fontWeight: 500,
  color: "#005",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
}));

const FooterContainer = styled("div")(({ viewMode }) => ({
  marginTop: "8px",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  boxSizing: "border-box",
  ...(viewMode === "row" && {
    marginLeft: "auto",
  }),
}));

const CompareContainer = styled("div")(({ theme }) => ({
  cursor: "pointer",
  background: "#f2f2f2",
  width: "40px",
  minWidth: "40px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0.8,
  transition: "opacity 0.3s ease-in-out",
  borderRadius: "8px",
  "&:hover": {
    opacity: 1,
  },
}));

const CardContainer = styled("div")(({ theme }) => ({
  cursor: "pointer",
  background: "#1E90CC",
  width: "100%",
  minWidth: "100px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0.9,
  transition: "opacity 0.3s ease-in-out",
  gap: "5px",
  borderRadius: "8px",
  "& svg": {
    width: "14px",
    height: "14px",
    color: "#000",
  },
  "& span": {
    color: "#000",
    fontSize: "12px",
    fontWeight: 600,
  },
  "&:hover": {
    opacity: 1,
  },
}));

const AdditionInfoContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  borderRadius: "20px",
  padding: "5px 8px",
  background: "#1E90CC",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#fff",
  fontSize: "9px",
  fontWeight: 500,
  textTransform: "uppercase",
  left: 0,
  bottom: -5,
}));

const CategoryItem = ({ categoryInfo, cardContainerRef, viewMode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentImgRef = useRef();
  //img with and height 256:256
  const objExample = {
    id: 0,
    img: "",
    price: 500,
    discountPrice: 400,
    isNew: true,
    isBestPrice: false,
    isWithGift: false,
    byWithBank: 14,
    description:
      "Apple iPhone 15 Pro e-SIM only | 128GB Natural Titani Apple iPhone 15 Pro e-SIM only | 128GB Natural Titani ...",
  };

  const getTextBuyWithBank = () => {
    if (objExample.byWithBank) {
      return (
        <span>
          {t("perMonth")}:{" "}
          <span
            style={{
              color: "#4AA9FF",
            }}
          >
            {objExample.byWithBank} ₾
          </span>
        </span>
      );
    }

    return "";
  };

  const getAdditionInfoContainerText = () => {
    if (objExample.isBestPrice) return "Bets Price";
    if (objExample.isNew) return "New";
    if (objExample.isWithGift) return "Gifts";
  };

  const onSaveProduct = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const savedProductList = getLocalStorageValue("savedProductItems", "");
    if (
      !savedProductList ||
      (savedProductList &&
        !JSON.parse(savedProductList).find(
          (item) => item.id === categoryInfo.id,
        ))
    ) {
      const imgElement = currentImgRef.current;
      const imgClone = imgElement.cloneNode(true);
      const rect = imgElement.getBoundingClientRect();
      const cardRect = cardContainerRef.current.getBoundingClientRect();

      imgClone.style.position = "fixed";
      imgClone.style.top = rect.top + "px";
      imgClone.style.left = rect.left + "px";
      imgClone.style.zIndex = 999999;
      imgClone.style.opacity = "1";
      imgClone.style.borderRadius = "8px";
      imgClone.style.transition =
        "width 0.5s ease-in-out, height 0.5s ease-in-out, border-radius 0.5s ease-in-out, top 1s ease-in-out, left 1s ease-in-out, right 1s ease-in-out";

      setTimeout(() => {
        imgClone.style.width = "40px";
        imgClone.style.height = "40px";
        imgClone.style.borderRadius = "100%";
        imgClone.style.top = cardRect.top + "px";
        imgClone.style.left = cardRect.left + "px";
        imgClone.style.zIndex = 999999;
      }, 200);
      document.body.appendChild(imgClone);
      setTimeout(() => {
        document.body.removeChild(imgClone);
      }, 1200);
    }
    dispatch(saveProduct(categoryInfo));
  };

  const getImageSrc = useMemo(() => {
    if (
      categoryInfo &&
      categoryInfo.ProductImages &&
      categoryInfo.ProductImages.length > 0
    ) {
      return `data:${categoryInfo.ProductImages[0].FileFormat};base64,${categoryInfo.ProductImages[0].Image}`;
    }
    return "";
  }, [categoryInfo]);

  const onClick = () => {
    if (categoryInfo.ProductTypeEnum === 0) {
      navigate(`/mobiles/${categoryInfo.Id}`);
    }
  };

  const clickCompare = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(updateMaintenanceModalOpen(true));
  };

  return (
    <>
      <Root viewMode={viewMode} onClick={onClick}>
        <ImgContainer>
          <img
            alt={"item img"}
            src={getImageSrc}
            width={160}
            height={160}
            ref={currentImgRef}
            style={{
              cursor: "pointer",
              borderRadius: "12px",
            }}
          />
          {getAdditionInfoContainerText() && (
            <AdditionInfoContainer>
              {getAdditionInfoContainerText()}
            </AdditionInfoContainer>
          )}
        </ImgContainer>
        <ContentContainer viewMode={viewMode}>
          <PriceContainer>
            <PriceText
              hasDiscount={objExample.discountPrice}
            >{`${objExample.discountPrice || objExample.price} ₾`}</PriceText>
            {objExample.discountPrice && (
              <DiscountPriceText>{`${objExample.price} ₾`}</DiscountPriceText>
            )}
          </PriceContainer>
          <WithBankContainer>{getTextBuyWithBank()}</WithBankContainer>
          <DescriptionContainer>{objExample.description}</DescriptionContainer>
          <FooterContainer viewMode={viewMode}>
            <CompareContainer onClick={(e) => clickCompare(e)}>
              <CompareIcon />
            </CompareContainer>
            <CardContainer onClick={(e) => onSaveProduct(e)}>
              <ShoppingCartIcon />
              <span>{t("addToCart")}</span>
            </CardContainer>
          </FooterContainer>
        </ContentContainer>
      </Root>
    </>
  );
};

export default CategoryItem;
