import * as types from "../constants/mainConstants";

export const userAuthorization = (userAuthorizationInfo) => ({
  type: types.USER_AUTHORIZATION,
  payload: userAuthorizationInfo,
});

export const openLoginModal = (isOpen) => ({
  type: types.OPEN_LOGIN_MODAL,
  payload: isOpen,
});

export const openRegistrationModal = (isOpen) => ({
  type: types.OPEN_REGISTRATION_MODAL,
  payload: isOpen,
});

export const closeAuthModal = (isOpen) => ({
  type: types.CLOSE_AUTH_MODAL,
  payload: isOpen,
});

export const cookieFileIsOk = (isOk) => ({
  type: types.UPDATE_COOKIE_FILE_IS_OK,
  payload: isOk,
});

export const LogOutDispatch = () => ({
  type: types.LOG_OUT,
  payload: null,
});

export const ChangeShareModalInfo = (info) => ({
  type: types.CHANGE_SHARE_MODAL_INFO,
  payload: info,
});

export const ChangeWheelPrizes = (info) => ({
  type: types.UPDATE_WHEEL_PRIZES,
  payload: info,
});

export const updateTicketCount = (info) => ({
  type: types.UPDATE_TICKET_COUNT,
  payload: info,
});

export const updateCoinCount = (info) => ({
  type: types.UPDATE_COIN_COUNT,
  payload: info,
});

export const updateMaintenanceModalOpen = (isOpen) => ({
  type: types.UPDATE_MAINTENANCE_MODAL_OPEN,
  payload: isOpen,
});
