import Box from "@mui/material/Box";
import { UserProfileSvg } from "../../svgIcons";
import { styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LogOutDispatch } from "../../redux/actions/mainAction";

const StyledHeader = styled(Box)({
  borderBottom: "2px solid #f2f2f2",
  display: "flex",
  width: "100%",
});

const StyledHeaderText = styled(Typography)({
  display: "flex",
  alignItems: "center",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "19px",
});

const Item = styled("div")(({ theme }) => ({
  color: "#1875CC",
  display: "flex",
  gap: "10px",
  alignItems: "center",
  cursor: "pointer",
  padding: "7px",
  boxSizing: "border-box",
  fontWeight: 500,
  border: "1px solid #fff",
  borderRadius: "8px",
  transition: "border 0.3s ease-in-out, color 0.3s ease-in-out",
  "& .MuiSvgIcon-root": {
    color: "#1875CC",
    width: "20px",
    height: "20px",
  },
  "&:hover": {},
}));

const MobileProfileMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Box>
      <StyledHeader>
        <UserProfileSvg />
        <StyledHeaderText>{t("hello")}</StyledHeaderText>
      </StyledHeader>
      <Box>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
            paddingTop: "20px",
          }}
        >
          <Item
            onClick={() => {
              navigate("/user-info");
            }}
          >
            <InfoIcon />
            <span>{t("aboutMe")}</span>
          </Item>
          <Item
            onClick={() => {
              navigate("/prize");
            }}
          >
            <DonutSmallIcon />
            <span>{t("progress")}</span>
          </Item>
          <Item
            onClick={() => {
              navigate("/promo-code");
            }}
          >
            <AssessmentIcon />
            <span>{t("promoCode")}</span>
          </Item>
          <Item>
            <SettingsIcon />
            <span>{t("settings")}</span>
          </Item>
          <Item
            onClick={() => {
              dispatch(LogOutDispatch());
            }}
          >
            <LogoutIcon />
            <span>{t("logOut")}</span>
          </Item>
        </div>
      </Box>
    </Box>
  );
};

export default MobileProfileMenu;
