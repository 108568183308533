import {
  Button,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CloseIcon,
  DeleteIcon,
  EmptyBasketIcon,
  Remove2Icon,
} from "../../svgIcons";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import ContinueShoppingButton from "../Common/ContinueShoppingButton";
import CartFooter from "./CartFooter";
import CustomScrollbar from "../Common/CustomScrollbar";
import ExampleComponent from "../Common/CustomScrollbar";
import { useState } from "react";
import ConfirmationModal from "../Common/Modal/ConfirmationModal";
import CartRowQuantityCell from "./CartTable/CartRowQuantityCell";
import CartItem from "./cartItem";

const Root = styled("div")(({ isMobileVersion }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  padding: isMobileVersion ? "0" : "0 40px",
}));

const Header = styled("div")(({ isMobileVersion }) => ({
  display: "flex",
  width: "100%",
  flexDirection: isMobileVersion ? "column" : "row",
  gap: isMobileVersion ? "10px" : "",
  justifyContent: "space-between",
}));

const MainContainer = styled("div")(({}) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const CTypography = styled(Typography)(({}) => ({
  display: "flex",
  alignItems: "center",
}));

const StyledClearAllButton = styled(Button)({
  textTransform: "capitalize",
  height: "30px",
  color: "#000000c7",
  border: "1px solid #57637530",
  fontSize: "13px",
  "&:hover": {
    // border: "1px solid rgba(255,0,0,0.47)",
    // backgroundColor: "rgba(255,139,139,0.12)",
    backgroundColor: "rgba(0,0,0,0.03)",
    border: "1px solid #57637530",
    "& svg path": {
      // stroke: "red",
      // stroke: "rgba(255,0,0,0.47)",
    },
  },
  "&:active": {
    // backgroundColor: "transparent !important", // Force transparent background on active
    // boxShadow: "none",
  },
  "& .MuiTouchRipple-root": {
    // color: "rgba(255, 0, 0, 0.5)", // Ripple color
  },
  "& .MuiTouchRipple-ripple": {
    // backgroundColor: "rgba(255, 0, 0, 0.5)", // Custom ripple effect color
  },
});

const StyledTableRow = styled(TableRow)({
  position: "relative",
  // cursor: "pointer",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  // boxShadow: "rgba(0, 0, 0, 0.11) 1px 2px 3px 1px",
  "&:hover": {
    // backgroundColor: "#e6e6e675",
    // boxShadow:
    //   "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    // boxShadow:
    //   "rgba(0, 0, 0, 0.05) 0px -1px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 0px 0px",
    // boxShadow: "rgba(0, 0, 0, 0.11) 1px 2px 3px 1px",
    transform: "scale(1.02)",
    // boxShadow: "rgba(0, 0, 0, 0.2) 2px 4px 6px 2px",
    "& #actionButton": {
      display: "flex !important",
    },
  },
});

const StyledRemoveItemButton = styled(IconButton)({});

const CartPage = ({ isMobileVersion }) => {
  const { t } = useTranslation();
  const [confirmationModal, setConfirmationModal] = useState({
    isOpen: false,
    isOpenAll: false,
    item: {},
  });
  const [rows, setRows] = useState([
    {
      img: "./assets/testing_imgs/prod1.png",
      product: "Xiaomi TV A Pro 75 2025 EU",
      price: "1380",
      quantity: 2,
      total: "1380",
      id: "1",
    },
    {
      img: "./assets/testing_imgs/prod2.png",
      product: "Marshall Major V Black",
      price: "499",
      quantity: 1,
      total: "499",
      id: "12",
    },
    {
      img: "./assets/testing_imgs/prod3.png",
      product: "PS5 Wireless Controller",
      price: "189",
      quantity: 1,
      total: "189",
      id: "13",
    },
    {
      img: "./assets/testing_imgs/prod8.png",
      product: "Apple iPhone 16 e-SIM Only",
      price: "2499",
      quantity: 1,
      total: "2499",
      id: "14",
    },
    {
      img: "./assets/testing_imgs/prod8.png",
      product: "Apple iPhone 16 e-SIM Only",
      price: "2499",
      quantity: 1,
      total: "2499",
      id: "15",
    },
    {
      img: "./assets/testing_imgs/prod3.png",
      product: "PS5 Wireless Controller",
      price: "189",
      quantity: 1,
      total: "189",
      id: "17",
    },
    {
      img: "./assets/testing_imgs/prod2.png",
      product: "Marshall Major V Black",
      price: "499",
      quantity: 1,
      total: "499",
      id: "18",
    },
    {
      img: "./assets/testing_imgs/prod1.png",
      product: "Xiaomi TV A Pro 75 2025 EU",
      price: "1380",
      quantity: 2,
      total: "1380",
      id: "19",
    },
  ]);
  const columns = [
    {
      field: "img",
      headerName: "IMAGE",
      renderCell: (item) => (
        <Box>
          <img
            src={item?.img}
            alt="Product"
            style={{ width: "60px", height: "auto" }}
          />
        </Box>
      ),
    },
    { field: "product", headerName: "PRODUCT" },
    { field: "price", headerName: "PRICE" },
    {
      field: "quantity",
      headerName: "QTY",
      renderCell: (item) => <CartRowQuantityCell quantity={item?.quantity} />,
    },
    { field: "total", headerName: "TOTAL" },
  ];

  const handleDeleteSingleItem = () => {
    setRows(rows.filter((item) => item.id !== confirmationModal.item.id));
    setConfirmationModal({});
  };

  const handleDeleteAllItems = () => {
    setRows([]);
    setConfirmationModal({});
  };

  return (
    <Root isMobileVersion={isMobileVersion}>
      {rows.length > 0 ? (
        <MainContainer>
          <Header isMobileVersion={isMobileVersion}>
            <CTypography fontWeight={600} fontSize={"24px"}>
              My Cart ({rows.length})
            </CTypography>
            <Box display={"flex"} gap={"10px"} alignItems={"center"}>
              <ContinueShoppingButton />
              <StyledClearAllButton
                onClick={() => setConfirmationModal({ isOpenAll: true })}
                variant="outlined"
                disableRipple
                startIcon={
                  <DeleteIcon
                    color={"#000000c7"}
                    style={{ width: 18, height: 18 }}
                  />
                }
              >
                {t("clear")}
              </StyledClearAllButton>
            </Box>
          </Header>

          {isMobileVersion ? (
            <Box sx={{ marginTop: "20px" }}>
              {rows.map((prodItem, index) => (
                <CartItem
                  isMobileVersion={true}
                  imgSrc={prodItem.img}
                  title={prodItem.product}
                  price={prodItem.price}
                  key={index}
                  onClickDelete={() =>
                    setConfirmationModal({ item: prodItem, isOpen: true })
                  }
                />
              ))}
            </Box>
          ) : (
            <TableContainer
              component={Paper}
              // sx={{ marginTop: "10px", boxShadow: "none", }}
              sx={{
                display: "flex",
                marginTop: "10px",
                boxShadow: "none",
                height: "350px", // Set the height limit for the table
                overflow: "auto",
              }}
            >
              <CustomScrollbar>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <TableRow>
                      {columns.map((column) => {
                        return (
                          <TableCell
                            align={
                              column.field === "quantity" ? "center" : "left"
                            }
                          >
                            {column.field === "img" ? null : column.headerName}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {columns.map((column, colIndex) => {
                          const cellValue = row[column.field];
                          return (
                            <TableCell
                              key={colIndex}
                              align={
                                column.field === "quantity" ? "center" : "left"
                              }
                            >
                              {
                                column.renderCell
                                  ? column.renderCell(row) // Use renderCell if defined
                                  : cellValue // Otherwise, display the plain value
                              }
                            </TableCell>
                          );
                        })}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            height: "100%",
                            alignItems: "center",
                            position: "absolute",
                            right: "15px",
                          }}
                        >
                          <Tooltip title={t("removeProduct")}>
                            <StyledRemoveItemButton
                              id={"actionButton"}
                              sx={{ display: "none" }}
                              onClick={() =>
                                setConfirmationModal({
                                  item: row,
                                  isOpen: true,
                                })
                              }
                            >
                              <CloseIcon style={{ fill: "red" }} />
                            </StyledRemoveItemButton>
                          </Tooltip>
                        </Box>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </CustomScrollbar>
            </TableContainer>
          )}

          <CartFooter isMobileVersion={isMobileVersion} />
        </MainContainer>
      ) : (
        <EmptyBasketIcon />
      )}
      <ConfirmationModal
        open={confirmationModal.isOpen || confirmationModal.isOpenAll}
        title={confirmationModal.isOpen ? t("removeProduct") : t("clearCart")}
        message={
          confirmationModal.isOpen
            ? t("areYouSureYouWantToRemoveTheProduct")
            : t("areYouSureYouWantToClearTheCart")
        }
        onClose={() => setConfirmationModal({})}
        onConfirm={() => {
          return confirmationModal.isOpen
            ? handleDeleteSingleItem()
            : handleDeleteAllItems();
        }}
      />
    </Root>
  );
};

export default CartPage;
