import { Divider, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const COLORS = {
  PRIMARY: "#6b6b6b",
  SECONDARY_HEADER: "#1c1c1cce",
};

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const HeaderText = styled("p")({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
});

const CustomDivider = styled(Divider)(({ theme }) => ({
  marginTop: "16px",
  borderColor: "#cccccc",
  marginBottom: "30px",
}));

const SecondaryHeader = styled("p")(({}) => ({
  fontSize: "13px",
  fontWeight: 700,
  color: COLORS.SECONDARY_HEADER,
  marginBottom: "10px",
}));

const MainHeader = styled("p")(({}) => ({
  fontWeight: 700,
  color: "#5f5f5f",
  display: "flex",
  justifyContent: "center",
  margin: "10px 0px",
}));

const PrimaryText = styled("p")(({}) => ({
  fontSize: "14px",
  margin: "10px 0px",
  color: COLORS.PRIMARY,
}));

const TradePolicyPage = () => {
  const { t } = useTranslation();

  const WWW_MOBILESTORE_GE = "www.mobilestore.ge";

  return (
    <Root>
      <HeaderText>{t("tradePolicy")}</HeaderText>
      <CustomDivider />
      <Typography
        sx={{
          fontWeight: 700,
          color: "#000000",
          display: "flex",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        შპს "მობაილ სთორი" სავაჭრო პოლიტიკა
      </Typography>
      <SecondaryHeader>პრეამბულა</SecondaryHeader>
      <PrimaryText
        style={{
          fontSize: "14px",
          margin: "20px 0px",
          color: COLORS.PRIMARY,
        }}
      >
        წინამდებარე სავაჭრო პოლიტიკის დოკუმენტის მიზანია, განსაზღვროს შპს
        "მობაილ სთორი" (შემდგომში - "MOBILE STORE") და მომხმარებლებს შორის
        სამართლებრივი ურთიერთობის საფუძვლები, ხელი შეუწყოს "მობაილ სთორისა" და
        მომხმარებელებს შორის ურთიერთპატივისცემაზე დამყარებული ქცევის კულტურის
        განვითარებას, ასევე, პატივი სცეს მომხმარებლების ლეგიტიმურ მოლოდინს, რომ
        "მობაილ სთორის" ურთიერთობაში ისინი დაცული იქნებიან არაკანონიერი და
        უსამართლო მოპყრობისაგან.
        <br />
        <br />
        წინამდებარე სავაჭრო პოლიტიკა მოიცავს მომხმარებლებთან სარეწში გაფორმების
        ზოგად და დისტანციური საშუალებების გამოყენებითა და სარეწის გარეთ დადებული
        ხელშეკრულებების გაფორმების წესებს, რომლის სამართლებრივი საფუძვლები
        მოცემულია საქართველოს კანონმდებლობაში, მათ შორის, საქართველოს სამოქალაქო
        კოდექსში, "მომხმარებლის უფლებების დაცვის შესახებ" საქართველოს კანონში და
        სხვა ნორმატიულ აქტებში.
      </PrimaryText>
      <SecondaryHeader>ტერმინთა განმარტება</SecondaryHeader>
      <PrimaryText>
        წინამდებარე სავაჭრო პოლიტიკაში მოცემულ ტერმინებს აქვს შემდეგი
        მნიშვნელობა:
        <br />
        <br />
        “ვებ-გვერდი” - ნიშნავს {WWW_MOBILESTORE_GE}, რომლის მეშვეობითაც ხდება
        საქონლის გაყიდვა ან/და მომსახურების შეთავაზება/განხორციელება;
        <br />
        <br />
        "საქონელი" − ნებისმიერი მოძრავი ნივთი, აგრეთვე მასში განთავსებული ან/და
        ციფრული ფორმით გავრცელებული ქონებრივი სიკეთე;
        <br />
        <br />
        “კომპანია“ ან “მოვაჭრე” - შპს “მობაილ სთორი” (იურიდიული მისამართი: ???
        ??? ??? ), რომელიც რეგისტრირებულია საქართველოს კანონმდებლობის
        შესაბამისად და რომელსაც ეკუთვნის ვებ-გვერდი {WWW_MOBILESTORE_GE};
        <br />
        <br />
        “მომხმარებელი” - ნებისმიერი ფიზიკური პირი, რომელიც იძენს ან შემდგომ
        იყენებს საქონელს უპირატესად პირადი მოხმარების მიზნით და არა სავაჭრო,
        სამეწარმეო, სახელობო ან სხვა პროფესიული საქმიანობის განსახორციელებლად
        როგორც სარეწში ან სარეწის გარეთ, ასევე "მობაილ სთორის" ვებ-გვერდზე
        {WWW_MOBILESTORE_GE} რეგისტრაციის მეშვეობით;
        <br />
        <br />
        “მხარეები” - ნიშნავს "მობაილ სთორისა" და მომხმარებელს;
        <br />
        <br />
        “ანგარიში” - ნიშნავს მომხმარებლის მიერ "მობაილ სთორის" ვებ-გვერდზე
        რეგისტრირებულ ანგარიშს;
        <br />
        <br />
        “რეგისტრაცია” - ნიშნავს მომხმარებლის მიერ "მობაილ სთორის" ვებ-გვერდზე
        სპეციალური პროცედურის გავლასა და ანგარიშის შექმნას პროდუქტის შესყიდვის
        ან/და მომსახურების მიღების მიზნით;
        <br />
        <br />
        “ავტორიზაცია” - მომხმარებლის მიერ "მობაილ სთორის" ვებ-გვერდზე
        რეგისტრირებული ანგარიშის მეშვეობით განხორციელებული ქმედება;
        <br />
        <br />
        “დისტანციური ხელშეკრულება” − ხელშეკრულება, რომელიც დადებულია "მობაილ
        სთორისა" და მომხმარებელს შორის მხოლოდ დისტანციური კომუნიკაციის ერთი ან
        რამდენიმე საშუალების გამოყენებით, დისტანციური ნასყიდობის ან მომსახურების
        მიწოდების ორგანიზებული სქემის მეშვეობით და არ მოითხოვს მომხმარებლისა და
        მოვაჭრის ფიზიკურ თანაყოფობას. ამასთანავე, ხელშეკრულება დისტანციურ
        ხელშეკრულებად არ მიიჩნევა, თუ მომხმარებელი და "მობაილ სთორი"
        ხელშეკრულების პირობებზე სარეწში შეთანხმდნენ და ხელშეკრულება დისტანციურად
        მხოლოდ ამის შემდეგ დაიდო;
        <br />
        <br />
        “სარეწი” − ნებისმიერი უძრავი ობიექტი ან მოძრავი ობიექტი, სადაც "მობაილ
        სთორი", ჩვეულებრივ, კომერციულ საქმიანობას ახორციელებს;
        <br />
        <br />
        “საგარანტიო მომსახურება“ - ნიშნავს "მობაილ სთორის" ან მისი კონტრაქტორის
        მიერ გაყიდვის შემდეგ დაზიანებული საქონლის უფასო და აუცილებელ შეკეთებას
        საგარანტიო ტალონში მითითებული წესების მიხედვით;
        <br />
        <br />
        “პერსონალური მონაცემი” - ნებისმიერი ინფორმაცია, რომელიც უკავშირდება
        იდენტიფიცირებულ ან იდენტიფიცირებად ფიზიკურ პირს. პირი იდენტიფიცირებადია,
        როდესაც შესაძლებელია მისი იდენტიფიცირება პირდაპირ ან არაპირდაპირ,
        კერძოდ, საიდენტიფიკაციო ნომრით ან პირის მახასიათებელი ფიზიკური,
        ფიზიოლოგიური, ფსიქოლოგიური, ეკონომიკური, კულტურული ან სოციალური
        ნიშნებით;
      </PrimaryText>
      <MainHeader>I. თავი</MainHeader>
      <SecondaryHeader>ხელშეკრულების გაფორმების ზოგადი წესები</SecondaryHeader>
      <PrimaryText>
        "მობაილ სთორის" მიერ მომხმარებელს მიეწოდება სრული ინფორმაცია იმ საქონლის
        შესახებ, რომელზეც იგი გამოხატავს ინტერესს. ამისათვის, კომპანიის ტექნიკურ
        პერსონალს აქვს შესაბამისი კვალიფიკაცია და ცოდნა, რომ მომხმარებელს
        მიაწოდოს ინფორმაცია პროდუქტის მწარმოებლის, არსებითი მახასიათებლებისა და
        პარამეტრების შესახებ. ინფორმაციის მიწოდების გარდა, მას შემდეგ, რაც
        მომხმარებელი გამოხატავს სურვილს საქონლის შეძენის შესახებ, შესყიდული
        ნივთი ტექნიკური პერსონალის მიერ მომხმარებელთან ერთად დათვალიერდება და
        იგი თავდაპირველად შემოწმდება ვიზუალურ ხარვეზზე. მხოლოდ ამის შემდეგ,
        ხდება მომხმარებლის თანდასწრებით ნივთის ტესტირება, მისი შემოწმება
        ტექნიკურ გამართულობაზე.
      </PrimaryText>
      <SecondaryHeader>1. საქონლის მიწოდების ვადა</SecondaryHeader>
      <PrimaryText>
        "მობაილ სთორი" ვალდებულია მომხმარებელს მიაწოდოს საქონელი დაყოვნების
        გარეშე, მაგრამ ხელშეკრულების დადებიდან არაუგვიანეს 14 სამუშაო დღისა, თუ
        ხელშეკრულებით სხვა რამ არ არის გათვალისწინებული და მომხმარებელი ამის
        თაობაზე ინფორმირებულია და თანახმაა. იმ შემთხვევაში, თუ "მობაილ სთორმა"
        ვალდებულება ამ მუხლის პირველი პუნქტით დადგენილ ვადაში არ შეასრულა,
        მომხმარებელი უფლებამოსილია განუსაზღვროს "მობაილ სთორს" გონივრული
        დამატებითი ვადა ვალდებულების შესასრულებლად. დამატებითი ვადის გასვლის
        შემდეგ, ვალდებულების შეუსრულებლობის შემთხვევაში, მომხმარებელს უფლება
        აქვს, უარი თქვას ხელშეკრულებაზე და მოითხოვოს ზიანის ანაზღაურება
        საქართველოს სამოქალაქო კანონმდებლობით დადგენილი წესით. ამ მუხლის მე-2
        პუნქტით გათვალისწინებული დამატებითი ვადის განსაზღვრა სავალდებულო არ
        არის, თუ აშკარაა, რომ ამას არავითარი შედეგი არ ექნება, ან მომხმარებელმა
        ხელშეკრულების შესრულება საქონლის დროულად მიღებას დაუკავშირა. 4. თუ
        მომხმარებელს საქონელი იმის გამო არ მიეწოდება, რომ იგი ხელმისაწვდომი არ
        არის, "მობაილ სთორი" ვალდებულია მომხმარებელს დაუყოვნებლივ აცნობოს ამის
        შესახებ.
      </PrimaryText>
      <SecondaryHeader>2. საქონლის მიწოდების ვალდებულება</SecondaryHeader>
      <PrimaryText>
        "მობაილ სთორი" ვალდებულია გადასცეს მომხმარებელს ხელშეკრულებით
        გათვალისწინებული პირობების შესაბამისი საქონელი. საქონელი ხელშეკრულებით
        გათვალისწინებული პირობების შესაბამის საქონლად მიიჩნევა:
        <br />
        ა) თუ იგი შეესაბამება "მობაილ სთორის" მიერ მომხმარებლისთვის მიწოდებულ
        აღწერას და მას აქვს იგივე თვისებები, რომლებიც "მობაილ სთორის" მიერ
        მომხმარებლისთვის წარდგენილ საქონლის ნიმუშს ჰქონდა;
        <br />
        ბ) "მობაილ სთორის" მიერ მომხმარებლისთვის მიწოდებული აღწერის არარსებობის
        შემთხვევაში, საქონელი ხელშეკრულებით გათვალისწინებული პირობების შესაბამის
        საქონლად მიიჩნევა, თუ იგი გამოსადეგია იმ მიზნისთვის, რომლის თაობაზედაც
        მომხმარებელმა "მობაილ სთორს" ხელშეკრულების დადების დროს აცნობა და
        რომელსაც "მობაილ სთორი" დაეთანხმა.
        <br />
        გ) თუ მომხმარებელი "მობაილ სთორის" არ აცნობებს იმ მიზნის თაობაზე,
        რომლისთვისაც მას სურს საქონლის გამოყენება, მაშინ საქონელი ხელშეკრულებით
        გათვალსიწნებული პირობების შესაბამისად მიიჩნევა, თუ იგი ვარგისია იმ
        მიზნით სარგებლობისთვის, რომლისთვისაც იგი ჩვეულებრივ გამოიყენება. თუ
        საქონლის ჩვეულებრივი გამოყენების მიზანი ვერ დგინდება, საქონელი
        ხელშეკრულებით გათვალისწინებული პირობების შესაბამის საქონლად მიიჩნევა, თუ
        მას აქვს ამ სახეობის საქონლის თვისებები და ფუნქციონირებს ისე, როგორც
        აღნიშნული სახეობის საქონელი, ამასთანავე, რისი გონივრული მოლოდინიც
        მომხმარებელს შეიძლებოდა ჰქონოდა საქონლის ბუნებიდან და იმ საჯარო
        განცხადებებიდან გამომდინარე, რომლებიც გააკეთა "მობაილ სთორმა",
        მწარმოებელმა, მისმა წარმომადგენელმა ან ბაზარზე განთავსებისთვის
        პასუხისმგებელმა სხვა პირმა, მათ შორის, რეკლამის ან/და ეტიკეტის
        საშუალებით. "მობაილ სთორის" მიერ ან მისი პასუხისმგებლობით საქონლის
        არასწორად დამონტაჟების შედეგად წარმოქმნილი ნაკლი უთანაბრდება საქონლის
        ხელშეკრულებით გათვალისწინებულ პირობებთან შეუსაბამობას (ნაკლს), თუ
        საქონლის მონტაჟი ხელშეკრულების ნაწილი იყო. ეს წესი ვრცელდება აგრეთვე იმ
        საქონელზე, რომელიც მომხმარებელმა უნდა ააწყოს ან დაამონტაჟოს და რომელიც
        საქონლისთვის დართული ინსტრუქციის ნაკლოვანების გამო არასწორად აიწყო ან
        დამონტაჟდა. ამ მუხლის მიზნებისთვის საქონელი ნაკლის მქონე საქონლად არ
        მიიჩნევა, თუ ხელშეკრულების დადების მომენტში მომხმარებელმა იცოდა ან უნდა
        სცოდნოდა, რომ საქონელს ნაკლი ჰქონდა. "მობაილ სთორის არ დაეკისრება
        პასუხისმგებლობა საქონლის ან მომსახურების შესახებ საჯარო განცხადებისთვის,
        თუ:
        <br />
        ა) მას არ ჰქონდა ან ვერ ექნებოდა ინფორმაცია ამ განცხადების შესახებ;
        <br />
        ბ) ხელშეკრულების დადებამდე ამ განცხადებაში მოცემული ინფორმაცია შეიცვალა;
        <br />
        გ) ეს განცხადება ვერ მოახდენდა გავლენას მომხმარებლის მიერ საქონლის ან
        მომსახურების შეძენაზე.
      </PrimaryText>
      <SecondaryHeader>
        3. მომხმარებლის უფლებები ნაკლის მქონე საქონლის მიწოდების შემთხვევაში
      </SecondaryHeader>
      <PrimaryText>
        იმ შემთხვევაში, თუ საქონელი ნაკლის მქონე აღმოჩნდა, მომხმარებელს უფლება
        აქვს, "მობაილ სთორის" მოსთხოვოს ნაკლის აღმოფხვრა (საქონლის უსასყიდლოდ
        შეკეთება ან შეცვლა, ფასის შემცირება) ან გავიდეს ხელშეკრულებიდან.
        საქონლის შეკეთება და შეცვლა მომხმარებლის პირველი რიგის უფლებებია.
        მომხმარებელს უფლება აქვს, "მობაილ სთორის" მოსთხოვოს საქონლის უსასყიდლოდ
        შეკეთება ან შეცვლა, გარდა იმ შემთხვევისა, როდესაც ეს, საქონლის ბუნებიდან
        გამომდინარე, შეუძლებელია ან არათანაზომიერ ან შეუსაბამოდ დიდ ხარჯს
        მოითხოვს. საქონელი შეკეთებული ან შეცვლილი უნდა იქნეს გონივრულ ვადაში,
        ისე, რომ მომხმარებელს არ შეექმნას მნიშვნელოვანი შეფერხება საქონლის
        ბუნებისა და იმ მიზნის გათვალისწინებით, რომლისთვისაც მან ეს საქონელი
        შეიძინა. საქონლის უსასყიდლოდ შეკეთება ან შეცვლა გულისხმობს "მობაილ
        სთორის" მიერ ყველა ხარჯის (მათ შორის, ფოსტის, სამუშაო ძალისა და
        მასალების ხარჯების) გაწევას, რომლებიც საჭიროა საქონლის ხელშეკრულებით
        გათვალისწინებულ პირობებთან შესაბამისობის უზრუნველსაყოფად. მომხმარებელს
        უფლება აქვს, მოითხოვოს საქონლის ფასის შემცირება ან უარი თქვას
        ხელშეკრულებაზე, თუ არსებობს ერთ-ერთი შემდეგი პირობა:
        <br />
        ა) თუ გონივრულ ვადაში საქონლის შეკეთება ან შეცვლა შეუძლებელია;
        <br />
        ბ) "მობაილ სთორმა" საქონელი გონივრულ ვადაში არ შეაკეთა ან არ შეცვალა და
        მომხმარებელმა ხელშეკრულების შესრულებისადმი ინტერესი დაკარგა;
        <br />
        გ) "მობაილ სთორის" მიერ საქონლის შეკეთება ან შეცვლა მომხმარებელს
        მნიშვნელოვან შეფერხებას შეუქმნის. მომხმარებელს უფლება აქვს,
        ხელშეკრულებიდან გასვლის შემთხვევაში მოითხოვოს ხელშეკრულების
        შეუსრულებლობით მიყენებული ზიანის ანაზღაურება საქართველოს კანონმდებლობით
        დადგენილი წესით. მომხმარებელს არ აქვს უფლება, უარი თქვას ხელშეკრულებაზე,
        თუ საქონლის ნაკლი უმნიშვნელოა. იმის განსაზღვრისას, თუ რამდენად
        არათანაზომიერია ან შეუსაბამოდ დიდია ხარჯი, გათვალისწინებული უნდა იქნეს
        შემდეგი პირობები:
        <br />
        ა) საქონლის ან მომსახურების ფასი მისი ხელშეკრულებით გათვალისწინებულ
        პირობებთან შესაბამისობის შემთხვევაში;
        <br />
        ბ) საქონლის ან მომსახურების ხელშეკრულებით გათვალისწინებულ პირობებთან
        შეუსაბამობის ხარისხი;
        <br />
        გ) საქონლის ნაკლის მომხმარებლისთვის შეფერხების შექმნის გარეშე აღმოფხვრის
        ალტერნატიული შესაძლებლობა.
      </PrimaryText>
      <SecondaryHeader>4. კომერციული გარანტიის პირობები</SecondaryHeader>
      <PrimaryText>
        ნივთი შეიცვლება შემდეგ შემთხვევებში:
        <br />
        თუ ნივთს გახსნისთანავე, ყიდვის მომენტში, "მობაილ სთორის" წარმომადგენლის
        თანდასწრებით დაუფიქსირდა ვიზუალური დეფექტი;*
        <br />
        თუ შეძენილ ნივთს შეძენიდან 7 (შვიდი) კალენდარული დღის ვადაში აღმოაჩნდა
        ქარხნული დეფექტი (არ იგულისხმება ვიზუალური დაზიანება და პროგრამული
        ხარვეზი), და მომხმარებლის მიერ აღნიშნულ ვადაში ნივთი წარდგენილ იქნა შპს
        "???", "???" კი შეცვლის აქტით ადასტურებს ნივთის ქარხნულ დეფექტს; თუ
        ნივთი ვერ შეკეთდა "???" მისი საგარანტიო ვადაში წარდგენიდან გონივრულ
        ვადაში, მაგრამ არაუმეტეს 15 სამუშაო დღისა; იმ შემთხვევაში, თუ ნივთის
        შეკეთებისათვის საჭიროა სათადარიგო ნაწილის საზღვარგარეთ შეძენა ან ნივთის
        გაგზავნა საზღვარგარეთ მწარმოებლის ავტორიზებულ სერვისცენტრში, მაშინ
        შეკეთების ვადა შეადგენს 30 სამუშაო დღეს. ნივთის შეცვლა ხდება იგივე ან
        მეტი (მომხმარებლის მიერ განსხვავების დამატებითი გადახდით) ღირებულების
        ნივთის გადაცვლით, რომელიც შეცვლის მომენტში "მობაილ სთორის" გაყიდვაშია.
        ნივთის ღირებულება განისაზღვრება მომხმარებლის მიერ მისი შეძენისას
        გადახდილი ფასიდან.
      </PrimaryText>
      <SecondaryHeader>
        5. მტკიცების ტვირთი და ვადები ხელშეკრულებასთან შეუსაბამობისას
      </SecondaryHeader>
      <PrimaryText>
        საგარანტიო მომსახურება გულისხმობს საქართველოს კანონმდებლობის საფუძველზე
        მომხმარებლის უფლებას, ნივთის შეძენიდან 2 (ორი) წლის განმავლობაში
        მოითხოვოს მისი უსასყიდლოდ შეკეთება ან შეცვლა ან ხელშეკრულებიდან გასვლა,
        ფასის შემცირება, თუ საგარანტიო ვადის განმავლობაში ნივთი ნაკლის მქონე
        აღმოჩნდა. თუ მომხმარებელმა საქონლის ნაკლი საქონლის მფლობელობაში
        მიღებიდან 6 თვის განმავლობაში აღმოაჩინა, საწინააღმდეგოს დამტკიცებამდე
        იგულისხმება, რომ ნაკლი არსებობდა საქონლის მიწოდებისას, თუ, საქონლის
        ბუნებიდან ან ნაკლის ხასიათიდან გამომდინარე, ეს შესაძლებელია. ამ
        შემთხვევაში მტკიცების ტვირთი მოვაჭრეს ეკისრება. თუ მომხმარებელმა
        საქონლის ნაკლი საქონლის მფლობელობაში მიღებიდან 6 თვის შემდეგ აღმოაჩინა,
        იმის მტკიცების ტვირთი, რომ ნაკლი არსებობდა საქონლის მიწოდებისას,
        მომხმარებელს ეკისრება. მომხმარებელს უფლება აქვს, ისარგებლოს "მომხმარბლის
        უფლებების დაცვის შესახებ" საქართველოს კანონითა და წინამდებარე სავაჭრო
        პოლიტიკის მე-4 და მე-5 მუხლებით გათვალისწინებული უფლებებით, თუ საქონლის
        ნაკლი საქონლის მისთვის გადაცემიდან 2 წლის განმავლობაში აღმოჩნდა. ამ
        მუხლის მე-2 პუნქტის მოქმედება არ ვრცელდება მეორეული საქონლის
        ნასყიდობაზე. ასეთი საქონლის ნასყიდობისას ამ მუხლის მე-2 პუნქტით
        განსაზღვრულ შემთხვევაში "ზემერის" პასუხისმგებლობა მხარეთა შორის
        ინდივიდუალური შეთანხმების საგანია.
      </PrimaryText>
      <MainHeader>
        II. თავი დისტანციური ხელშეკრულება და სარეწის გარეთ დადებული ხელშეკრულება
      </MainHeader>
      <PrimaryText>
        მომხმარებლის მიერ საქონლის შეძენა შეიძლება განხორციელდეს დისტანციურად
        კომპანიის ვებ-გვერდის {WWW_MOBILESTORE_GE} მეშვეობით ან სარეწის გარეთ.
        პროცესის განხორციელებისათვის პირველ ნაბიჯს წარმოადგენს მომხმარებლის
        რეგისტრაცია ელ. ფოსტის საშუალებით, სადაც იგი გაეცნობა კომპანიის
        ვებგვერდით სარგებლობის წესებსა და არსებით პირობებს, აგრეთვე წინამდებარე
        სავაჭრო პოლიტიკას და შესაბამისი ველის მონიშვნით გამოხატავს ნებას, რომ
        იგი გაეცნო და თანახმაა, რომ კომპანიასა და მომხმარებელს შორის
        ხელშეკრულება გაფორმდეს დისტანციური კომუნიკაციის საშუალებით.
      </PrimaryText>
      <SecondaryHeader>
        6. დისტანციურ ხელშეკრულებაზე ან/და სარეწის გარეთ დადებულ ხელშეკრულებაზე
        უარის თქმის უფლება
      </SecondaryHeader>
      <PrimaryText>
        ამ საქართველოს კანონმდებლობისა და ამ პოლიტიკის მე-5 მუხლით დადგენილი
        გამონაკლისების გარდა, მომხმარებელს უფლება აქვს, ყოველგვარი საფუძვლის
        მითითების გარეშე თქვას უარი დისტანციურ ხელშეკრულებაზე ან/და სარეწის
        გარეთ დადებულ ხელშეკრულებაზე 14 კალენდარული დღის განმავლობაში. ეს ვადა
        აითვლება:
        <br />
        ა) მომსახურების ხელშეკრულების შემთხვევაში − ხელშეკრულების დადებიდან;
        <br />
        ბ) ნასყიდობის ხელშეკრულების შემთხვევაში − მომხმარებლის ან მის მიერ
        განსაზღვრული მესამე პირის მიერ (გარდა გადამზიდავისა) საქონლის
        მფლობელობაში მიღებიდან;
        <br />
        გ) მომხმარებლის მიერ საქონლის ნაწილ-ნაწილ შეკვეთის შემთხვევაში −
        მომხმარებლის ან მის მიერ განსაზღვრული მესამე პირის მიერ (გარდა
        გადამზიდავისა) ბოლო ნივთის მფლობელობაში მიღებიდან;
        <br />
        დ) საქონლის რეგულარულად (განსაზღვრული პერიოდულობით) მიწოდების
        ხელშეკრულების შემთხვევაში − მომხმარებლის ან მის მიერ განსაზღვრული მესამე
        პირის მიერ (გარდა გადამზიდავისა) ნივთის მფლობელობაში პირველად მიღებიდან.
        მომხმარებლის მიერ ხელშეკრულებაზე უარის თქმის შემთხვევაში მას არ
        დაეკისრება არანაირი ხარჯის (მათ შორის, მიწოდების ხარჯის) გაწევა, გარდა
        იმ შემთხვევისა, როდესაც მომხმარებელმა "მობაილ სთორის" მიერ შეთავაზებული
        სტანდარტული მიწოდების მომსახურებაზე უფრო ძვირი მიწოდების მომსახურება
        აირჩია ან საქონლის ფასი შემცირდა ისეთი სარგებლობის შედეგად, რომელიც
        დაკავშირებული არ არის საქონლის ბუნების, მახასიათებლებისა და
        ფუნქციონირების დადგენასთან. საქონლის ფასის შემცირების შედეგად
        წარმოშობილი სხვაობის დაფარვის ვალდებულება მომხმარებელს არ დაეკისრება, თუ
        "მობაილ სთორმა" მას კანონით გათვალისწინებული ხელშეკრულებაზე უარის თქმის
        უფლების შესახებ სრული ინფორმაცია არ მიაწოდა. მომხმარებლის მიერ
        დისტანციურ ხელშეკრულებაზე უარის თქმა ავტომატურად იწვევს დაკავშირებული
        ხელშეკრულებიდან მის გასვლას და პირვანდელი მდგომარეობის აღდგენას.
        მომხმარებელი ვალდებულია "მობაილ სთორის" ამ მუხლის პირველი პუნქტით
        დადგენილ ვადაში გაუგზავნოს შევსებული ფორმა (დანართი 1) ან სხვა ცალსახა
        მტკიცებულება, რომელიც ასახავს მომხმარებლის გადაწყვეტილებას საქონლის
        დაბრუნების შესახებ. ამ მუხლით გათვალისწინებული ხელშეკრულებაზე უარის
        თქმის უფლების დადგენილ ვადაში განხორციელების მტკიცების ტვირთი
        მომხმარებელს ეკისრება. მომხმარებელს შეუძლია ხელშეკრულებაზე უარის თქმის
        უფლება განახორციელოს ამ მუხლის პირველი პუნქტით დადგენილ ვადაში, თუ მან
        ხელშეკრულებაზე უარის თქმის შესახებ შეტყობინება "მობაილ სთორს" აღნიშნული
        ვადის ამოწურვამდე გაუგზავნა. იმის მტკიცების ტვირთი, რომ ხელშეკრულებაზე
        უარის თქმის უფლება ამ მუხლის შესაბამისად განხორციელდა, მომხმარებელს
        ეკისრება. თუ "მობაილ სთორი" მომხმარებელს არ მიაწვდის ინფორმაციას ამ
        კანონით გათვალისწინებული საქონლის ან მომსახურების დაბრუნების უფლების
        შესახებ, აღნიშნული საქონლის ან მომსახურების დაბრუნება შესაძლებელია ამ
        მუხლის პირველი პუნქტით დადგენილი ვადის გასვლიდან 12 თვის განმავლობაში.
        თუ "მობაილ სთორმა" მომხმარებელს ამ მუხლის პირველი პუნქტით დადგენილი
        ვადის ათვლის მომენტიდან 12 თვის ვადაში მიაწოდა ინფორმაცია ხელშეკრულებაზე
        უარის თქმის უფლების შესახებ, საქონლის ან მომსახურების დაბრუნების
        7-დღიანი ვადა აღნიშნული ინფორმაციის მიღებიდან აითვლება.
      </PrimaryText>
      <SecondaryHeader>
        7. ყოველგვარი საფუძვლის მითითების გარეშე ხელშეკრულებაზე უარის თქმასთან
        დაკავშირებული გამონაკლისები
      </SecondaryHeader>
      <PrimaryText>
        მომხმარებელს არ აქვს უფლება, უარი თქვას ხელშეკრულებაზე, თუ:
        <br />
        ა) "მობაილ სთორის" ხელშეკრულებით გათვალისწინებული მომსახურება სრულად
        გასწია, მან მომსახურების გაწევა მომხმარებლის წინასწარი თანხმობით დაიწყო
        და მომხმარებელმა იცოდა, რომ მომსახურების სრულად მიღების შემთხვევაში
        დაკარგავდა ხელშეკრულებაზე უარის თქმის უფლებას;
        <br />
        ბ) ხელშეკრულებით გათვალისწინებულია, რომ მიწოდებული საქონლის ან
        მომსახურების ფასი დამოკიდებულია ფინანსურ ბაზარზე არსებულ ცვლილებაზე,
        რომელსაც "მობაილ სთორი" ვერ აკონტროლებს და რომელიც ხელშეკრულებაზე უარის
        თქმის უფლების განხორციელებისთვის ამ კანონით დადგენილ ვადაში წარმოიშვა;
        <br />
        გ) მიწოდებული საქონელი ან მომსახურება მომხმარებლის ინდივიდუალური
        შეკვეთით არის დამზადებული ან აშკარად მის პირად საჭიროებებზეა მორგებული;
        <br />
        დ) მიწოდებული საქონელი მალფუჭებადია ან მას მოკლე ვარგისობის ვადა აქვს;
        <br />
        ე) მას მიეწოდა ჰერმეტულად დაცული საქონელი, მისი ჰერმეტულობა მიწოდების
        შემდეგ დაირღვა და, ამდენად, საქონლის დაბრუნება შეუძლებელია ჯანმრთელობის
        ან ჰიგიენური ნორმების დაცვის გამო;
        <br />
        ვ) მიწოდებული საქონელი მიწოდების დროს, მისი მახასიათებლებიდან
        გამომდინარე, განუყოფლად შეერწყა სხვა საქონელს;
        <br />
        ზ) მიწოდებულ იქნა დალუქული აუდიო- ან ვიდეოჩანაწერი ან პროგრამული
        უზრუნველყოფა და მისი ლუქი მიწოდების შემდეგ დაირღვა;
        <br />
        თ) მას ციფრული შინაარსი მიეწოდა არამყარი მატარებლით, ხელშეკრულების
        შესრულება მომხმარებლის წინასწარი თანხმობით დაიწყო და მომხმარებელმა
        იცოდა, რომ ამით დაკარგავდა ხელშეკრულებაზე უარის თქმის უფლებას;
      </PrimaryText>
      <SecondaryHeader>8. ხელშეკრულებაზე უარის თქმის შედეგები</SecondaryHeader>
      <PrimaryText>
        ხელშეკრულებაზე უარის თქმის შედეგად ქარწყლდება მხარეების მიერ დისტანციური
        ხელშეკრულებით ან სარეწის გარეთ დადებული ხელშეკრულებით ნაკისრი
        ვალდებულებები, ხოლო ის, რაც მხარეებმა მისი შესრულებით მიიღეს, მათ
        უბრუნდებათ. 2. ხელშეკრულებაზე უარის თქმის შემთხვევაში "მობაილ სთორი"
        ვალდებულია:
        <br />
        ა) მომხმარებელს გადახდილი თანხა, აგრეთვე არსებობის შემთხვევაში −
        მიწოდების ხარჯის ასანაზღაურებლად გადახდილი თანხა დაუბრუნოს სრულად,
        გაჭიანურების გარეშე, ხელშეკრულებაზე უარის თქმის შესახებ შეტყობინების
        მიღებიდან არაუგვიანეს 14 კალენდარული დღისა. "მობაილ სთორი" ვალდებული არ
        არის, მომხმარებელს დაუბრუნოს იმ დამატებითი ხარჯის ასანაზღაურებლად
        გადახდილი თანხა, რომელიც დაკავშირებულია მომხმარებლის მიერ არასტანდარტული
        ან "მობაილ სთორი" მიერ შეთავაზებული მიწოდების მომსახურებაზე უფრო ძვირი
        მიწოდების მომსახურების არჩევასთან;
        <br />
        ბ) თანხა აანაზღაუროს გადახდის იმავე საშუალებებით, რომლებიც მომხმარებელმა
        გამოიყენა, გარდა იმ შემთხვევისა, როდესაც მომხმარებელი გადახდის სხვა
        საშუალების გამოყენებაზე თანხმობას განაცხადებს და მას დამატებითი ხარჯი არ
        წარმოეშობა. ნასყიდობის ხელშეკრულებაზე უარის თქმის შემთხვევაში "მობაილ
        სთორს" უფლება აქვს, უარი თქვას თანხის დაბრუნებაზე მანამ, სანამ საქონელს
        არ დაიბრუნებს ან მომხმარებლისგან არ მიიღებს საქონლის გაგზავნის
        დამადასტურებელ დოკუმენტს, იმის მიხედვით, რომელიც უფრო ადრე
        განხორციელდება, გარდა იმ შემთხვევისა, როდესაც "მობაილ სთორმა" თავად
        იკისრა საქონლის უკან წაღება. ხელშეკრულებაზე უარის თქმის შემთხვევაში
        მომხმარებელი ვალდებულია:
        <br />
        ა) "მობაილ სთორს" ან მის მიერ განსაზღვრულ მესამე პირს დაუბრუნოს საქონელი
        ხელშეკრულებაზე უარის თქმის შესახებ შეტყობინების გაგზავნის შემდეგ
        დაუყოვნებლივ, მაგრამ არაუგვიანეს 7 კალენდარული დღისა, გარდა იმ
        შემთხვევისა, როდესაც "მობაილ სთორმა" თავად იკისრა საქონლის უკან წაღება;
        <br />
        ბ) გასწიოს მხოლოდ საქონლის დაბრუნებასთან დაკავშირებული პირდაპირი
        (უშუალო) ხარჯი, გარდა იმ შემთხვევისა, როდესაც "მობაილ სთორმა" თავად
        იკისრა ამ ხარჯის გაწევა ან მომხმარებელს არ მიაწოდა ინფორმაცია იმის
        შესახებ, რომ საქონლის დაბრუნების შემთხვევაში ეს ხარჯი წარმოიშობოდა.
        სარეწის გარეთ დადებული ხელშეკრულების შემთხვევაში, როდესაც საქონელი
        მომხმარებელს სახლში მიეწოდება, "მობაილ სთორი" ვალდებულია საქონელი
        საკუთარი ხარჯით წაიღოს უკან, თუ, საქონლის ბუნებიდან გამომდინარე, მისი
        ფოსტით გაგზავნა შეუძლებელია.
      </PrimaryText>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          color: COLORS.SECONDARY_HEADER,
          marginBottom: "10px",
        }}
      >
        დანართი 1
      </Typography>
      <PrimaryText>
        მომხმარებლის მიერ ხელშეკრულებაზე უარის თქმის შემთხვევაში მობაილ
        სთორისთვის
      </PrimaryText>
      <SecondaryHeader>გასაგზავნი ფორმა</SecondaryHeader>
      <PrimaryText>
        შპს "მობაილ სთორი"
        <br />
        მის: თბილისი, ???
        <br />
        საიდენტიფიკაციო ნომერი: ??? ელ. ფოსტა: ???
        <br />
        ტელ./ფაქსი: ???
        <br />
        შეკვეთის თარიღი:
        <br />
        <br />
        შეკვეთის მიღების თარიღი:
        <br />
        <br />
        მომხმარებლის სახელი:
        <br />
        <br />
        მომხმარებლის მისამართი:
        <br />
        <br />
        მომხმარებლის ხელმოწერა:
        <br />
        <br />
        ფორმის შევსების თარიღი:
        <br />
        <br />
        შენიშვნა:
      </PrimaryText>
    </Root>
  );
};

export default TradePolicyPage;
