import axiosInstance from "../axios";

const PREFIX = "/Product";

export const GetAllProducts = (params) => {
  return axiosInstance.get(`${PREFIX}/GetAllProducts`, {
    params: {
      ...params,
    },
  });
};

export const GetProductByCategory = (categoryName) => {
  return axiosInstance.get(`${PREFIX}/GetProductByCategory`, {
    params: {
      CategoryName: categoryName,
    },
  });
};

export const GetAllMobilesWithFilters = (body) => {
  return axiosInstance.post(`${PREFIX}/GetAllMobilesWithFilters`, body);
};

export const GetProductById = (Id) => {
  return axiosInstance.get(`${PREFIX}/GetProductById`, {
    params: {
      Id,
    },
  });
};
