import { styled, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const Root = styled("div")(({ theme }) => ({
  width: "30%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "16px", // Increased padding for a more spacious look
  background: "linear-gradient(135deg, #bee3ff, #0083ff00);", // Gradient background
  borderRadius: "10px", // Slightly rounded corners
  gap: "15px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
  transition: "transform 0.3s ease, box-shadow 0.3s ease", // Smooth transition for hover effect
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.05)", // Scale up on hover
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)", // Deeper shadow on hover
  },
}));

const AddressContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
}));

const CTypography = styled("p")(({ theme }) => ({}));

const STypography = styled(CTypography)(({ theme }) => ({
  color: "#747474",
  fontSize: "12px",
  fontWeight: 400,
}));

const NTypography = styled(CTypography)(({ theme }) => ({
  color: "#3281FE",
}));

const WHTypography = styled(CTypography)(({ theme }) => ({
  color: "#000000CC",
  fontWeight: 500,
  fontSize: "12px",
  display: "flex",
  justifyContent: "end",
}));

const WorkingHoursContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const BranchCard = () => {
  const { t } = useTranslation();

  const handleOnClick = () => {
    window.open(
      "https://www.google.com/maps/place/24+%E1%83%9E%E1%83%94%E1%83%99%E1%83%98%E1%83%9C%E1%83%98%E1%83%A1+%E1%83%92%E1%83%90%E1%83%9B%E1%83%96%E1%83%98%E1%83%A0%E1%83%98,+%E1%83%97%E1%83%91%E1%83%98%E1%83%9A%E1%83%98%E1%83%A1%E1%83%98/@41.7224017,44.7671121,14.83z/data=!4m6!3m5!1s0x404472d9276fb1b3:0x120da048cfd0ecc4!8m2!3d41.7239822!4d44.7712608!16s%2Fg%2F11_q1qzgz?entry=ttu&g_ep=EgoyMDI0MTAyNy4wIKXMDSoASAFQAw%3D%3D",
      "_blank",
    );
  };

  return (
    <Root onClick={handleOnClick}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <AddressContainer>
          <CTypography>tbilisi</CTypography>
          <STypography>#24 პეკინის გამზირი</STypography>
        </AddressContainer>
        <NTypography>+995 (32) 2 60 30 60</NTypography>
      </Box>
      <WorkingHoursContainer>
        <WHTypography>{t("monSat")}: 10:00 - 20:00</WHTypography>
        <WHTypography>{t("sunday")}: 10:00 - 20:00</WHTypography>
      </WorkingHoursContainer>
    </Root>
  );
};

export default BranchCard;
