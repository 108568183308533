import axios from "axios";
import qs from "qs";

const BASE_REST_URL = process.env.REACT_APP_API_URL_PRODUCTION; // Use production API URL in production

const instance = axios.create({
  baseURL: `${BASE_REST_URL}/api`, // Append /api to the base URL for all requests
  timeout: 30000,
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
});

export default instance;
