import axiosInstance from "../axios";

const PREFIX = "/PromoCode";

export const GetPrizeByPromoCode = (promoCode) => {
  return axiosInstance.get(`${PREFIX}/GetPrizeByPromoCode`, {
    params: {
      PromoCode: promoCode,
    },
  });
};
