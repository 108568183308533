import Modal from "@mui/material/Modal";
import { Checkbox, styled } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import CustomTextFields from "../Fields/CustomTextFields";
import CustomButton from "../Buttons/CustomButton";
import ExternalAuthorizationContainer from "./ExternalAuthorizationContainer";
import FacebookAuthIcon from "../../api/ui/svgIcons/FacebookAuthIcon";
import GoogleAuthIcon from "../../api/ui/svgIcons/GoogleAuthIcon";
import TwitterAuthIcon from "../../api/ui/svgIcons/TwitterAuthIcon";
import LinkedInAuthIcon from "../../api/ui/svgIcons/LinkedInAuthIcon";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  closeAuthModal,
  openLoginModal,
  openRegistrationModal,
  userAuthorization,
} from "../../redux/actions/mainAction";
import { useSnackbar } from "notistack";
import { CheckEmailValidation, getLocalStorageValue } from "../../util/appUtil";
import {
  GetUserFullInfo,
  Login,
  LoginUsingFacebook,
  LoginUsingGoogle,
  LoginUsingLinkedIn,
  LoginUsingX,
  Registration,
} from "../../api/services/Auth.api";
import { getBaseUrl } from "../../api/baseUrl";
import { useNavigate } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#ffffff",
  borderRadius: "16px",
  padding: "20px",
  width: isMobile ? "90%" : 400,
  border: "1px solid #1E90FF",
  outline: "none !important",
}));

const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  boxSizing: "border-box",
  borderBottom: "2px solid #f2f2f2",
  position: "relative",
  paddingBottom: "16px",
  marginBottom: "10px",
}));

const HeaderContainerItem = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  boxSizing: "border-box",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  fontSize: isMobile ? "12px" : "16px",
  fontWeight: 400,
  transition: "font-weight 0.3s ease-in-out, color 0.3s ease-in-out",
}));

const HeaderContainerItemIndicator = styled("div")(({ isLoginPageOpen }) => ({
  position: "absolute",
  width: "50%",
  background: "#1E90FF",
  height: "2px",
  boxSizing: "border-box",
  bottom: "-1px",
  right: isLoginPageOpen ? "50%" : "0",
  transition: "right 0.3s ease-in-out",
}));

const EmailAndPassAuthContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  gap: "10px",
}));

const ForgotPasswordContainer = styled("div")(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
  margin: "10px 0px 20px 0px",
  display: "flex",
  justifyContent: "flex-end",
}));

const ForgotPasswordText = styled("span")(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 600,
  cursor: "pointer",
}));

const HorizontalLineContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "10px",
  width: "100%",
  alignItems: "center",
  marginTop: "10px",
}));

const HorizontalLine = styled("span")(({ theme }) => ({
  background: "#f2f2f2",
  height: "2px",
  width: "100%",
}));

const AnotherAuthContainer = styled("div")(({ theme }) => ({
  background: "#f2f2f2",
  display: "flex",
  gap: "6px",
  padding: "8px 16px",
  width: "100%",
  borderRadius: "8px",
  alignItems: "center",
  justifyContent: "center",
}));

const TermsAndConditionsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  gap: "4px",
  alignItems: "center",
  margin: "8px 0px 15px 0px",
}));

const TermsAndConditionsText = styled("span")(({ theme }) => ({
  fontSize: "11px",
  fontWeight: 500,
}));

const AuthorizationModal = ({
  isLoginOpen,
  isRegisterOpen,
  setOpenLoginPage,
  setOpenRegistrationPage,
  closeAuthModal,
  userAuthorization,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showRegPassword, setShowRegPassword] = useState(false);
  const [showRegRepeatPassword, setShowRegRepeatPassword] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const regInfo = useRef({
    email: "",
    password: "",
    repeatPassword: "",
  });
  const loginInfo = useRef({
    email: "",
    password: "",
    rememberMe: false,
  });
  const [isCheckedAgreement, setCheckedAgreement] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChangeRegInfo = (key, value) => {
    regInfo.current[key] = value;
  };

  const onChangeLoginInfo = (key, value) => {
    loginInfo.current[key] = value;
  };

  useEffect(() => {
    const referralCodeInfo = getLocalStorageValue("referral-code", null);
    let referralCode = null;

    if (referralCodeInfo) {
      const userInfo = JSON.parse(referralCodeInfo);
      const savedDate = new Date(userInfo.date);
      const currentDate = new Date();
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
      const timeDifference = currentDate.getTime() - savedDate.getTime();
      if (timeDifference <= oneDayInMilliseconds) {
        referralCode = userInfo.code;
      }

      if (referralCode) {
        setReferralCode(referralCode);
      }
    }
  }, []);

  const onClose = () => {
    closeAuthModal(true);
    setCheckedAgreement(false);
    regInfo.current = {
      email: "",
      password: "",
      repeatPassword: "",
    };
    loginInfo.current = {
      email: "",
      password: "",
      rememberMe: false,
    };
    setReferralCode(null);
  };

  const onRegistration = () => {
    if (
      !regInfo.current["email"] ||
      !regInfo.current["password"] ||
      !regInfo.current["repeatPassword"]
    ) {
      enqueueSnackbar(t("pleaseFillAllField"), {
        variant: "warning",
      });
    } else if (!CheckEmailValidation(regInfo.current["email"])) {
      enqueueSnackbar(t("pleaseEnterCorrectEmail"), {
        variant: "warning",
      });
    } else if (
      regInfo.current["password"] !== regInfo.current["repeatPassword"]
    ) {
      enqueueSnackbar(t("passwordDoesNotMatch"), {
        variant: "warning",
      });
    } else if (regInfo.current["password"].trim().length < 6) {
      enqueueSnackbar(t("minSizeOfPassword"), {
        variant: "warning",
      });
    } else if (!isCheckedAgreement) {
      enqueueSnackbar(t("pleaseReadAgreement"), {
        variant: "warning",
      });
    } else {
      setLoading(true);
      Registration({
        email: regInfo.current["email"],
        password: regInfo.current["password"],
        referralCode: referralCode ? referralCode.trim() : null,
      })
        .then((resp) => {
          if (resp.data.message === "SuccessfullyRegistered") {
            enqueueSnackbar(t("SuccessfullyRegistered"), {
              variant: "success",
            });
            return getUserFullInfoAfterLogin(false);
          }

          if (resp.data.message === "EmailTaken") {
            enqueueSnackbar(t("EmailTaken"), {
              variant: "error",
            });
          }
        })
        .catch(() => {
          enqueueSnackbar(t("errorPleaseContactWebsiteAdministrator"), {
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const LoginUser = () => {
    if (!loginInfo.current["email"] || !loginInfo.current["password"]) {
      enqueueSnackbar(t("pleaseFillAllField"), {
        variant: "warning",
      });
    } else if (!CheckEmailValidation(loginInfo.current["email"])) {
      enqueueSnackbar(t("pleaseEnterCorrectEmail"), {
        variant: "warning",
      });
    } else if (loginInfo.current["password"].trim().length < 6) {
      enqueueSnackbar(t("incorrectCredentials"), {
        variant: "error",
      });
    } else {
      setLoading(true);
      Login({
        ...loginInfo.current,
      })
        .then((resp) => {
          if (resp.data.message === "SuccessfullyLogin") {
            return getUserFullInfoAfterLogin(true);
          } else {
            enqueueSnackbar(t("incorrectCredentials"), {
              variant: "error",
            });
          }
        })
        .catch(() => {
          enqueueSnackbar(t("errorPleaseContactWebsiteAdministrator"), {
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const loginWithFb = () => {
    LoginUsingFacebook()
      .then((response) => {
        window.location.href = getBaseUrl() + response.data.redirectUrl;
      })
      .catch(() => {
        enqueueSnackbar(t("errorPleaseContactWebsiteAdministrator"), {
          variant: "error",
        });
      });
  };

  const loginWithGoogle = () => {
    LoginUsingGoogle()
      .then((response) => {
        window.location.href = getBaseUrl() + response.data.redirectUrl;
      })
      .catch(() => {
        enqueueSnackbar(t("errorPleaseContactWebsiteAdministrator"), {
          variant: "error",
        });
      });
  };

  const loginWithLinkedIn = () => {
    LoginUsingLinkedIn()
      .then((response) => {
        window.location.href = getBaseUrl() + response.data.redirectUrl;
      })
      .catch(() => {
        enqueueSnackbar(t("errorPleaseContactWebsiteAdministrator"), {
          variant: "error",
        });
      });
  };

  const loginWithTwitter = () => {
    LoginUsingX()
      .then((response) => {
        window.location.href = getBaseUrl() + response.data.redirectUrl;
      })
      .catch(() => {
        enqueueSnackbar(t("errorPleaseContactWebsiteAdministrator"), {
          variant: "error",
        });
      });
  };

  const getUserFullInfoAfterLogin = async (isLogin) => {
    try {
      const response = await GetUserFullInfo();
      userAuthorization(response.data);
      onClose();
      if (!isLogin) {
        navigate("user-info");
      }
    } catch {
      enqueueSnackbar(t("errorPleaseContactWebsiteAdministrator"), {
        variant: "error",
      });
    }
  };

  return (
    <Modal
      open={isLoginOpen || isRegisterOpen}
      onClose={() => onClose()}
      sx={{
        "& .MuiBackdrop-root": {
          border: "none",
        },
        "& .MuiPaper-root": {
          border: "none",
        },
      }}
    >
      <form>
        <Root>
          <HeaderContainer>
            <HeaderContainerItem
              onClick={() => setOpenLoginPage(true)}
              style={{
                fontWeight: isLoginOpen ? 600 : 400,
              }}
            >
              {t("signIn")}
            </HeaderContainerItem>
            <HeaderContainerItem
              onClick={() => setOpenRegistrationPage(true)}
              style={{
                fontWeight: !isLoginOpen ? 600 : 400,
              }}
            >
              {t("registration")}
            </HeaderContainerItem>
            <HeaderContainerItemIndicator isLoginPageOpen={isLoginOpen} />
          </HeaderContainer>
          {isLoginOpen && (
            <EmailAndPassAuthContainer>
              <CustomTextFields
                placeholder={t("email")}
                onChange={(val) => onChangeLoginInfo("email", val)}
                defaultValue={loginInfo.current["email"]}
                onKeyDown={() => LoginUser()}
              />
              <CustomTextFields
                placeholder={t("password")}
                type={showPassword ? "text" : "password"}
                showPassword={showPassword}
                onPasswordShow={setShowPassword}
                passwordIcon={true}
                onChange={(val) => onChangeLoginInfo("password", val)}
                defaultValue={loginInfo.current["email"]}
                onKeyDown={() => LoginUser()}
              />
            </EmailAndPassAuthContainer>
          )}
          {!isLoginOpen && (
            <EmailAndPassAuthContainer>
              <CustomTextFields
                placeholder={t("email")}
                onChange={(val) => onChangeRegInfo("email", val)}
                defaultValue={regInfo.current["email"]}
                onKeyDown={() => onRegistration()}
              />

              <CustomTextFields
                placeholder={t("password")}
                type={showRegPassword ? "text" : "password"}
                showPassword={showRegPassword}
                onPasswordShow={setShowRegPassword}
                passwordIcon={true}
                onChange={(val) => onChangeRegInfo("password", val)}
                defaultValue={regInfo.current["password"]}
                onKeyDown={() => onRegistration()}
              />
              <CustomTextFields
                placeholder={t("repeatPassword")}
                type={showRegRepeatPassword ? "text" : "password"}
                showPassword={showRegRepeatPassword}
                onPasswordShow={setShowRegRepeatPassword}
                passwordIcon={true}
                onChange={(val) => onChangeRegInfo("repeatPassword", val)}
                defaultValue={regInfo.current["repeatPassword"]}
                onKeyDown={() => onRegistration()}
              />
              <CustomTextFields
                placeholder={t("userInputPromoCode")}
                onChange={(val) => setReferralCode(val)}
                defaultValue={referralCode}
                onKeyDown={() => onRegistration()}
              />
            </EmailAndPassAuthContainer>
          )}
          {isLoginOpen && (
            <ForgotPasswordContainer>
              <ForgotPasswordText>{t("forgotPassword")}</ForgotPasswordText>
            </ForgotPasswordContainer>
          )}
          {!isLoginOpen && (
            <TermsAndConditionsContainer>
              <Checkbox
                checked={isCheckedAgreement}
                onChange={() => setCheckedAgreement(!isCheckedAgreement)}
                size="small"
              />
              <TermsAndConditionsText>
                {t("readAndAgree")}{" "}
                <span
                  style={{
                    color: "#1E90FF",
                    cursor: "pointer",
                  }}
                >
                  {t("rulesRestrictionsAndDataPolicy")}
                </span>
              </TermsAndConditionsText>
            </TermsAndConditionsContainer>
          )}
          <CustomButton
            onClick={() => (isLoginOpen ? LoginUser() : onRegistration())}
            text={isLoginOpen ? t("logIn") : t("registration")}
            loading={loading}
          />
          <HorizontalLineContainer>
            <HorizontalLine />
            <span
              style={{
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              {t("or")}
            </span>
            <HorizontalLine />
          </HorizontalLineContainer>
          <div
            style={{
              fontSize: "12px",
              fontWeight: 600,
              textAlign: "center",
              margin: "6px 0px 16px 0px",
            }}
          >
            {t("loginWithAnotherMethod")}
          </div>
          <AnotherAuthContainer>
            <ExternalAuthorizationContainer onClick={() => loginWithFb()}>
              <FacebookAuthIcon />
            </ExternalAuthorizationContainer>
            <ExternalAuthorizationContainer onClick={() => loginWithGoogle()}>
              <GoogleAuthIcon />
            </ExternalAuthorizationContainer>
            <ExternalAuthorizationContainer onClick={() => loginWithTwitter()}>
              <TwitterAuthIcon />
            </ExternalAuthorizationContainer>
            <ExternalAuthorizationContainer onClick={() => loginWithLinkedIn()}>
              <LinkedInAuthIcon />
            </ExternalAuthorizationContainer>
          </AnotherAuthContainer>
        </Root>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isLoginOpen: state.main.isLoginOpen,
  isRegisterOpen: state.main.isRegisterOpen,
});

const dispatchToProps = (dispatch) => ({
  setOpenLoginPage: bindActionCreators(openLoginModal, dispatch),
  setOpenRegistrationPage: bindActionCreators(openRegistrationModal, dispatch),
  closeAuthModal: bindActionCreators(closeAuthModal, dispatch),
  userAuthorization: bindActionCreators(userAuthorization, dispatch),
});

export default connect(mapStateToProps, dispatchToProps)(AuthorizationModal);
