import * as types from "../constants/savedProductConstants";

export const restoreSavedProductFromLocalStorage = (products) => ({
  type: types.RESTORE_PRODUCTS_FROM_LOCALSTORAGE,
  payload: products,
});
export const saveProduct = (product) => ({
  type: types.SAVE_PRODUCTS,
  payload: product,
});

export const removeProduct = (product) => ({
  type: types.REMOVE_PRODUCT,
  payload: product,
});
