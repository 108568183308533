import ProductsGrid from "../ProductsGrid/ProductsGrid";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { GetAllMobilesWithFilters } from "../../api/services/products.api";
import { useSnackbar } from "notistack";
import Box from "@mui/material/Box";
import {
  Checkbox,
  debounce,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { BrandEnumArray } from "../../util/appUtil";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { CheckedIcon, FilterExpandArrow, UnCheckedIcon } from "../../svgIcons";
import { isMobile } from "react-device-detect";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  borderRadius: "12px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<FilterExpandArrow style={{}} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  paddingLeft: "7px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    "& p": {
      fontSize: isMobile ? "12px" : "14px",
      fontWeight: 600,
      lineHeight: "20px",
      opacity: 0.8,
      color: "#000000",
      marginLeft: "15px",
    },
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: "0px",
  paddingLeft: "0px",
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const StyledLabel = styled(FormControlLabel)({
  fontSize: "0.875rem",
  fontWeight: "500",
  color: "#555", // Elegant color for label
  margin: "0.25rem 0",
  "& .MuiCheckbox-root": {
    padding: "4px", // Smaller padding for compact look
    "&.Mui-checked": {
      color: "#2793ff", // Elegant color for checked state
    },
  },
  "& .MuiTypography-root": {
    fontSize: isMobile ? "12px" : "0.875rem",
    // fontWeight: "bold",
    marginLeft: "10px",
  },
  "&:hover": {
    // backgroundColor: "rgba(0, 119, 255, 0.06);",
    borderRadius: "10px",
  },
});

const ALL_MEMORY = [2, 3, 4, 5, 8, 12, 16, 48, 64];

const MobilesPage = ({ isMobileVersion }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [hideBrand, setHideBrand] = useState(false);
  const [hideSimCard, setHideSimCard] = useState(false);
  const [hideESim, setHideESim] = useState(false);
  const [hideMemory, setHideMemory] = useState(false);
  const [hideRam, setHideRam] = useState(false);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [params, setParams] = useState({
    Limit: limit,
    IsSecondHand: false,
    MinPrice: 0,
    MaxPrice: 8000,
    BrandEnum: [],
    HasEsim: null,
    Memory: [],
    Ram: [],
  });

  useEffect(() => {
    setLoading(true);
    GetAllMobilesWithFilters({ ...params, PageNumber: 1 })
      .then((resp) => {
        setPage(1);
        if (resp) {
          setTotal(JSON.parse(resp.data.data).Total);
          setData(JSON.parse(resp.data.data).Products);
        }
      })
      .catch(() => {
        enqueueSnackbar(t("errorPleaseContactWebsiteAdministrator"), {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  const seeMoreCallBack = () => {
    setLoading(true);
    GetAllMobilesWithFilters({ ...params, PageNumber: page + 1 })
      .then((resp) => {
        setPage(page + 1);
        if (resp) {
          setData([...data, ...JSON.parse(resp.data.data).Products]);
        }
      })
      .catch(() => {
        enqueueSnackbar(t("errorPleaseContactWebsiteAdministrator"), {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changePriceCallBack = useMemo(
    () =>
      debounce((value) => {
        if (value) {
          setParams({ ...params, MinPrice: value[0], MaxPrice: value[1] });
        }
      }, 500),
    [],
  );

  const onChangeBrands = (brand, isChecked) => {
    const value = BrandEnumArray.find((item) => item.Name === brand).value;
    setParams({
      ...params,
      BrandEnum: isChecked
        ? [...params.BrandEnum, value]
        : params.BrandEnum.filter((item) => item !== value),
    });
  };

  const onChangeMemory = (memory, isChecked) => {
    setParams({
      ...params,
      Memory: isChecked
        ? [...params.Memory, memory]
        : params.Memory.filter((item) => item !== memory),
    });
  };

  const onChangeRam = (ram, isChecked) => {
    setParams({
      ...params,
      Ram: isChecked
        ? [...params.Ram, ram]
        : params.Ram.filter((item) => item !== ram),
    });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <ProductsGrid
        title={t("mobiles")}
        minAmount={0}
        maxAmount={8000}
        data={data}
        loading={loading}
        total={total}
        seeMoreCallBack={seeMoreCallBack}
        changePriceCallBack={changePriceCallBack}
        isMobileVersion={isMobileVersion}
      >
        <div>
          <StyledLabel
            control={
              <Checkbox
                onClick={(event) =>
                  setParams({ ...params, IsSecondHand: event.target.checked })
                }
                icon={<UnCheckedIcon />}
                checkedIcon={<CheckedIcon />}
                size="small"
              />
            }
            label={t("findAsSecondHand")}
            sx={{
              width: "100%",
              background: "#f7faff",
              borderRadius: "10px",
              padding: "5px",
              "& span": {
                fontWeight: 600,
                fontSize: "20px",
              },
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Accordion
            expanded={hideBrand}
            onChange={(event, newExpend) => {
              setHideBrand(newExpend);
            }}
          >
            <AccordionSummary>
              <Typography>{t("brand")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{ display: "flex", gap: "5px", flexDirection: "column" }}
              >
                {BrandEnumArray.map((item, index) => {
                  return (
                    <StyledLabel
                      key={item.name}
                      control={
                        <Checkbox
                          onClick={(event) =>
                            onChangeBrands(item.Name, event.target.checked)
                          }
                          icon={<UnCheckedIcon />}
                          checkedIcon={<CheckedIcon />}
                          size="small"
                        />
                      }
                      label={item.Name}
                    />
                  );
                })}
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={hideSimCard}
            onChange={(event, newExpend) => {
              setHideSimCard(newExpend);
            }}
          >
            <AccordionSummary>
              <Typography>{t("simCard")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{ display: "flex", gap: "5px", flexDirection: "column" }}
              >
                <StyledLabel
                  control={
                    <Checkbox
                      onClick={(event) =>
                        setParams({
                          ...params,
                          HasEsim: event.target.checked ? true : null,
                        })
                      }
                      icon={<UnCheckedIcon />}
                      checkedIcon={<CheckedIcon />}
                      size="small"
                    />
                  }
                  label={t("eSim")}
                />
                <StyledLabel
                  control={
                    <Checkbox
                      onClick={(event) =>
                        setParams({
                          ...params,
                          HasSingleSim: event.target.checked ? true : null,
                        })
                      }
                      icon={<UnCheckedIcon />}
                      checkedIcon={<CheckedIcon />}
                      size="small"
                    />
                  }
                  label={t("singleSim")}
                />
                <StyledLabel
                  control={
                    <Checkbox
                      onClick={(event) =>
                        setParams({
                          ...params,
                          HasDualSim: event.target.checked ? true : null,
                        })
                      }
                      icon={<UnCheckedIcon />}
                      checkedIcon={<CheckedIcon />}
                      size="small"
                    />
                  }
                  label={t("dualSim")}
                />
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={hideESim}
            onChange={(event, newExpend) => {
              setHideESim(newExpend);
            }}
          >
            <AccordionSummary>
              <Typography>{t("eSim")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{ display: "flex", gap: "5px", flexDirection: "column" }}
              >
                <StyledLabel
                  control={
                    <Checkbox
                      onClick={(event) =>
                        setParams({
                          ...params,
                          HasEsim: event.target.checked ? true : null,
                        })
                      }
                      icon={<UnCheckedIcon />}
                      checkedIcon={<CheckedIcon />}
                      size="small"
                    />
                  }
                  label={t("yes")}
                />
                <StyledLabel
                  control={
                    <Checkbox
                      onClick={(event) =>
                        setParams({
                          ...params,
                          HasEsim: !event.target.checked ? false : null,
                        })
                      }
                      icon={<UnCheckedIcon />}
                      checkedIcon={<CheckedIcon />}
                      size="small"
                    />
                  }
                  label={t("no")}
                />
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={hideMemory}
            onChange={(event, newExpend) => {
              setHideMemory(newExpend);
            }}
          >
            <AccordionSummary>
              <Typography>{t("internalMemory")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{ display: "flex", gap: "5px", flexDirection: "column" }}
              >
                <Box display="flex" flexDirection="column">
                  {ALL_MEMORY.map((value) => (
                    <StyledLabel
                      key={value}
                      control={
                        <Checkbox
                          onClick={(event) =>
                            onChangeMemory(value, event.target.checked)
                          }
                          icon={<UnCheckedIcon />}
                          checkedIcon={<CheckedIcon />}
                          size="small"
                          onChange={(event) => {}}
                        />
                      }
                      label={`${value} GB`}
                    />
                  ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={hideRam}
            onChange={(event, newExpend) => {
              setHideRam(newExpend);
            }}
          >
            <AccordionSummary>
              <Typography>{t("ram")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{ display: "flex", gap: "5px", flexDirection: "column" }}
              >
                {[8, 16, 64, 128, 256, 512, 1, 2].map((value) => (
                  <StyledLabel
                    key={value}
                    control={
                      <Checkbox
                        onClick={(event) =>
                          onChangeRam(value, event.target.checked)
                        }
                        icon={<UnCheckedIcon />}
                        checkedIcon={<CheckedIcon />}
                        size="small"
                        onChange={(event) => {}}
                      />
                    }
                    label={`${value} ${value <= 2 ? "TB" : "GB"}`}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </div>
      </ProductsGrid>
    </div>
  );
};

export default MobilesPage;
