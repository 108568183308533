import { styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ChangeShareModalInfo } from "../../redux/actions/mainAction";
import { useLocation } from "react-router-dom";

const Root = styled("div")(
  ({ theme, isMobileVersion, extraBottomPosition }) => ({
    right: 20,
    bottom: extraBottomPosition ? 180 : isMobileVersion ? 140 : 160,
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    background: "#fff",
    cursor: "pointer",
    zIndex: 20,
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "transform 0.3s ease-in-out",
    WebkitBoxShadow: "0px 0px 8px 3px rgba(180,208,237,1)",
    MozBoxShadow: "0px 0px 8px 3px rgba(180,208,237,1)",
    boxShadow: "0px 0px 8px 3px rgba(180,208,237,1)",
    transform: isMobileVersion ? "scale(0.9)" : "",
    "&:hover": {
      transform: "scale(1.2)",
    },
  }),
);

const Share = ({ isMobileVersion }) => {
  const dispatch = useDispatch();
  const [showShare, setShowShare] = useState(false);

  const location = useLocation();
  const [extraBottomPosition, setExtraBottomPosition] = useState(false);

  useEffect(() => {
    if (isMobileVersion && location.pathname.includes("/mobiles/")) {
      setExtraBottomPosition(true);
    } else {
      setExtraBottomPosition(false);
    }
  }, [location, isMobileVersion]);

  const handleClick = () => {
    setShowShare(true);
    dispatch(
      ChangeShareModalInfo({
        type: "general",
      }),
    );
  };

  return (
    <Root
      onClick={handleClick}
      onMouseEnter={() => setShowShare(true)}
      onMouseLeave={() => setShowShare(false)}
      isMobileVersion={isMobileVersion}
      extraBottomPosition={extraBottomPosition}
    >
      <img
        src={showShare ? "assets/gifs/share.gif" : "assets/gifs/share.png"}
        alt={"share-icon"}
        width={30}
        height={30}
      />
    </Root>
  );
};

export default Share;
