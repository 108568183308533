import { styled } from "@mui/material";
import HeaderMobileIcon from "../../api/ui/svgIcons/HeaderMobileIcon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  height: "40px",
  minHeight: "40px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  background: "#1875CC",
  padding: "0px 15px",
  justifyContent: "center",
  zIndex: 1001,
}));

const MobileInfoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "6px",
  cursor: "pointer",
  transition: "transform 0.3s ease-in-out",
  width: "fit-content",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const InfoContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  width: "100%",
  justifyContent: "flex-end",
  minWidth: "fit-content",
  justification: "center",
}));

const InfoItems = styled("span")(({ theme }) => ({
  cursor: "pointer",
  fontSize: "11px",
  fontWeight: 600,
  color: "rgba(225,225,225,0.8)",
  transition: "color 0.3s ease-in-out",
  height: "100%",
  "&:hover": {
    color: "#fff",
  },
}));

const SecondaryHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Root>
      <div
        style={{
          maxWidth: "1200px",
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "20px",
          }}
        >
          <MobileInfoContainer>
            <HeaderMobileIcon />
            <span
              style={{
                color: "#fff",
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              +995 (32)2 00 00 00
            </span>
          </MobileInfoContainer>
        </div>
        <InfoContainer>
          <InfoItems onClick={() => navigate("/promotion")}>
            {t("allPromotions")}
          </InfoItems>
          <InfoItems onClick={() => navigate("/branches")}>
            {t("branches")}
          </InfoItems>
          <InfoItems onClick={() => navigate("/tradePolicy")}>
            {t("tradePolicy")}
          </InfoItems>
          <InfoItems onClick={() => navigate("/career")}>
            {t("career")}
          </InfoItems>
          <InfoItems onClick={() => navigate("/tradeIn")}>
            {t("tradeIn")}
          </InfoItems>
        </InfoContainer>
      </div>
    </Root>
  );
};

export default SecondaryHeader;
