import { Divider, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const COLORS = {
  PRIMARY: "#6b6b6b",
  SECONDARY_HEADER: "#1c1c1cce",
};

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const HeaderText = styled("p")({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
});

const CustomDivider = styled(Divider)(({ theme }) => ({
  marginTop: "16px",
  borderColor: "#cccccc",
  marginBottom: "60px",
}));

const SecondaryHeader = styled("p")(({}) => ({
  fontSize: "13px",
  fontWeight: 700,
  color: COLORS.SECONDARY_HEADER,
  marginTop: "10px",
}));

const PrimaryText = styled("p")(({}) => ({
  fontSize: "14px",
  margin: "10px 0px",
  color: COLORS.PRIMARY,
}));

const TradeInPage = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <HeaderText>{t("tradeIn")}</HeaderText>
      <CustomDivider />
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          color: COLORS.SECONDARY_HEADER,
          marginBottom: "10px",
        }}
      >
        საჯარო შეთავაზების შეთანხმება (ოფერტა)
      </Typography>
      <SecondaryHeader>1. განმარტებები და ტერმინები</SecondaryHeader>
      <PrimaryText>
        კლიენტი – ქმედუნარიანი ფიზიკური პირი.
        <br />
        აქცეპტი – კლიენტის სრული და უპირობო თანხმობა (აქცეპტი) შეთანხმების
        დადებაზე წინამდებარე შეთანხმებით განსაზღვრული პირობებით.
        <br />
        მეორადი საქონელი – მოწყობილობა, რომელიც ადრე გამოყენებულია და ვარგისია
        შემდგომი გამოყენებისათვის იმავე მდგომარეობაში ან შეკეთების შემდეგ.
        <br />
        ახალი საქონელი – მოწყობილობა, რომელიც არ გამოუყენებიათ ან გამოუყენებიათ
        შეძენის დღიდან 14 კალენდარული დღის განმავლობაში და რომელსაც არ აქვს
        ტექნიკური დეფექტი, გარეგნობა, შესრულება და ა.შ.
        <br />
        სახელშეკრულებო ღირებულება – მეორადი საქონლის ღირებულება, რომელიც
        განისაზღვრება მისი ტექნიკური მდგომარეობის გათვალისწინებით, ამ
        შეთანხმების მხარეების მიერ.
        <br />
        საცალო მოვაჭრე – საქონლის საცალო ვაჭრობის ქსელი, რომლის მიმართაც კლიენტს
        აქვს ფულადი ვალდებულება გადაიხადოს ახალი საქონლის ღირებულება და
        კომპანიას გაფორმებული აქვს თანამშრომლობის (პარტნიორობის) ხელშეკრულება.
        საცალო მოვაჭრის სახელები და დეტალები მითითებული უნდა იყოს წინამდებარე
        საჯარო შეთავაზების შეთანხმების (ოფერტა) დადასტურების განცხადებაში.
      </PrimaryText>
      <SecondaryHeader>2. შეთანხმების საგანი</SecondaryHeader>
      <PrimaryText>
        2.1. წინამდებარე შეთანხმების თანახმად, კლიენტი მიყიდის კომპანიას მეორად
        მოწყობილობებს, რომელიც ეკუთვნის კლიენტს (შემდგომში „მეორადი საქონელი“)
        და რომელიც აკმაყოფილებს წინამდებარე შეთანხმების მოთხოვნებს, ხოლო
        კომპანია უხდის კლიენტს გამოყენებული ნივთის სახელშეკრულებო ღირებულებას
        საცალო მოვაჭრისათვის გადარიცხვის გზით წინამდებარე შეთანხმების
        დადასტურების განცხადების შესაბამისად (შემდგომში - განცხადება), რომელიც
        წარმოადგენს ამ შეთანხმების დანართს.
        <br />
        2.2. ითვლება, რომ კლიენტმა შეასრულა ვალდებულება კომპანიის მიმართ, რაც
        გამოიხატება მეორადი საქონლის გადაცემაში, კერძოდ, მეორადი საქონლის
        კომპანიისთვის მიყიდვის დროს, რომლის სახელით და ინტერესებიდან გამომდინარე
        მოქმედებს საცალო მოვაჭრე.
        <br />
        2.3. მეორადი საქონლის მიღებამდე საცალო მოვაჭრე ვალდებულია განახორციელოს
        დიაგნოსტიკა (შეამოწმოს მეორადი საქონლის შესაბამისობა წინამდებარე
        შეთანხმების მოთხოვნებთან, მისი ექსპლუატაციისუნარიანობა, ტექნიკური
        მდგომარეობა, გარეგნობის შეფასება, აღჭურვილობა, შესრულება და ა.შ.) და არ
        მიიღოს მეორადი საქონელი, რომელიც არ შეესაბამება შეთანხმებას.
        <br />
        2.4. ითვლება, რომ კომპანიამ შეასრულა კლიენტის წინაშე მეორადი საქონლის
        ღირებულების გადახდის ვალდებულება, კერძოდ, კლიენტის მიერ ახალი საქონლის
        საცალო მოვაჭრისგან მიღების დროს, რომლის ნაწილობრივი საფასურიც კომპანიამ
        კლიენტის სახელით გადაურიცხა საცალო მოვაჭრეს, რაც წარმოადგენს მეორადი
        საქონლის სახელშეკრულებო ღირებულებას.
        <br />
        2.5. მეორადი საქონლის გასაყიდად, კლიენტი უნდა იყოს ქმედუნარიანი და 18
        წელს მიღწეული პირი
      </PrimaryText>
      <SecondaryHeader>3. შეთანხმების დადება</SecondaryHeader>
      <PrimaryText>
        <br />
        3.1. შეთანხმების პირობების უპირობო მიღება ხდება კლიენტის მიერ
        წინამდებარე შეთანხმებასთან დადასტურების განცხადების ხელმოწერით, რომელიც
        წარმოადგენს ამ შეთანხმების განუყოფელ ნაწილს.
        <br />
        3.2. შეთანხმების დადების თარიღი არის კომპანიის მიერ ხელმოწერილი
        განცხადების მიღების თარიღი.
      </PrimaryText>
      <SecondaryHeader>4. ყიდვა-გაყიდვის პროცედურა და პირობები</SecondaryHeader>
      <PrimaryText>
        4.1. მეორადი და ახალი საქონლის ყიდვა-გაყიდვა ხორციელდება შემდეგი
        თანმიმდევრობით:
        <br />
        4.1.1. კლიენტი მეორადი საქონლის მიღებაჩაბარების აქტის საფუძველზე მის
        მიერ ხელმოწერილ განაცხადთან ერთად გადასცემს მის საკუთრებაში არსებულ
        მეორად საქონელს კომპანიას საცალო მოვაჭრობის შენობაში, ხოლო კომპანია,
        რომელიც მოქმედებს საცალო მოვაჭრის სახელით და კომპანიის ინტერესებიდან
        გამომდინარე, იღებს მეორად საქონელს.
        <br />
        4.1.2. განცხადებაში და მეორადი საქონლის მიღებაჩაბარების აქტში უნდა
        მიეთითოს კლიენტისთვის გადასახდელი მეორადი საქონლის სახელშეკრულებო
        ღირებულება, რომელიც შეთანხმებულია მხარეებს შორის მეორადი საქონლის
        ტექნიკური მდგომარეობიდან გამომდინარე.
        <br />
        4.1.3. კლიენტს წარმოეშობა ფულადი ვალდებულება გადაიხადოს ახალი საქონლის
        ღირებულება იმ საცალო მოვაჭრის მიმართ, რომელსაც განაცხადი წარედგინა,
        რისთვისაც კლიენტი აპლიკაციაში ავალებს კომპანიას, გადარიცხოს კლიენტის
        სახელით სახელშეკრულებო ღირებულება საცალო მოვაჭრის საბანკო ანგარიშზე,
        რომელსაც გადაეცა მეორადი საქონელი. ამ გადახდასთან დაკავშირებულ ყველა
        საბანკო ხარჯს იხდის კომპანია.
        <br />
        4.1.4. კომპანია არ არის პასუხისმგებელი კლიენტის წინაშე ახალი საქონლის
        გაყიდვასთან / დაბრუნებასთან / ჩანაცვლებასთან და ასეთი ახალი საქონლის
        ხარისხთან დაკავშირებულ ნებისმიერ საკითხზე.
        <br />
        4.2. განაცხადის წარდგენისა და მეორადი საქონლის მიღება-ჩაბარების აქტის
        საფუძველზე გადაცემის დროს, მეორადი საქონლის საკუთრების უფლება და ყველა
        რისკი გადადის კლიენტიდან კომპანიაზე, ხოლო კლიენტისათვის მეორადი საქონლის
        დაბრუნება შეუძლებელია.
        <br />
        4.3. კლიენტი ვალდებულია შეიძინოს ახალი საქონელი მხოლოდ მის მიერ
        განაცხადში მითითებულ საცალო მოვაჭრის ქსელის ნებისმიერ მაღაზიაში.
      </PrimaryText>
      <SecondaryHeader>5. მოთხოვნები მეორადი საქონლის მიმართ</SecondaryHeader>
      <PrimaryText>
        5.1. კომპანია შეიძენს მხოლოდ მეორად საქონელს, რომელიც აკმაყოფილებს ყველა
        შემდეგ მოთხოვნას:
        <br />
        5.1.1. კლიენტის საკუთრებაშია და არ არის დატვირთული მესამე მხარის
        უფლებით;
        <br />
        5.1.2. არის სამუშაო მდგომარეობაში (ე.ი. აღჭურვილობის ყველა ფუნქცია
        მუშაობს ასეთი აღჭურვილობის მწარმოებლის მიერ დადგენილი ინდიკატორებისა და
        პარამეტრების შესაბამისად);
        <br />
        5.1.3. არ აქვს სითხის დაზიანების კვალი (კოროზიის კვალი);
        <br />
        5.1.4. არ არის გატეხილი, არ აქვს მოწყობილობის დასატენად გატეხილი
        კონექტორები;
        <br />
        5.1.5. ოპერატორის მიერ არ არის დაბლოკილი;
        <br />
        5.1.6. არ არის ძებნაში;
        <br />
        5.1.7. მოწყობილობის დისპლეი უნდა იყოს თავისუფალი ლაქებისგან,
        ბზარებისგან, ვერტიკალური ან ჰორიზონტალური ზოლებისგან, შუშის ქვეშ
        მტვრისგან;
        <br />
        5.1.8. არის მოწყობილობაზე მითითებული პაროლებისა და კოდების გარეშე;
        <br />
        5.1.9. არის ანგარიშების გარეშე;
        <br />
        5.1.10. იყოს კლიენტის მფლობელობაში. თუ შესაძლებელია, კლიენტმა უნდა
        დააფიქსიროს საქონლის გამოყენებადობა (მაგალითად, მეორადი საქონლის
        შესყიდვის ქვითარი, რომელიც შეიცავს მისი შეძენის თარიღს და/ან კლიენტის
        განცხადებას ან სხვა დოკუმენტს).
        <br />
        5.1.11. მოწყობილობის დისპლეი უნდა იძლეოდეს დიაგნოსტიკის საშუალებას.
        ასევე შესაძლოა მოწყობილობის დისპლეი იყოს დაზიანებული, თუმცა შესაძლებელი
        უნდა იყოს დიაგნოსტიკა.
      </PrimaryText>
      <SecondaryHeader>6. კონფიდენციალურობა</SecondaryHeader>
      <PrimaryText>
        6.1. ინფორმაცია, რომელიც მხარეთათვის გახდა ცნობილი ურთიერთობის
        ურთიერთობის შესახებ წინამდებარე შეთანხმების დადებისა და განხორციელების
        დროს, აგრეთვე ინფორმაცია, რომელიც დაკავშირებულია ამ შეთანხმების საგანთან
        ან მის დადებასთან ან განხორციელებასთან, კონფიდენციალურია.
        <br />
        6.2. კონფიდენციალურობა წინამდებარე შეთანხმების მნიშვნელობით ნიშნავს
        მესამე მხარისათვის შესაბამისი ინფორმაციის გამჟღავნების დაუშვებლობას
        მეორე მხარის წერილობითი თანხმობის გარეშე.
        <br />
        6.3. მხარეები იღებენ ვალდებულებას, არ გამოიყენონ ინფორმაცია, რომელიც
        მათთვის ცნობილი გახდა წინამდებარე შეთანხმების დადებასთან ან/და
        განხორციელებასთან დაკავშირებით ერთმანეთის საზიანოდ.
      </PrimaryText>
      <SecondaryHeader>7. პერსონალური მონაცემების დამუშავება</SecondaryHeader>
      <PrimaryText>
        7.1. განაცხადის ხელმოწერით კლიენტი აძლევს ინფორმირებულ და ნებაყოფლობით
        თანხმობას კომპანიას და საცალო მოვაჭრეებს, დაამუშავონ აპლიკაციაში
        მითითებული მისი პერსონალური მონაცემები და კლიენტის მიერ გადაცემული და
        მხარეების მიერ წინამდებარე ხელშეკრულების ფარგლებში ხელმოწერილი სხვა
        დოკუმენტები, ე.ი. ავტომატიზირებული, არა-ავტომატური და შერეული
        დამუშავება, მათ შორის შეგროვება, რეგისტრაცია, დაგროვება, შენახვა,
        ადაპტაცია, მოდიფიკაცია, განახლება, გამოყენება, დეპერსონალიზაცია,
        განადგურება და გავრცელება (გავრცელება, გაყიდვა, გადაცემა) პერსონალური
        მონაცემების (i) საჯარო საკომუნიკაციო ქსელებზე, (ii) საერთაშორისო
        საინფორმაციო და სატელეკომუნიკაციო ქსელების ინფორმაციის გაცვლა,
        პერსონალურ მონაცემებთან დაკავშირებული ურთიერთობის უცხოური სუბიექტების
        ჩათვლით, აგრეთვე თანხმობა კლიენტის პერსონალური მონაცემების მიღებაზე
        ნებისმიერი სამართლებრივი წყაროდან, მათ შორის თავად და მესამე მხარისგან,
        ამ შეთანხმებისათვის საჭირო ინფორმაციისა და განხორციელებისათვის.
        <br />
        7.2. განაცხადის ხელმოწერით კლიენტი თანახმაა გადასცეს თავისი პერსონალური
        მონაცემები მესამე მხარეს - კომპანიის დეტალებში მითითებულ საბანკო
        დაწესებულებას, ამ ხელშეკრულების მხარეებს შორის ანგარიშსწორებისთვის და
        მათი განხორციელებისთვის საჭირო ზომით, ასევე გადასცეს. კომპანიის
        შვილობილი (დაკავშირებული) პერსონალური მონაცემები, კომპანიის იურიდიული
        პირები და მენეჯერები, რათა შეასრულონ წინამდებარე ხელშეკრულების პირობები.
        <br />
        7.3. კლიენტის პერსონალური მონაცემების დამუშავება და შენახვა ხორციელდება
        ხელშეკრულების ვადის განმავლობაში.
        <br />
        7.4. ეს თანხმობა შეიძლება გააუქმოს კლიენტმა ნებისმიერ დროს,
        კომპანიისთვის რეგისტრირებული ფოსტით გაგზავნილი გაუქმების შესახებ
        წერილობითი შეტყობინების გზით.
        <br />
        7.5. ამ თანხმობის გაუქმება ძალაში შედის კომპანიისთვის შესაბამისი
        შეტყობინების მიწოდების დღიდან.
      </PrimaryText>
      <SecondaryHeader>8. გარანტიები და პასუხისმგებლობა</SecondaryHeader>
      <PrimaryText>
        8.1. წინამდებარე ხელშეკრულების გაფორმებით მხარეები ადასტურებენ, რომ:
        <br />
        8.1.1. ისინი არ მალავენ გარემოებებს, რომლებიც არსებითია ამ
        შეთანხმებისთვის;
        <br />
        8.1.2. ამ შეთანხმების დადება არ დაარღვევს სხვათა უფლებებსა და ლეგიტიმურ
        ინტერესებს, მათ შორის არასრულწლოვანთა, არასრულწლოვანთა, შეზღუდული
        შესაძლებლობის მქონე ბავშვებს და სხვა პირებს, რომლებიც მხარეები
        ვალდებულნი არიან შეინარჩუნონ კანონით ან ხელშეკრულებით;
        <br />
        8.1.3. ხელშეკრულება არ არის დადებული მათთვის რთული გარემოებების
        გავლენით;
        <br />
        8.1.4. ისინი არ ცხადდებიან ქმედუუნაროდ ან ნაწილობრივ ქმედუუნაროდ;
        <br />
        8.1.5. ხელშეკრულების დადება აკმაყოფილებს მათ ინტერესებს;
        <br />
        8.1.6. ნების გამოხატვა თავისუფალია, შეგნებული და შეესაბამება მათ შინაგან
        ნებას;
        <br />
        8.1.7. ხელშეკრულების პირობები ნათელია და შეესაბამება მხარეთა ფაქტობრივ
        შეთანხმებას;
        <br />
        8.1.8. ხელშეკრულება არ მალავს სხვა გარიგებას და მიზნად ისახავს მასში
        გათვალისწინებული შედეგების ფაქტობრივ წარმოშობას;
        <br />
        8.2. ამ ხელშეკრულების დადებისას კლიენტმა იცის თავისი ქმედებების
        მნიშვნელობა და შეუძლია მათი მართვა, ასევე ესმის ამ ტრანზაქციის ბუნება.
        <br />
        8.3. შეთანხმების დარღვევის შემთხვევაში მხარე, რომელიც არღვევს,
        პასუხისმგებელია წინამდებარე შეთანხმებისა და საქართველოს მოქმედი
        კანონმდებლობით.
        <br />
        8.4. ხელშეკრულების დარღვევა არის მისი შეუსრულებლობა ან არაჯეროვანი
        შესრულება, ანუ შესრულება ამ ხელშეკრულებით განსაზღვრული პირობების
        დარღვევით.
        <br />
        8.5. მხარე არ იქნება პასუხისმგებელი ხელშეკრულების დარღვევისთვის, თუ ეს
        მოხდა მისი ბრალის გარეშე (განზრახ ან გაუფრთხილებლობით).
        <br />
        8.6. კლიენტის მიერ ამ ნაწილში მითითებული გარანტიების დარღვევის
        შემთხვევაში, კლიენტი ვალდებულია სრულად აუნაზღაუროს კომპანიას ზიანი.
      </PrimaryText>
      <SecondaryHeader>
        9. ხელშეკრულების ვადა. ცვლილებები შეთანხმებაში
      </SecondaryHeader>
      <PrimaryText>
        9.1. წინამდებარე ხელშეკრულება ძალაში შედის კლიენტის მიერ მისი
        მიღებისთანავე და ძალაში რჩება მანამ, სანამ მხარეები სრულად არ
        შეასრულებენ ამ ხელშეკრულებით ნაკისრ ვალდებულებებს.
        <br />
        9.2. კომპანიას უფლება აქვს შეცვალოს წინამდებარე ხელშეკრულების პირობები.
        თუ ხელშეკრულება მიიღება, მაშინ ძალაშია მიღების თარიღისთვის მოქმედი
        ხელშეკრულების ვერსია.
      </PrimaryText>
    </Root>
  );
};

export default TradeInPage;
