import { IconButton, styled } from "@mui/material";

import { useTranslation } from "react-i18next";
import {
  CategoryIcon,
  CompareIcon,
  GiftIcon,
  HomeIcon,
  UserIcon,
} from "../svgIcons";
import { useEffect, useRef, useState } from "react";
import {
  openLoginModal,
  updateMaintenanceModalOpen,
} from "../redux/actions/mainAction";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  background: "#FFFFFF",
  borderTop: "1px solid #f5f5f5",
  position: "fixed",
  bottom: 0,
  zIndex: 18,
  padding: "0px 10px 10px",
}));

const MenuContainer = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
}));

const MenuItem = styled("div")(({ isActive }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flex: "1",
  "& svg": {
    color: "#ababab",
  },
}));

const SpecialMenuItem = styled(MenuItem)(({ theme }) => ({
  position: "relative",
  "& button": {
    position: "absolute",
    backgroundColor: "#5763752e",
    top: "-20px",
    "&:hover": {
      background: "#3C8BFF",
    },
  },
  "& svg": {
    color: "#FFFFFF",
  },
}));

const MenuText = styled("div")(({ theme }) => ({
  fontSize: "12px",
  color: "#666666",
}));

const SpecialMenuText = styled(MenuText)(({ theme }) => ({
  position: "absolute",
  bottom: "5px",
}));

const Indicator = styled("div")(() => ({
  position: "absolute",
  height: "2px",
  backgroundColor: "#3688FF",
  transition: "left 0.2s ease-in-out; opacity 0.1s ease-in-out",
}));

const MobileFooter = ({
  openPrizeModal,
  setOpenPrizeModal,
  isUserAuthorized,
  isLoginOpen,
  isRegisterOpen,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("home");
  const [index, setIndex] = useState(0);
  const menuRef = useRef(null);
  const [itemWidth, setItemWidth] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (menuRef.current) {
      const containerWidth = menuRef.current.clientWidth;
      const width = containerWidth / 5;
      setItemWidth(width);
    }
  }, [menuRef.current?.clientWidth]);

  useEffect(() => {
    switch (activeTab) {
      case "home":
        setIndex(0);
        break;
      case "category":
        setIndex(1);
        break;
      case "prize":
        setIndex(2);
        setOpenPrizeModal(true);
        break;
      case "compare":
        setIndex(3);
        break;
      case "logIn":
        setIndex(4);
        break;
      case "profile":
        setIndex(4);
        break;
      default:
        setIndex(0);
        break;
    }
    if (activeTab === "compare") {
      dispatch(updateMaintenanceModalOpen(true));
    }

    if (activeTab === "category") {
      navigate("/category");
    }
  }, [activeTab]);

  useEffect(() => {
    if (location) {
      if (location.pathname === "/") {
        setActiveTab("home");
      }
    }
  }, [location]);

  useEffect(() => {
    if (!isRegisterOpen && !isLoginOpen && activeTab === "logIn") {
      if (location.pathname === "/") {
        setActiveTab("home");
      }

      if (location.pathname === "/category") {
        setActiveTab("category");
      }
    }
  }, [isLoginOpen, isRegisterOpen, location]);

  return (
    <Root>
      <MenuContainer ref={menuRef}>
        <Indicator
          style={{
            width: `${itemWidth}px`,
            left: `${index * itemWidth}px`,
            opacity: activeTab === "prize" ? 0 : 1,
          }}
        />
        <MenuItem
          isActive={activeTab === "home"}
          onClick={() => {
            setActiveTab("home");
            navigate("/");
          }}
        >
          <IconButton>
            <HomeIcon />
          </IconButton>
          <MenuText>{t("home")}</MenuText>
        </MenuItem>
        <MenuItem
          isActive={activeTab === "category"}
          onClick={() => setActiveTab("category")}
        >
          <IconButton>
            <CategoryIcon />
          </IconButton>
          <MenuText>{t("category")}</MenuText>
        </MenuItem>
        <SpecialMenuItem
          isActive={activeTab === "prize"}
          onClick={() => setActiveTab("prize")}
        >
          <IconButton>
            <GiftIcon />
          </IconButton>
          <SpecialMenuText>{t("prize")}</SpecialMenuText>
        </SpecialMenuItem>
        <MenuItem
          isActive={activeTab === "compare"}
          onClick={() => setActiveTab("compare")}
        >
          <IconButton>
            <CompareIcon />
          </IconButton>
          <MenuText>{t("compare")}</MenuText>
        </MenuItem>
        <MenuItem
          isActive={
            isUserAuthorized ? activeTab === "profile" : activeTab === "logIn"
          }
          onClick={() => {
            if (isUserAuthorized) {
              setActiveTab("profile");
              navigate("/profile");
            } else {
              setActiveTab("logIn");
              dispatch(openLoginModal(true));
            }
          }}
        >
          <IconButton>
            <UserIcon />
          </IconButton>
          <MenuText>{isUserAuthorized ? t("profile") : t("logIn")}</MenuText>
        </MenuItem>
      </MenuContainer>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  isUserAuthorized: state.main.isUserAuthorized,
  isLoginOpen: state.main.isLoginOpen,
  isRegisterOpen: state.main.isRegisterOpen,
});

const dispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, dispatchToProps)(MobileFooter);
