import {
  REMOVE_PRODUCT,
  RESTORE_PRODUCTS_FROM_LOCALSTORAGE,
  SAVE_PRODUCTS,
} from "../constants/savedProductConstants";
import { setLocalStorageValue } from "../../util/appUtil";
import { enqueueSnackbar } from "notistack";
import { t } from "i18next";

const initialState = {
  products: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_PRODUCTS:
      if (state.products.some((item) => item.id === action.payload.id)) {
        enqueueSnackbar(t("productAlreadyAdded"), {
          variant: "info",
        });
        return {
          ...state,
        };
      } else {
        const productAddedList = [...state.products, action.payload];
        setLocalStorageValue(
          "savedProductItems",
          JSON.stringify(productAddedList),
        );

        enqueueSnackbar(t("productAddedSuccessfully"), {
          variant: "success",
        });

        return {
          ...state,
          products: productAddedList,
        };
      }
    case REMOVE_PRODUCT:
      const productRemovedList = [
        ...state.products.filter((item) => item.id !== action.payload.id),
      ];
      setLocalStorageValue(
        "savedProductItems",
        JSON.stringify(productRemovedList),
      );
      return {
        ...state,
        products: productRemovedList,
      };
    case RESTORE_PRODUCTS_FROM_LOCALSTORAGE:
      return {
        ...state,
        products: action.payload,
      };
    default:
      return state;
  }
}
