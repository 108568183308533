import { Divider, styled } from "@mui/material";
import CategoryItem from "../CategorySwiper/CategoryItem";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const HeaderText = styled("p")({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
});

const CustomDivider = styled(Divider)(({ theme }) => ({
  marginTop: "16px",
  borderColor: "#cccccc",
  marginBottom: "60px",
}));

const PromotionPage = ({ data, cardContainerRef }) => {
  const { t } = useTranslation();
  return (
    <Root>
      <HeaderText>{t("promotion")}</HeaderText>
      <CustomDivider />
      <Box
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: "40px",
        }}
      >
        {data.map((item, index) => {
          return (
            <CategoryItem
              cardContainerRef={cardContainerRef}
              categoryInfo={item}
              key={index}
            />
          );
        })}
      </Box>
    </Root>
  );
};

export default PromotionPage;
