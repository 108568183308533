import { useEffect } from "react";
import { GoogleResponse } from "../../api/services/Auth.api";
import { useNavigate } from "react-router-dom";

const GoogleAuthContainer = () => {
  const navigate = useNavigate();
  useEffect(() => {
    GoogleResponse()
      .then(() => {})
      .finally(() => {
        navigate("/");
      });
  }, []);
  return <div></div>;
};

export default GoogleAuthContainer;
