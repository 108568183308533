import React from "react";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: 8,
    padding: 16,
    minWidth: 300,
    maxWidth: 500,
  },
});

const StyledDialogTitle = styled(DialogTitle)({
  padding: "16px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .MuiTypography-root": {
    fontWeight: 600,
  },
});

const StyledDialogContent = styled(DialogContent)({
  padding: "16px",
  "& .MuiTypography-root": {
    color: "#666",
  },
});

const StyledDialogActions = styled(DialogActions)({
  padding: "16px 16px 0 16px",
  "& .MuiButton-root": {
    minWidth: 100,
  },
});

const StyledCloseButton = styled(IconButton)({
  position: "absolute",
  right: 8,
  top: 8,
  color: "#999",
});

const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
  confirmButtonText = "yes",
  cancelButtonText = "no",
}) => {
  const { t } = useTranslation();

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="confirmation-dialog-title"
    >
      <StyledDialogTitle id="confirmation-dialog-title">
        <Typography variant="h6">{title}</Typography>
        <StyledCloseButton onClick={onClose} aria-label="close">
          <CloseIcon />
        </StyledCloseButton>
      </StyledDialogTitle>

      <StyledDialogContent>
        <Typography>{message}</Typography>
      </StyledDialogContent>

      <StyledDialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{ color: "#666", borderColor: "#666" }}
        >
          {t(cancelButtonText)}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onConfirm();
            onClose();
          }}
          autoFocus
        >
          {t(confirmButtonText)}
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ConfirmationModal;
