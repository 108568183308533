import { styled } from "@mui/material";
import { useState } from "react";

const Root = styled("div")(({ theme, showScrollUp }) => ({
  position: "fixed",
  right: showScrollUp ? 45 : 20,
  bottom: showScrollUp ? 255 : 230,
  borderRadius: "50%",
  width: showScrollUp ? "0px" : "50px",
  height: showScrollUp ? "0px" : "50px",
  overflow: "hidden",
  background: "#fff",
  transition:
    "transform 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out, right 0.3s ease-in-out, bottom 0.3s ease-in-out",
  cursor: "pointer",
  zIndex: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  WebkitBoxShadow: showScrollUp ? "" : "-1px 0px 8px 3px rgba(180,208,237,1)",
  MozBoxShadow: showScrollUp ? "" : "-1px 0px 8px 3px rgba(180,208,237,1)",
  boxShadow: showScrollUp ? "" : "-1px 0px 8px 3px rgba(180,208,237,1)",
  "&:hover": {
    transform: "scale(1.2)",
  },
}));

const ScrollUp = ({ showScrollUp }) => {
  const [showGIF, setShowGIF] = useState(false);
  const onScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Root
      onMouseEnter={() => setShowGIF(true)}
      onMouseLeave={() => setShowGIF(false)}
      showScrollUp={!showScrollUp}
      onClick={() => onScrollTop()}
    >
      <img
        src={showGIF ? "assets/gifs/upload.gif" : "assets/gifs/upload.png"}
        alt={"notification-icon"}
        width={30}
        height={30}
      />
    </Root>
  );
};

export default ScrollUp;
