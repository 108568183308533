import { Popover, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import { LogOutDispatch } from "../../redux/actions/mainAction";
import { useDispatch } from "react-redux";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";

const ButtonContainer = styled("div")(({ theme }) => ({
  transition: "background 0.3s ease-in-out",
  padding: "10px",
  background: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "fit-content",
  cursor: "pointer",
  filter: "drop-shadow(rgba(0, 0, 0, 0.1) 0px 2px 10px)",
  borderRadius: "12px",
  gap: "10px",
  "&:hover": {
    background: "#f2f2f2",
  },
}));

const CustomPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "12px",
  },
}));

const Item = styled("div")(({ theme }) => ({
  color: "#1875CC",
  display: "flex",
  gap: "10px",
  alignItems: "center",
  cursor: "pointer",
  padding: "7px",
  boxSizing: "border-box",
  fontWeight: 500,
  border: "1px solid #fff",
  borderRadius: "8px",
  transition: "border 0.3s ease-in-out, color 0.3s ease-in-out",
  "& .MuiSvgIcon-root": {
    color: "#1875CC",
    width: "20px",
    height: "20px",
  },
  "&:hover": {
    border: "1px solid #1875CC",
  },
}));

const AuthorizedUserMenu = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <ButtonContainer onClick={handleClick}>
        <AccountCircleIcon
          style={{
            color: "#125799",
          }}
        />
        {t("profile")}
      </ButtonContainer>
      <CustomPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          top: "4px",
        }}
        disableScrollLock={true}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "200px",
            padding: "10px",
          }}
        >
          <Item
            onClick={() => {
              navigate("user-info");
              setAnchorEl(null);
            }}
          >
            <InfoIcon />
            <span>{t("aboutMe")}</span>
          </Item>
          <Item
            onClick={() => {
              navigate("prize");
              setAnchorEl(null);
            }}
          >
            <DonutSmallIcon />
            <span>{t("progress")}</span>
          </Item>
          <Item
            onClick={() => {
              navigate("promo-code");
              setAnchorEl(null);
            }}
          >
            <AssessmentIcon />
            <span>{t("promoCode")}</span>
          </Item>
          <Item>
            <SettingsIcon />
            <span>{t("settings")}</span>
          </Item>
          <Item
            onClick={() => {
              dispatch(LogOutDispatch());
              setAnchorEl(null);
            }}
          >
            <LogoutIcon />
            <span>{t("logOut")}</span>
          </Item>
        </div>
      </CustomPopover>
    </div>
  );
};

export default AuthorizedUserMenu;
