import CategorySwiper from "../CategorySwiper/CategorySwiper";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import Banner from "./Banner";
import BrandSwiper from "../CategorySwiper/BrandSwiper";
import PromoBanner from "../PromoBanner";
import SimpleBanner from "../SimpleBanner";
import Box from "@mui/material/Box";
import { useEffect, useMemo, useState } from "react";
import { GetAllBanner } from "../../api/services/banner.api";
import { ProductOfferCategory } from "../../util/appUtil";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
}));

const SwiperContainer = styled("div")(({ theme, isMobileVersion }) => ({
  display: "flex",
  gap: isMobileVersion ? "20px" : "60px",
  flexDirection: "column",
}));

const Lobby = ({ cardContainerRef, setMainLoading, isMobileVersion }) => {
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    GetAllBanner()
      .then((resp) => {
        setData(JSON.parse(resp.data.data));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const firstPromoBannerInfo = useMemo(() => {
    const items = data.filter((item) => item.IsMain);
    let result = {
      title: "",
      imageSrc: "",
      endTime: "",
    };
    if (items && items.length > 0) {
      const imageSrc = `data:${items[0].FileFormat};base64,${items[0].Image}`;
      return {
        title: items[0].Name,
        imageSrc: imageSrc,
        endTime: items[0].FinishedDate,
      };
    }

    return result;
  }, [data]);

  const secondPromoBannerInfo = useMemo(() => {
    const items = data.filter((item) => item.IsMain);
    let result = {
      title: "",
      imageSrc: "",
      endTime: "",
    };
    if (items && items.length > 1) {
      const imageSrc = `data:${items[1].FileFormat};base64,${items[1].Image}`;
      return {
        title: items[1].Name,
        imageSrc: imageSrc,
        endTime: items[1].FinishedDate,
      };
    }

    return result;
  }, [data]);

  return (
    <Root>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          margin: "10px",
        }}
      >
        <PromoBanner
          loading={loading}
          data={firstPromoBannerInfo}
          isMobileVersion={isMobileVersion}
        />
        {!isMobileVersion && (
          <PromoBanner loading={loading} data={secondPromoBannerInfo} />
        )}
      </Box>
      <Banner
        data={[...data.filter((item) => !item.IsMain)]}
        loading={loading}
        isMobileVersion={isMobileVersion}
      />
      <SwiperContainer isMobileVersion={isMobileVersion}>
        <CategorySwiper
          title={t("mobiles")}
          cardContainerRef={cardContainerRef}
          categoryName={ProductOfferCategory.Mobiles}
          showFull={"/mobiles"}
          isMobileVersion={isMobileVersion}
        />
        <CategorySwiper
          title={t("topProduct")}
          cardContainerRef={cardContainerRef}
          categoryName={ProductOfferCategory.TopOffers}
          isMobileVersion={isMobileVersion}
        />
        <BrandSwiper />
        <div style={{ display: "flex", gap: "10px" }}>
          {isMobileVersion && (
            <>
              <SimpleBanner
                loading={loading}
                data={data}
                index={0}
                isMobileVersion={isMobileVersion}
              />
              <SimpleBanner
                loading={loading}
                data={data}
                index={1}
                isMobileVersion={isMobileVersion}
              />
            </>
          )}
          {!isMobileVersion && (
            <>
              <SimpleBanner
                loading={loading}
                data={data}
                index={0}
                isMobileVersion={isMobileVersion}
              />
              <SimpleBanner
                loading={loading}
                data={data}
                index={1}
                isMobileVersion={isMobileVersion}
              />
              <SimpleBanner
                loading={loading}
                data={data}
                index={2}
                isMobileVersion={isMobileVersion}
              />
              <SimpleBanner
                loading={loading}
                data={data}
                index={3}
                isMobileVersion={isMobileVersion}
              />
            </>
          )}
        </div>
        <CategorySwiper
          title={t("theBestOffers")}
          cardContainerRef={cardContainerRef}
          categoryName={ProductOfferCategory.BestOffer}
          isMobileVersion={isMobileVersion}
        />
        <CategorySwiper
          title={t("newModels")}
          cardContainerRef={cardContainerRef}
          categoryName={ProductOfferCategory.NewModels}
          isMobileVersion={isMobileVersion}
        />
        <div style={{ display: "flex", gap: "10px" }}>
          {isMobileVersion && (
            <>
              <SimpleBanner
                loading={loading}
                data={data}
                index={2}
                isMobileVersion={isMobileVersion}
              />
              <SimpleBanner
                loading={loading}
                data={data}
                index={3}
                isMobileVersion={isMobileVersion}
              />
            </>
          )}
          {!isMobileVersion && (
            <>
              <SimpleBanner
                loading={loading}
                data={data}
                index={4}
                isMobileVersion={isMobileVersion}
              />
              <SimpleBanner
                loading={loading}
                data={data}
                index={5}
                isMobileVersion={isMobileVersion}
              />
              <SimpleBanner
                loading={loading}
                data={data}
                index={6}
                isMobileVersion={isMobileVersion}
              />
              <SimpleBanner
                loading={loading}
                data={data}
                index={7}
                isMobileVersion={isMobileVersion}
              />
            </>
          )}
        </div>
        <CategorySwiper
          title={t("secondHandModels")}
          cardContainerRef={cardContainerRef}
          categoryName={ProductOfferCategory.SecondHandModels}
          isMobileVersion={isMobileVersion}
        />
      </SwiperContainer>
      {/*<button onClick={() => LoginUser()}>Login User</button>
          <button onClick={() => loginWithFb()}>Login With Facebook</button>
          <button onClick={() => loginWithGoogle()}>
              Login With Google
          </button>
          <button onClick={() => loginWithTwitter()}>
              Login With Twitter
          </button>
          <button onClick={() => loginWithLinkedIn()}>
              Login With LinkedIn
          </button>
          <button onClick={() => check()}>Check Authorization</button>
          <button onClick={() => logOutSystem()}>Log Out</button>
          <div
              style={{
                  height: "2000px",
              }}
          ></div>*/}
    </Root>
  );
};

export default Lobby;
