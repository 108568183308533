import React, { useRef, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";

const Container = styled("div")({
  position: "relative",
  width: "100%",
  overflow: "hidden",
});

const Content = styled("div")({
  height: "100%",
  width: "100%",
  paddingRight: "10px",
  overflow: "auto",

  "&": {
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
  },
});

const ScrollThumb = styled("div")({
  position: "absolute",
  width: "4px",
  background: "#3281FE",
  borderRadius: "20px",
  cursor: "pointer",
  opacity: 0.8,

  "&:hover": {
    background: "#3281FE",
    opacity: 1,
  },
});

const ScrollTrack = styled("div")({
  position: "absolute",
  right: "0px",
  top: 0,
  bottom: 0,
  width: "4px",
  background: "#57637529",
  borderRadius: "20px",
});

const CustomScrollbar = ({ children }) => {
  const contentRef = useRef(null);
  const thumbRef = useRef(null);
  const [thumbHeight, setThumbHeight] = useState(20);
  const [scrollStartPosition, setScrollStartPosition] = useState(null);
  const [initialScrollTop, setInitialScrollTop] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);

  const calculateThumbHeight = () => {
    if (!contentRef.current) return;
    const { scrollHeight, clientHeight } = contentRef.current;
    return Math.max((clientHeight / scrollHeight) * clientHeight, 40);
  };

  const checkIfScrollable = () => {
    if (contentRef.current) {
      const { scrollHeight, clientHeight } = contentRef.current;
      setIsScrollable(scrollHeight > clientHeight);

      // Calculate and set initial thumb height
      const newThumbHeight = calculateThumbHeight();
      setThumbHeight(newThumbHeight);

      // Set initial thumb position
      if (thumbRef.current) {
        thumbRef.current.style.top = "0px";
      }
    }
  };

  const handleThumbPosition = () => {
    if (!contentRef.current || !thumbRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
    const trackHeight = clientHeight;
    const newThumbHeight = calculateThumbHeight();
    const thumbPosition = (scrollTop / scrollHeight) * trackHeight;

    setThumbHeight(newThumbHeight);
    thumbRef.current.style.top = `${thumbPosition}px`;
  };

  const handleTrackClick = (e) => {
    const { clientY } = e;
    const { current: contentElement } = contentRef;
    const { top: trackTop } = e.currentTarget.getBoundingClientRect();
    const clickPosition = clientY - trackTop;
    const thumbOffset = thumbHeight / 2;

    contentElement.scrollTop =
      ((clickPosition - thumbOffset) / contentElement.clientHeight) *
      contentElement.scrollHeight;
  };

  const handleThumbMousedown = (e) => {
    setScrollStartPosition(e.clientY);
    setInitialScrollTop(contentRef.current.scrollTop);
    setIsDragging(true);
  };

  const handleThumbMouseup = () => {
    setIsDragging(false);
  };

  const handleThumbMousemove = (e) => {
    if (!isDragging) return;

    const { scrollHeight, clientHeight } = contentRef.current;
    const deltaY = e.clientY - scrollStartPosition;
    const percentage = deltaY * (scrollHeight / clientHeight);

    contentRef.current.scrollTop = initialScrollTop + percentage;
  };

  useEffect(() => {
    // Wait for a tick to ensure content is rendered
    setTimeout(() => {
      checkIfScrollable();
      handleThumbPosition();
    }, 0);

    const handleResize = () => {
      checkIfScrollable();
      handleThumbPosition();
    };

    const resizeObserver = new ResizeObserver(() => {
      checkIfScrollable();
      handleThumbPosition();
    });

    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", handleThumbMousemove);
    document.addEventListener("mouseup", handleThumbMouseup);
    document.addEventListener("mouseleave", handleThumbMouseup);

    return () => {
      document.removeEventListener("mousemove", handleThumbMousemove);
      document.removeEventListener("mouseup", handleThumbMouseup);
      document.removeEventListener("mouseleave", handleThumbMouseup);
    };
  }, [isDragging, scrollStartPosition]);

  return (
    <Container>
      <Content ref={contentRef} onScroll={handleThumbPosition}>
        {children}
      </Content>
      {isScrollable && (
        <ScrollTrack onClick={handleTrackClick}>
          <ScrollThumb
            ref={thumbRef}
            style={{ height: `${thumbHeight}px` }}
            onMouseDown={handleThumbMousedown}
          />
        </ScrollTrack>
      )}
    </Container>
  );
};

export default CustomScrollbar;
