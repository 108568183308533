import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { FilterExpandArrow } from "../../svgIcons";
import { isMobile } from "react-device-detect";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { FormControlLabel, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  borderRadius: "12px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  // backgroundColor: "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  paddingLeft: "0px",
  height: isMobile ? "30px" : "40px",
  minHeight: "10px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: "0px",
    "& p": {
      fontSize: isMobile ? "14px" : "16px",
      fontWeight: 600,
      lineHeight: "20px",
      opacity: 0.8,
      color: "#000000",
      marginLeft: "0px",
    },
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: "0px",
  paddingLeft: "0px",
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const StyledLabel = styled(FormControlLabel)(({}) => ({
  fontSize: "0.875rem",
  fontWeight: "500",
  color: "#555", // Elegant color for label
  margin: "0.25rem 0",
  display: "flex",
  justifyContent: "space-between",
  "& span": {
    display: "flex",
    width: "100%",
  },
  "& .MuiCheckbox-root": {
    padding: "4px", // Smaller padding for compact look
    "&.Mui-checked": {
      color: "#2793ff", // Elegant color for checked state
    },
  },
  "& .MuiTypography-root": {
    // fontSize: isMobile ? "12px" : "0.875rem", // now
    // fontWeight: "bold",
    // marginLeft: "10px", // now
  },
  "&:hover": {
    // backgroundColor: "rgba(0, 119, 255, 0.06);",
    borderRadius: "10px",
  },
}));

const StyledPrimaryLabel = styled(Typography)({
  // marginLeft: "40px",
  fontSize: isMobile ? "12px" : "14px",
  display: "flex",
  fontWeight: 350,
  lineHeight: "20px",
  flex: 1,
  color: "#00000099",
});

const StyledSecondaryLabel = styled("h4")({
  // marginLeft: "40px",
  fontSize: isMobile ? "12px" : "14px",
  display: "flex",
  flex: 1.5,
  width: "100%",
  lineHeight: "24px",
  fontWeight: 500,
  color: "#000000C3",
});

const CustomAccordion = ({
  isExpand = false,
  title = "",
  keyValueTypeData,
}) => {
  const [expand, setExpand] = useState(isExpand);
  const { t } = useTranslation();

  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      expanded={expand}
      onChange={(event, newExpend) => {
        setExpand(newExpend);
      }}
    >
      <AccordionSummary
        children={<Typography sx={{ flexGrow: 1 }}>{t(title)}</Typography>}
        expandIcon={<FilterExpandArrow />}
        sx={{ flexDirection: "row" }}
      />
      <AccordionDetails>
        <Box sx={{ display: "flex", gap: "5px", flexDirection: "column" }}>
          {keyValueTypeData.map((item, index) => {
            const [key, value] = Object.entries(item)[0];
            return (
              <StyledLabel
                control={<></>}
                key={key + index}
                label={
                  <>
                    <StyledPrimaryLabel>{t(key)}:</StyledPrimaryLabel>
                    <StyledSecondaryLabel>
                      {typeof value === "boolean"
                        ? value
                          ? t("yes")
                          : t("no")
                        : t(value)}
                    </StyledSecondaryLabel>
                  </>
                }
              />
            );
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
