import Box from "@mui/material/Box";
import { isMobile } from "react-device-detect";
import { cardio } from "ldrs";
import { keyframes, styled } from "@mui/material";

cardio.register();

const animate = (isMobile) =>
  keyframes({
    "0%": {
      transform: "translateY(0px)",
    },
    "20%": {
      transform: isMobile ? "translateY(-10px)" : "translateY(-20px)",
    },
    "50%": {
      transform: "translateY(0px)",
    },
  });

const StyledMobileSpan = styled("span")({
  color: "#000 !important",
});

const StyledStoreSpan = styled("span")({
  color: "#0866FF !important",
});

const MainLoader = ({ isMobileVersion }) => {
  return (
    <Box
      style={{
        width: "100vw",
        height: isMobile ? "100dvh" : "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        zIndex: 9999999,
        background: "azure",
        flexDirection: "column",
        gap: "20px",
        top: 0,
        left: 0,
      }}
    >
      <Box
        id={"class"}
        sx={{
          position: "relative",
          WebkitBoxReflect:
            "below -8px linear-gradient(transparent, rgba(0,0,0,0.2))",
          "& span": {
            fontFamily: isMobileVersion ? "auto" : "cursive",
            display: "inline-block",
            position: "relative",
            color: "blue",
            fontSize: isMobileVersion ? "1.5em" : "2em",
            textTransform: "uppercase",
            animation: `${animate(isMobileVersion)} 2s ease-in-out infinite`,
            animationDelay: `calc(0.1s * var(--i))`,
          },
        }}
      >
        <StyledMobileSpan style={{ "--i": 1 }}>M</StyledMobileSpan>
        <StyledMobileSpan style={{ "--i": 2 }}>O</StyledMobileSpan>
        <StyledMobileSpan style={{ "--i": 3 }}>B</StyledMobileSpan>
        <StyledMobileSpan style={{ "--i": 4 }}>I</StyledMobileSpan>
        <StyledMobileSpan style={{ "--i": 5 }}>L</StyledMobileSpan>
        <StyledMobileSpan style={{ "--i": 6 }}>E</StyledMobileSpan>
        <StyledStoreSpan style={{ "--i": 7 }}>S</StyledStoreSpan>
        <StyledStoreSpan style={{ "--i": 8 }}>T</StyledStoreSpan>
        <StyledStoreSpan style={{ "--i": 9 }}>O</StyledStoreSpan>
        <StyledStoreSpan style={{ "--i": 10 }}>R</StyledStoreSpan>
        <StyledStoreSpan style={{ "--i": 11 }}>E</StyledStoreSpan>
        <StyledStoreSpan style={{ "--i": 12 }}>.</StyledStoreSpan>
        <StyledStoreSpan style={{ "--i": 13 }}>.</StyledStoreSpan>
        <StyledStoreSpan style={{ "--i": 14 }}>.</StyledStoreSpan>
      </Box>
    </Box>
  );
};

export default MainLoader;
