import React, { useEffect, useState } from "react";
import { styled } from "@mui/material";
import PrimaryButton from "./Buttons/CustomButton";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const Root = styled("div")(({ theme, backgroundImagePath }) => ({
  position: "relative",
  height: "280px",
  width: "100%",
  padding: "28px",
  borderRadius: "10px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "10px",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${backgroundImagePath})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    zIndex: -1,
    transition: "transform 1s ease",
  },
  "&:hover::before": {
    transform: "scale(1.2)",
  },
}));

const HeaderText = styled("p")(({ theme, isMobileVersion }) => ({
  fontSize: isMobileVersion ? "20px" : "28px",
  color: "#FFF",
}));

const CountDownContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  gap: "10px",
  // marginTop: "10px",
}));

const CountDownBox = styled("div")(({ theme }) => ({
  width: "65px",
  height: "65px",
  backgroundColor: "#ffffff78",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const CountDownValue = styled("p")(({ theme }) => ({
  color: "black",
  fontWeight: 600,
  textAlign: "center",
  fontSize: "20px",
}));

const CountDownText = styled("p")(({ theme }) => ({
  color: "#000000",
  textAlign: "center",
  fontSize: "12px",
}));

const CountDownItem = React.memo(({ value, label }) => (
  <CountDownBox>
    <CountDownValue>{value.toString().padStart(2, "0")}</CountDownValue>
    <CountDownText>{label}</CountDownText>
  </CountDownBox>
));

const CountDown = ({ loading, leftTime }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const endDate = new Date(leftTime);

    const calculateTimeLeft = () => {
      const difference = endDate - new Date();

      if (difference > 0) {
        return {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    };

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [leftTime, loading]);

  return (
    <CountDownContainer
      style={{
        opacity: loading ? 0 : 1,
      }}
    >
      <CountDownItem value={timeLeft.days} label="Days" />
      <CountDownItem value={timeLeft.hours} label="Hr" />
      <CountDownItem value={timeLeft.minutes} label="Min" />
      <CountDownItem value={timeLeft.seconds} label="Sc" />
    </CountDownContainer>
  );
};

const PromoBanner = ({ data = {}, loading, isMobileVersion }) => {
  const { t } = useTranslation();

  return (
    <Root backgroundImagePath={data.imageSrc}>
      {loading && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
          }}
        >
          <Skeleton variant="rounded" width={"100%"} height="100%" />
        </Box>
      )}
      <HeaderText
        style={{
          opacity: loading ? 0 : 1,
        }}
        isMobileVersion={isMobileVersion}
      >
        {data.title}
      </HeaderText>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <CountDown loading={loading} leftTime={data?.endTime} />
        <PrimaryButton
          buttonStyles={{
            minWidth: "100px",
            borderRadius: "4px",
            width: "fit-content",
            padding: "0px 10px",
            opacity: loading ? 0 : 1,
          }}
          text={t("buyNow")}
          onClick={() => console.log("buy")}
        />
      </div>
    </Root>
  );
};

export default PromoBanner;
