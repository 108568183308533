import { IconButton, Popover, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import CustomButton from "../Buttons/CustomButton";
import SendIcon from "@mui/icons-material/Send";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import TipTapEditor from "../TipTapEditor";
import { CreateUserQuestionMessage } from "../../api/services/chat.api";
import { connect } from "react-redux";
import CustomTextFields from "../Fields/CustomTextFields";
import { enqueueSnackbar } from "notistack";
import { isMobile } from "react-device-detect";
import { useLocation } from "react-router-dom";

const Root = styled("div")(
  ({ theme, isMobileVersion, extraBottomPosition }) => ({
    position: "fixed",
    right: 20,
    bottom: extraBottomPosition ? 120 : isMobileVersion ? 80 : 20,
    borderRadius: "50%",
    width: "50px",
    height: "50px",
    background: "#fff",
    transition: "transform 0.3s ease-in-out",
    cursor: "pointer",
    zIndex: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    WebkitBoxShadow: "-1px 0px 8px 3px rgba(180,208,237,1)",
    MozBoxShadow: "-1px 0px 8px 3px rgba(180,208,237,1)",
    boxShadow: "-1px 0px 8px 3px rgba(180,208,237,1)",
    transform: isMobileVersion ? "scale(0.9)" : "",
    "&:hover": {
      transform: "scale(1.2)",
    },
  }),
);

const PopoverBox = styled("div")(({ theme, open }) => ({
  padding: "6px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const PopoverHeader = styled("div")(({ theme, open }) => ({
  width: "100%",
  // background: "pink",
  display: "flex",
  justifyContent: "space-between",
  "& p": {
    color: "#3281fed9",
    display: "flex",
    alignItems: "center",
  },
}));

const PopoverContent = styled("div")(({ theme, open }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "10px 0px",
  gap: "10px",
  height: "100%",
  // background: "pink",
}));

const PopoverFooter = styled("div")(({}) => ({
  marginTop: "10px",
  width: "100%",
}));

const HeaderBtnsContainer = styled("div")(({}) => ({
  display: "flex",
  gap: "5px",
  " & svg": {
    transition: "transform 1s ease",
  },
  "& :hover": {
    svg: {
      transform: "scale(1.2)",
    },
  },
  "& button": {
    padding: "0px",
    "& svg": {
      // fontSize: "40px",
    },
  },
}));

const Chat = ({ isMobileVersion }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showGIF, setShowGIF] = useState(false);
  const [openFullScreen, setOpenFullScreen] = useState(false);
  const [messageInfo, setMessageInfo] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [popoverKey, setPopoverKey] = useState(Date.now());
  const location = useLocation();
  const [extraBottomPosition, setExtraBottomPosition] = useState(false);

  useEffect(() => {
    if (isMobileVersion && location.pathname.includes("/mobiles/")) {
      setExtraBottomPosition(true);
    } else {
      setExtraBottomPosition(false);
    }
  }, [location, isMobileVersion]);

  useEffect(() => {
    setPopoverKey(Date.now());
  }, [openFullScreen]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleScreenSizeChange = () => {
    setOpenFullScreen(!openFullScreen);
  };

  const handleOnChange = (key, value) => {
    setMessageInfo({
      ...messageInfo,
      [key]: value,
    });
  };

  const isEmailValid = () => {
    const googleGmailRegex = /^[a-z0-9](.?[a-z0-9]){5,}@g(oogle)?mail.com/;
    return googleGmailRegex.test(messageInfo.email);
  };

  const handleSubmitBtn = () => {
    const name = messageInfo.name || "";
    const email = messageInfo.email || "";
    const message = messageInfo.message || "";
    if (!name.trim() || !email.trim() || !message.trim()) {
      enqueueSnackbar(t("pleaseFillAllField"), {
        variant: "warning",
      });
    } else if (!isEmailValid(email)) {
      enqueueSnackbar(t("pleaseEnterCorrectEmail"), {
        variant: "warning",
      });
    } else {
      setLoading(true);
      CreateUserQuestionMessage({
        Name: messageInfo.name,
        Gmail: messageInfo.email,
        Description: messageInfo.message,
      })
        .then((res) => {
          switch (res.data.message) {
            case "InvalidMessage":
            case "InvalidEmail":
            case "InvalidName":
            case "InvalidDescription":
              enqueueSnackbar(t("invalidInformation"), {
                variant: "warning",
              });
              break;
            default:
              setAnchorEl(null);
              setMessageInfo({
                name: "",
                email: "",
                message: "",
              });
              enqueueSnackbar(t("successSendQuestion"), {
                variant: "success",
              });
          }
        })
        .catch(() => {
          enqueueSnackbar(t("errorPleaseContactWebsiteAdministrator"), {
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Root
        onClick={handleClick}
        onMouseEnter={() => setShowGIF(true)}
        onMouseLeave={() => setShowGIF(false)}
        isMobileVersion={isMobileVersion}
        extraBottomPosition={extraBottomPosition}
      >
        <img
          src={showGIF ? "assets/gifs/error.gif" : "assets/gifs/error.png"}
          alt={"notification-icon"}
          width={30}
          height={30}
        />
      </Root>
      <Popover
        key={popoverKey}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          ".MuiPaper-root": {
            borderRadius: "8px",
            WebkitBoxShadow: "0px 0px 8px 3px rgba(180,208,237,1)",
            MozBoxShadow: "0px 0px 8px 3px rgba(180,208,237,1)",
            boxShadow: "0px 0px 8px 3px rgba(180,208,237,1)",
            top: "auto !important",
            left: "auto !important",
            right: "20px !important",
            bottom: isMobile ? "140px !important" : "75px !important",
          },
        }}
      >
        <PopoverBox
          open={open}
          style={{
            height: openFullScreen ? "550px" : "400px",
            width: isMobile ? "300px" : openFullScreen ? "450px" : "300px",
            transition: "width 0.3s ease-in-out, height 0.3s ease-in-out",
          }}
        >
          <PopoverHeader
            style={{
              padding: "8px",
              paddingBottom: "2px",
            }}
          >
            <Typography>
              <span
                style={{
                  fontWeight: 900,
                  color: "#000",
                  fontFamily: "cursive",
                }}
              >
                MOBILE
              </span>
              <span
                style={{
                  fontWeight: 900,
                  fontFamily: "cursive",
                }}
              >
                STORE
              </span>
            </Typography>
            <HeaderBtnsContainer>
              <IconButton onClick={handleScreenSizeChange} color={"primary"}>
                {openFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </IconButton>
              <IconButton onClick={handleClose} color={"primary"}>
                <CloseIcon />
              </IconButton>
            </HeaderBtnsContainer>
          </PopoverHeader>
          <PopoverContent>
            <CustomTextFields
              label={t("userName")}
              defaultValue={messageInfo.name}
              onChange={(value) => handleOnChange("name", value)}
              placeholder={t("userName")}
              size={!openFullScreen ? "small" : "default"}
            />
            <CustomTextFields
              label={t("email")}
              defaultValue={messageInfo.email}
              onChange={(value) => handleOnChange("email", value)}
              placeholder={t("email")}
              size={!openFullScreen ? "small" : "default"}
            />
            <TipTapEditor
              openFullScreen={openFullScreen}
              handleOnChange={handleOnChange}
              error={false}
              defaultValue={messageInfo.message}
            />
          </PopoverContent>
          <PopoverFooter>
            <CustomButton
              text={t("send")}
              onClick={handleSubmitBtn}
              loading={loading}
            >
              <SendIcon
                style={{
                  color: "#FFFFFF",
                  position: "absolute",
                  right: "15px",
                }}
              />
            </CustomButton>
          </PopoverFooter>
        </PopoverBox>
      </Popover>
    </>
  );
};

const mapStateToProps = () => ({});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(Chat);
