import axiosInstance from "../axios";

const PREFIX = "/User";

export const UpdateUserInfo = (body) => {
  return axiosInstance.post(`${PREFIX}/CreateUserFullInfo`, {
    ...body,
  });
};

export const GetCurrentUsersAllPrize = () => {
  return axiosInstance.get(`${PREFIX}/GetCurrentUsersAllPrize`);
};
