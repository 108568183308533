import { Button, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";

const StyledButton = styled(Button)({
  // border: "1px solid #57637530",
  border: "none",
  color: "rgba(0,0,0,0.6)",
  "&:hover": {
    border: "none",
    backgroundColor: "unset",
    // border: "1px solid #57637530",
  },
  maxWidth: "100px",
  width: "100px",
  gap: "16px",
});

const CartRowQuantityCell = ({ quantity }) => {
  const [value, setValue] = useState(quantity);

  const handleIncrease = () => {
    setValue(value + 1);
  };

  const handleDecrease = () => {
    if (value === 0) return;
    setValue(value - 1);
  };

  return (
    <StyledButton
      variant="outlined"
      startIcon={<RemoveIcon onClick={handleDecrease} />}
      endIcon={<AddIcon onClick={handleIncrease} />}
    >
      {value}
    </StyledButton>
  );
};

export default CartRowQuantityCell;
