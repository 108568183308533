import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { XResponse } from "../../api/services/Auth.api";

const XAuthContainer = () => {
  const navigate = useNavigate();
  useEffect(() => {
    XResponse()
      .then(() => {})
      .finally(() => {
        navigate("/");
      });
  }, []);
  return <div></div>;
};

export default XAuthContainer;
