import Lobby from "../../components/Home/Lobby";
import { connect } from "react-redux";

const HomeContainer = ({
  cardContainerRef,
  setMainLoading,
  isMobileVersion,
}) => {
  return (
    <Lobby
      cardContainerRef={cardContainerRef}
      setMainLoading={setMainLoading}
      isMobileVersion={isMobileVersion}
    />
  );
};

const mapStateToProps = () => ({});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(HomeContainer);
