import { useEffect, useRef, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Swiper, SwiperSlide } from "swiper/react";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import BrandItem from "./BrandItem";
import AppleLogoIcon from "../../api/ui/svgIcons/AppleLogoIcon";
import MicrosoftLogoIcon from "../../api/ui/svgIcons/MicrosoftLogoIcon";
import GoogleLogoIcon from "../../api/ui/svgIcons/GoogleLogoIcon";
import PlayStationLogo from "../../api/ui/svgIcons/PlayStationLogo";
import XiaomiLogoIcon from "../../api/ui/svgIcons/XiaomiLogoIcon";
import XBoxLogoIcon from "../../api/ui/svgIcons/XBoxLogoIcon";
import JBLLogoIcon from "../../api/ui/svgIcons/JBLLogoIcon";
import { Autoplay } from "swiper/modules";
import SwiperCore from "swiper";

SwiperCore.use([Autoplay]);

const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
  position: "relative",
  height: "130px",
  minWidth: "0px",
}));

const Header = styled("div")(({ theme }) => ({
  color: "#000",
  opacity: 0.8,
  fontWeight: 700,
  fontSize: "16px",
  marginBottom: "20px",
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  borderRadius: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#fff",
  padding: "6px",
  transition: "background 0.3s ease-in-out, opacity 0.3s ease-in-out",
  cursor: "pointer",
  filter: "drop-shadow(rgba(0, 0, 0, 0.1) 0px 2px 10px)",
  gap: "10px",
  zIndex: 2,
  opacity: 0,
  position: "absolute",
  height: "32px",
  width: "32px",
  top: "75px",
  "&:hover": {
    background: "#f2f2f2",
  },
  "& svg": {
    width: "16px",
    height: "16px",
  },
}));

const BrandSwiper = () => {
  const [data, setData] = useState([
    {
      icon: <AppleLogoIcon />,
    },
    {
      icon: <MicrosoftLogoIcon />,
    },
    {
      icon: <GoogleLogoIcon />,
    },
    {
      icon: <PlayStationLogo />,
    },
    {
      icon: <XiaomiLogoIcon />,
    },
    {
      icon: <XBoxLogoIcon />,
    },
    {
      icon: <JBLLogoIcon />,
    },
  ]);

  const swiperRef = useRef(null);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [partiallyVisibleSlides, setPartiallyVisibleSlides] = useState(
    new Set(),
  );
  const [isAtBeginning, setIsAtBeginning] = useState(false);
  const [visibleSlidesCount, setVisibleSlidesCount] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const updateButtonStates = () => {
      if (swiperRef.current) {
        setIsAtBeginning(swiperRef.current.isBeginning);
        setIsAtEnd(swiperRef.current.isEnd);
      }
    };

    updateButtonStates();

    const swiperInstance = swiperRef.current;
    swiperInstance.on("slideChange", updateButtonStates);

    return () => {
      swiperInstance.off("slideChange", updateButtonStates);
    };
  }, [data]);

  useEffect(() => {
    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      swiperInstance.on("slideChangeTransitionEnd", updateSlideVisibility);
      swiperInstance.on("init", updateSlideVisibility);
    }

    updateSlideVisibility();

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChangeTransitionEnd", updateSlideVisibility);
        swiperInstance.off("init", updateSlideVisibility);
      }
    };
  }, [data]);

  useEffect(() => {
    const updateSlideVisibility = () => {
      const swiper = swiperRef.current;
      if (!swiper || !swiper.slides) return;

      const newPartiallyVisibleSlides = new Set();
      swiper.slides.forEach((slide, index) => {
        const slideRect = slide.getBoundingClientRect();
        const swiperRect = swiper.el.getBoundingClientRect();

        const isPartiallyVisible =
          slideRect.left < swiperRect.left ||
          slideRect.right > swiperRect.right;

        if (isPartiallyVisible) {
          newPartiallyVisibleSlides.add(index);
        }
      });

      setPartiallyVisibleSlides(newPartiallyVisibleSlides);
    };

    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      swiperInstance.on("init", updateSlideVisibility);
      swiperInstance.on("slideChangeTransitionEnd", updateSlideVisibility);

      updateSlideVisibility();
    }

    window.addEventListener("resize", updateSlideVisibility);

    return () => {
      if (swiperInstance) {
        swiperInstance.off("init", updateSlideVisibility);
        swiperInstance.off("slideChangeTransitionEnd", updateSlideVisibility);
        window.removeEventListener("resize", updateSlideVisibility);
      }
    };
  }, [data]);

  useEffect(() => {
    const handleResizeOrInit = () => {
      const swiper = swiperRef.current;
      if (swiper) {
        swiper.update();
        updateVisibleSlidesCount(swiper);
      }
    };

    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      swiperInstance.on("init", handleResizeOrInit);
    }
    window.addEventListener("resize", handleResizeOrInit);

    if (swiperInstance) {
      handleResizeOrInit();
    }

    return () => {
      window.removeEventListener("resize", handleResizeOrInit);
      if (swiperInstance) {
        swiperInstance.off("init", handleResizeOrInit);
      }
    };
  }, [data]);

  const updateVisibleSlidesCount = (swiper) => {
    if (!swiper || !swiper.slides.length || !swiper.slides[0]) return;

    const slideWidth =
      swiper.slides[0].offsetWidth + swiper.params.spaceBetween;
    const count = Math.floor(swiper.width / slideWidth);
    setVisibleSlidesCount(count);
  };

  const updateSlideVisibility = () => {
    const swiper = swiperRef.current;
    if (!swiper) return;

    const newPartiallyVisibleSlides = new Set();
    swiper.slides.forEach((slide, index) => {
      const slideLeftEdge = slide.offsetLeft;
      const slideRightEdge = slideLeftEdge + slide.offsetWidth;
      const swiperVisibleLeftEdge = swiper.translate;
      const swiperVisibleRightEdge = swiperVisibleLeftEdge + swiper.width;

      if (
        slideLeftEdge < swiperVisibleLeftEdge ||
        slideRightEdge > swiperVisibleRightEdge
      ) {
        newPartiallyVisibleSlides.add(index);
      }
    });

    setPartiallyVisibleSlides(newPartiallyVisibleSlides);
  };

  const goLeft = () => {
    if (!swiperRef.current) return;
    const swiper = swiperRef.current;
    const currentIndex = swiper.activeIndex;
    const newIndex = Math.max(currentIndex - 1, 0);
    swiper.slideTo(newIndex);
  };

  const goRight = () => {
    if (!swiperRef.current) return;
    const swiper = swiperRef.current;
    const currentIndex = swiper.activeIndex;
    const slidesCount = swiper.slides.length;
    const newIndex = Math.min(currentIndex + 1, slidesCount - 1);
    swiper.slideTo(newIndex);
  };

  return (
    <Root
      sx={{
        "&:hover": {
          "& #banner_left_arrow": {
            opacity: 1,
          },
          "& #banner_right_arrow": {
            opacity: 1,
          },
        },
      }}
    >
      <Header>{t("brands")}</Header>
      <ButtonContainer
        onClick={() => goLeft()}
        style={{
          left: "10px",
        }}
        id={"banner_left_arrow"}
      >
        <ArrowBackIosNewIcon
          style={{
            color: "#1875CC",
          }}
        />
      </ButtonContainer>

      <ButtonContainer
        onClick={() => goRight()}
        id={"banner_left_arrow"}
        style={{
          right: "10px",
        }}
      >
        <ArrowForwardIosIcon
          style={{
            color: "#1875CC",
          }}
        />
      </ButtonContainer>
      <Swiper
        spaceBetween={35}
        slidesPerView={"auto"}
        onSlideChange={() => {}}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        style={{
          padding: "10px 15px 0px 15px",
          height: "100%",
          cursor: "pointer",
        }}
        speed={1000}
        autoplay={{ delay: 8000 }}
        modules={[Autoplay]}
      >
        {data.map((item, index) => {
          const isPartiallyVisible = partiallyVisibleSlides.has(index);
          return (
            <SwiperSlide
              style={{
                color: "#fff",
                minWidth: "0px",
                transition: "opacity 0.2s ease-in-out",
                opacity: isPartiallyVisible ? 0.1 : 1,
                width: "fit-content",
              }}
              key={index}
            >
              <BrandItem item={item} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Root>
  );
};

export default BrandSwiper;
