import { Box, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const RootButton = styled(Box)(({ type }) => ({
  display: "flex",
  width: "100%",
  height: "40px",
  gap: "24px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "12px",
  borderTop: "1px solid rgba(255, 255, 255, 0.30)",
  transition: "background 0.3s ease-in-out, transform 0.3s ease-in-out",
  boxSizing: "border-box",
  cursor: "pointer",
  background: "#3281FE",
  position: "relative",
  maxWidth: "150px",
  "&:hover": {
    background: "#3C8BFF",
    transform: "scale(1.1)",
  },
}));

const Text = styled("span")(({ theme }) => ({
  textShadow: "0px 2px 0px rgba(0, 0, 0, 0.20)",
  color: "#fff",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 700,
}));

const ShareButton = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <RootButton onClick={() => onClick && onClick()}>
      <Text>{t("share")}</Text>
    </RootButton>
  );
};

export default ShareButton;
