const XiaomiLogoIcon = () => {
  return (
    <svg
      width="64px"
      height="64px"
      viewBox="0 0 800 800"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path fill="#f57921" d="M0 0h800v800H0z"></path>
        <defs>
          <path id="a" d="M0 0h800v800H0z"></path>
        </defs>
        <clipPath id="b">
          <use xlinkHref="#a" overflow="visible"></use>
        </clipPath>
        <path
          d="M541.82 603.47V311.85c0-66.258-53.699-119.979-119.95-119.979H84.68c-2.601 0-4.705 2.094-4.705 4.667v406.93c0 2.573 2.104 4.658 4.705 4.658h90.066a4.693 4.693 0 0 0 4.705-4.687V284.048a4.7 4.7 0 0 1 4.706-4.695h193.68c36.367 0 65.865 29.479 65.865 65.855v258.22a4.69 4.69 0 0 0 4.688 4.688h88.744c2.58 0 4.689-2.091 4.689-4.66"
          clipPath="url(#b)"
          fill="#faf9f5"
        ></path>
        <g>
          <defs>
            <path id="c" d="M0 0h800v800H0z"></path>
          </defs>
          <clipPath id="d">
            <use xlinkHref="#c" overflow="visible"></use>
          </clipPath>
          <path
            d="M359.94 603.44a4.676 4.676 0 0 1-4.688 4.687h-90.046c-2.601 0-4.706-2.095-4.706-4.687V358.69a4.7 4.7 0 0 1 4.706-4.695h90.046a4.684 4.684 0 0 1 4.688 4.695v244.76-.01z"
            clipPath="url(#d)"
            fill="#faf9f5"
          ></path>
        </g>
        <g>
          <defs>
            <path id="e" d="M0 0h800v800H0z"></path>
          </defs>
          <clipPath id="f">
            <use xlinkHref="#e" overflow="visible"></use>
          </clipPath>
          <path
            d="M720.02 603.44c0 2.592-2.104 4.687-4.707 4.687h-90.026c-2.603 0-4.727-2.095-4.727-4.687V196.58c0-2.602 2.124-4.705 4.727-4.705h90.026a4.702 4.702 0 0 1 4.707 4.705v406.86z"
            clipPath="url(#f)"
            fill="#faf9f5"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default XiaomiLogoIcon;
