import { styled } from "@mui/material";
import { isMobile } from "react-device-detect";
import Modal from "@mui/material/Modal";
import { bindActionCreators } from "redux";
import {
  ChangeShareModalInfo,
  openLoginModal,
} from "../../redux/actions/mainAction";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import ShareButton from "../Buttons/ShareButton";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import CopyIcon from "../../api/ui/svgIcons/CopyIcon";

const MainContainerWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: !isMobile ? "500px" : "calc(100% - 60px)",
  minWidth: "340px",
  background: "#111827",
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  outline: "none !important",
  alignItems: "center",
  padding: "20px",
  boxSizing: "border-box",
}));

const HeaderTitle = styled("div")(({ theme }) => ({
  color: "#fff",
  fontWeight: 600,
  fontSize: isMobile ? "12px" : "18px",
  textAlign: "center",
}));

const LinkText = styled("span")(({ theme }) => ({
  cursor: "pointer",
  color: "cadetblue",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: isMobile ? "12px" : "18px",
}));

const LinkTextCopy = styled("span")(({ theme }) => ({
  transition: "transform 0.3s ease-in-out",
  cursor: "pointer",
  color: "cadetblue",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    transform: "scale(1.2)",
  },
}));

const ShareModal = ({
  ChangeShareModalInfo,
  shareModalInfo,
  isUserAuthorized,
  openLoginModal,
  userInfo,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const domain =
    window.location.origin + "/referral-user/" + userInfo.promoCode;

  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        enqueueSnackbar(t("linkCopied"), {
          variant: "success",
        });
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  }

  const onClick = () => {
    if (isUserAuthorized) {
      switch (shareModalInfo) {
        case "facebook":
          window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${domain}`,
            "_blank",
            "noopener,noreferrer",
          );
          break;
        case "whatsapp":
          window.open(
            `https://wa.me/?text=${domain}`,
            "_blank",
            "noopener,noreferrer",
          );
          break;
        case "telegram":
          window.open(
            `https://t.me/share/url?url=${domain}`,
            "_blank",
            "noopener,noreferrer",
          );
          break;
        case "viber":
          window.open(
            `viber://forward?text=${domain}`,
            "_blank",
            "noopener,noreferrer",
          );
          break;
        default:
          copyToClipboard(domain);
          break;
      }
    } else {
      enqueueSnackbar(t("doAuthorizationBeforeShare"), {
        variant: "warning",
      });
      openLoginModal(true);
      ChangeShareModalInfo(null);
    }
  };

  return (
    <Modal
      open={Boolean(shareModalInfo)}
      onClose={() => ChangeShareModalInfo(null)}
      sx={{
        "& .MuiBackdrop-root": {
          border: "none",
        },
        "& .MuiPaper-root": {
          border: "none",
        },
      }}
    >
      <MainContainerWrapper>
        <HeaderTitle>{t("shareToYourFriendAndWinSomeGifts")}</HeaderTitle>
        <div
          style={{
            marginTop: "10px",
            marginBottom: "25px",
            display: "flex",
            gap: "10px",
          }}
        >
          <LinkText>{domain}</LinkText>
          <LinkTextCopy onClick={() => copyToClipboard(domain)}>
            <CopyIcon />
          </LinkTextCopy>
        </div>

        <ShareButton onClick={() => onClick()} />
      </MainContainerWrapper>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  shareModalInfo: state.main.shareModalInfo,
  isUserAuthorized: state.main.isUserAuthorized,
  userInfo: state.main.userInfo,
});

const dispatchToProps = (dispatch) => ({
  ChangeShareModalInfo: bindActionCreators(ChangeShareModalInfo, dispatch),
  openLoginModal: bindActionCreators(openLoginModal, dispatch),
});

export default connect(mapStateToProps, dispatchToProps)(ShareModal);
