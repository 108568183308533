import React from "react";
import { Divider, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import BranchCard from "./Cards/BranchCard";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const HeaderText = styled("p")({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
});

const CustomDivider = styled(Divider)(({ theme }) => ({
  marginTop: "16px",
  borderColor: "#cccccc",
  marginBottom: "60px",
}));

const Content = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const BranchesContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  gap: "15px",
}));

const MapContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "400px",
  marginBottom: "20px",
}));

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const BranchesPage = () => {
  const { t } = useTranslation();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBRHvyhoE3I091c4fZ8R5EW9Pia02Siik8",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <Root>
      <HeaderText>{t("branches")}</HeaderText>
      <CustomDivider />
      <Content>
        <MapContainer>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
              onUnmount={onUnmount}
              onLoad={onLoad}
            >
              {/* Child components, such as markers, info windows, etc. */}
              <></>
            </GoogleMap>
          ) : (
            <></>
          )}
        </MapContainer>
        <BranchesContainer>
          <BranchCard />
        </BranchesContainer>
      </Content>
    </Root>
  );
};

export default BranchesPage;
