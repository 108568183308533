import { useEffect } from "react";
import { FacebookResponse } from "../../api/services/Auth.api";
import { useNavigate } from "react-router-dom";

const FacebookAuthContainer = () => {
  const navigate = useNavigate();
  useEffect(() => {
    FacebookResponse()
      .then(() => {})
      .finally(() => {
        navigate("/");
      });
  }, []);
  return <div></div>;
};

export default FacebookAuthContainer;
