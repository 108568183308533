import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  debounce,
  Fade,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { keyframes, styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  ClearIcon,
  DeleteIcon,
  HistoryIcon,
  LeftArrowIcon,
  SearchIcon,
} from "../svgIcons";
import CloseIcon from "@mui/icons-material/Close";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { GetAllProducts } from "../api/services/products.api";
import { useSnackbar } from "notistack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  getArrayFromLocalStorage,
  PopularSearches,
  saveArrayToLocalStorage,
} from "../util/appUtil";
import SeeMoreButton from "./Buttons/SeeMoreButton";
import { isMobile } from "react-device-detect";
import CategoryDropdown from "./Common/CategoryDropdown";

const SEARCHED_PRODUCTS_KEY = "mobileStore-searchedProducts";
const MAX_SEARCHED_PRODUCTS = 5;

const slideInAnimation = keyframes`
    0% {
        transform: translateY(100vh);
        opacity: 0;
    }
    100% {
        transform: translateY(80px);
        opacity: 1;
    }
`;

const Root = styled("div")(({ theme }) => ({
  padding: isMobile ? "0px" : "0px 15px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledRecentContainer = styled(Box)(() => ({}));

const StyledFavoriteContainer = styled(Box)(() => ({}));

const StyledRecentContent = styled(Box)(() => ({
  marginTop: "10px",
  display: "flex",
  flexDirection: "column",
  gap: "6px",
}));

const StyledFavoriteContent = styled(StyledRecentContent)(() => ({}));

const StyledProductCard = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "35px",
  borderRadius: "10px",
  width: "100%",
  backgroundColor: "rgba(246, 247, 248, 0.4);",
  border: "1px solid rgba(232, 234, 238, 0.5);",
  cursor: "pointer",
  padding: "0px 5px",
  "&:hover": {
    backgroundColor: "rgb(235, 245, 255);",
    borderColor: "rgb(102, 179, 255);",
  },
  "& svg": {
    color: "#006BD6",
    fontSize: "large",
  },
  "& button": {
    minHeight: "26px !important",
    minWidth: "26px !important",
    width: "26px !important",
    height: "26px !important",
    borderRadius: "100%",
    transition: "all 0.1s !important",
    "&:hover": {
      backgroundColor: "rgb(204, 230, 255)",
      border: "1px solid rgb(102, 179, 255)",
    },
  },

  "&.removing": {
    opacity: 0,
    maxHeight: 0,
    padding: 0,
    margin: 0,
  },
}));

const StyledFakeSearchField = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  // paddingLeft: "15px",
  justifyContent: "space-between",
  color: "#576375",
  cursor: "pointer",
  width: "380px",
  height: "42px",
  padding: "0px 5px",
  backgroundColor: "rgba(246, 247, 248, 0.6)",
  borderRadius: "22px",
  border: "1px solid rgb(223, 226, 231);",
  boxShadow:
    "rgb(255, 255, 255) 0px 1px 0px inset, rgba(232, 234, 238, 0.4) 0px -1px 0px inset, rgba(223, 226, 231, 0.5) 0px 1px 2px 0px;",
  "&:hover": {
    backgroundColor: "rgba(232, 234, 238, 0.5);",
    borderColor: "rgba(50,129,254,0.3)",
    // border: "2px solid #a2d1ff6e",
  },
  "& span": {
    marginLeft: "10px",
  },
}));

const StyledModalRoot = styled(Box)(({ theme, mobileMode }) => ({
  outline: "none",
  display: "flex",
  flexDirection: "column",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  width: "100%",
  margin: "0 auto",
  backgroundColor: "white",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  overflow: "auto",
  borderRadius: mobileMode ? "" : "10px",
  ...(mobileMode
    ? {
        height: "100%",
      }
    : {
        maxWidth: "600px",
        animation: `${slideInAnimation} 0.3s ease-out forwards`,
      }),
}));

const StyledModal = styled(Modal)(() => ({
  outline: "none",
  top: 1,
  "& .MuiBackdrop-root": {
    backgroundColor: "#57637580",
    backdropFilter: "blur(2px)",
    top: 1,
  },
}));

const StyledModalHeader = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  gap: "10px",
  padding: "0px 10px",
  borderBottom: "1px solid rgb(223, 226, 231);",
}));

const StyledMobileModeModalHeader = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  padding: "10px 10px 10px 5px",
  borderBottom: "1px solid rgb(223, 226, 231);",
}));

const StyledModalContent = styled(Box)(() => ({
  padding: "15px 15px",
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  /* For Firefox */
  height: "100%",
  overflowY: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "hsl(215,15%,50%,1) #fff",
  position: "relative",
}));

const StyledModalTextField = styled(TextField)(() => ({
  width: "100%",
  "& fieldset": {
    border: "unset",
  },
}));

const StyledMobileModeModalTextField = styled(TextField)(() => ({
  width: "100%",
  "& fieldset": {
    border: "unset",
  },
  "& .MuiInputBase-root": {
    height: "34px",
    backgroundColor: "#f5f5f5",
    borderRadius: "20px",
    paddingRight: "5px",
  },
}));

const StyledDefaultProductsContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

const StyledRecentHeaderTypography = styled(Typography)(() => ({
  color: "rgba(0,0,0,.8)",
  fontWeight: 700,
  fontSize: "14px",
  paddingLeft: "0px",
}));

const LoaderContainer = styled("div")(({}) => ({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  left: 0,
  top: 0,
  zIndex: 999,
}));

const FilteredProductsContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

const FilteredProductHeader = styled(Typography)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

const FilterBySecondHand = styled("div")(({ theme }) => ({
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "17px",
  cursor: "pointer",
  color: "rgba(0,0,0,.8)",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  "& .MuiButtonBase-root": {
    padding: "0px",
  },
}));

const SeeAllProductText = styled("p")(({ theme }) => ({
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "17px",
  cursor: "pointer",
  color: "#1875CC",
  display: "flex",
  alignItems: "center",
}));

const NoProductFindText = styled("p")(({ mobileMode }) => ({
  fontWeight: 500,
  fontSize: mobileMode ? "16px" : "18px",
  lineHeight: "25px",
  alignItems: "center",
  marginTop: "15px",
  display: "flex",
  justifyContent: "center",
  color: "#5F6379",
}));

const NoProductFindContainer = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const PopularSearchedItem = styled("div")(({ theme }) => ({
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "14px",
  color: "rgba(0,0,0,.8)",
  padding: " 8px 15px",
  background: "#f2f2f2",
  borderRadius: "12px",
  transition: "background 0.3s ease-in-out",
  maxWidth: "fit-content",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#f7f7f7",
  },
}));

const StyledFilteredProductBox = styled(StyledRecentHeaderTypography)(() => ({
  width: "100%",
  height: "50px",
  padding: "6px",
  background: "rgb(242, 242, 242)",
  border: "1px solid rgb(242, 242, 242)",
  borderRadius: "8px",
  cursor: "pointer",
  display: "flex",
  gap: "8px",
  "&:hover": {
    backgroundColor: "rgb(235, 245, 255);",
    borderColor: "rgb(102, 179, 255);",
  },
}));

const StyledIconBtn = styled(IconButton)(() => ({}));

// Recent ( searched ) Card
const ProductCard = ({
  item,
  handleRemoveSearchedProd,
  setSearchValue,
  GetSearchedProducts,
}) => {
  const [show, setShow] = useState(true);

  const handleRemove = (e) => {
    e.stopPropagation();
    setShow(false);

    setTimeout(() => {
      handleRemoveSearchedProd(item);
    }, 300);
  };

  return (
    <Fade in={show} timeout={300} unmountOnExit>
      <StyledProductCard
        onClick={() => {
          setSearchValue(item);
          GetSearchedProducts(item);
        }}
      >
        <Box sx={{ width: "50px", display: "flex", justifyContent: "center" }}>
          <HistoryIcon style={{ stroke: "#49494a" }} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ fontSize: "14px", padding: "0px" }}>
            {item}
          </Typography>
        </Box>
        <Box sx={{ marginLeft: "auto" }}>
          <Button onClick={handleRemove}>
            <CloseRoundedIcon />
          </Button>
        </Box>
      </StyledProductCard>
    </Fade>
  );
};

const FilteredProductCard = ({ item }) => {
  return (
    <StyledFilteredProductBox>
      <img
        src={"assets/no-product-find.jpg"}
        alt={"product image"}
        style={{
          maxWidth: "38px",
          maxHeight: "38px",
          borderRadius: "4px",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <span
            style={{
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "14px",
              color: "rgba(0,0,0,.8)",
              opacity: "0.8",
            }}
          >
            {item.Name}
          </span>
          <span
            style={{
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "14px",
              color: "#000",
              opacity: "0.7",
            }}
          >
            {item.Price} ₾
          </span>
        </div>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
          sx={{
            "& .MuiSvgIcon-root": {
              color: "rgba(0,0,0,.8)",
              opacity: "0.8",
            },
          }}
        >
          <NavigateNextIcon />
        </Box>
      </div>
    </StyledFilteredProductBox>
  );
};

const MainSearchField = ({ mobileMode = false }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isSecondHand, setIsSecondHand] = useState(false);
  const [total, setTotal] = useState(0);
  const [products, setProducts] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [recentProducts, setRecentProducts] = useState([]);

  const modalRef = useRef(null); // Ref to track the Modal content

  useEffect(() => {
    setRecentProducts(getArrayFromLocalStorage(SEARCHED_PRODUCTS_KEY));
  }, []);

  useEffect(() => {
    if (products !== null && !!searchValue) {
      GetSearchedProducts(searchValue);
    }
  }, [isSecondHand]);

  useEffect(() => {
    if (page !== 1 && !!searchValue) {
      setLoading(true);
      GetAllProducts({
        name: searchValue,
        pageNumber: page,
        limit: limit,
        isSecondHand: isSecondHand,
      })
        .then((resp) => {
          const data = JSON.parse(resp.data.data);
          setTotal(data.Total);
          let result = [...products];
          data.Products.forEach((item) => {
            result.push(item);
          });
          setProducts(result);
        })
        .catch(() => {
          enqueueSnackbar(t("errorPleaseContactWebsiteAdministrator"), {
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [page]);

  useEffect(() => {
    if (!isOpenModal) {
      setProducts(null);
      setSearchValue("");
      setIsSecondHand(false);
    }
  }, [isOpenModal]);

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        value = value.trim();
        if (value.length > 2) {
          GetSearchedProducts(value);
        }

        if (value.length === 0) {
          setProducts(null);
        }
      }, 500),
    [],
  );

  const GetSearchedProducts = (value) => {
    setLoading(true);
    GetAllProducts({
      name: value,
      pageNumber: page,
      limit: limit,
      isSecondHand: isSecondHand,
    })
      .then((resp) => {
        setPage(1);
        const data = JSON.parse(resp.data.data);
        setTotal(data.Total);
        let result = [];
        data.Products.forEach((item) => {
          result.push(item);
        });
        setProducts(result);
        saveSearchedProductsLocalStorage(value);
      })
      .catch(() => {
        enqueueSnackbar(t("errorPleaseContactWebsiteAdministrator"), {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveSearchedProductsLocalStorage = (value) => {
    let searchedProds = getArrayFromLocalStorage(SEARCHED_PRODUCTS_KEY);
    if (searchedProds.includes(value)) {
      searchedProds = searchedProds.filter((item) => item !== value);
    }
    if (searchedProds.length === MAX_SEARCHED_PRODUCTS) {
      searchedProds.pop();
    }
    searchedProds.unshift(value);
    saveArrayToLocalStorage(SEARCHED_PRODUCTS_KEY, searchedProds);
    setRecentProducts(searchedProds);
  };

  const handleClearRecentSearches = () => {
    saveArrayToLocalStorage(SEARCHED_PRODUCTS_KEY, []);
    setRecentProducts([]);
  };

  const handleRemoveSearchedProd = (item) => {
    let searchedProds = getArrayFromLocalStorage(SEARCHED_PRODUCTS_KEY);
    let modifiedSearchedProds = searchedProds.filter((prod) => prod !== item);
    saveArrayToLocalStorage(SEARCHED_PRODUCTS_KEY, modifiedSearchedProds);
    setRecentProducts(modifiedSearchedProds);
  };

  return (
    <Root mobileMode={mobileMode}>
      {mobileMode ? (
        <IconButton onClick={() => setIsOpenModal(true)}>
          <SearchIcon style={{ stroke: "#313131" }} />
        </IconButton>
      ) : (
        <StyledFakeSearchField onClick={() => setIsOpenModal(true)}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              sx={{
                background: "#3281FE",
                padding: "6px",
                "&:hover": { background: "#3281FE" },
              }}
            >
              <SearchIcon style={{ stroke: "#FFF", width: "18", height: 18 }} />
            </IconButton>
            <span style={{ fontWeight: 300, fontSize: "14px" }}>
              {t("whatAreYouLookingFor")}
            </span>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              height: "100%",
            }}
          >
            <CategoryDropdown />
          </Box>
        </StyledFakeSearchField>
      )}
      <StyledModal
        open={isOpenModal}
        onClose={(event, reason) => {
          // if (reason === "backdropClick") {
          //   return;
          // }
          setIsOpenModal(false);
        }}
        style={{
          maxHeight: `100%`,
        }}
      >
        <StyledModalRoot ref={modalRef} mobileMode={mobileMode}>
          {mobileMode ? (
            <StyledMobileModeModalHeader>
              <StyledIconBtn
                onClick={() => {
                  setIsOpenModal(false);
                }}
              >
                <LeftArrowIcon />
              </StyledIconBtn>
              <StyledMobileModeModalTextField
                placeholder={t("whatAreYouLookingFor")}
                onChange={(event) => {
                  setSearchValue(event.target.value);
                  debouncedSearch(event.target.value);
                }}
                value={searchValue}
                autoFocus
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ padding: "5px" }}
                      onClick={() => {
                        setSearchValue("");
                        setProducts(null);
                      }}
                    >
                      {searchValue && <ClearIcon />}
                    </IconButton>
                  ),
                }}
              />
            </StyledMobileModeModalHeader>
          ) : (
            <StyledModalHeader>
              <SearchIcon style={{ stroke: "#3281FE" }} />
              <StyledModalTextField
                placeholder={t("whatAreYouLookingFor")}
                onChange={(event) => {
                  setSearchValue(event.target.value);
                  debouncedSearch(event.target.value);
                }}
                value={searchValue}
                autoFocus
              />
              <StyledIconBtn
                onClick={() => {
                  setIsOpenModal(false);
                }}
              >
                <CloseIcon />
              </StyledIconBtn>
            </StyledModalHeader>
          )}

          <StyledModalContent
            style={{
              opacity: loading ? 0.5 : 1,
              overflow: loading ? "hidden" : "auto",
            }}
          >
            {loading && (
              <LoaderContainer>
                <CircularProgress size={50} />
              </LoaderContainer>
            )}
            {products ? (
              <FilteredProductsContainer>
                <FilteredProductHeader>
                  <FilterBySecondHand>
                    {t("filterWithSecondHand")}
                    <Checkbox
                      size={"small"}
                      checked={isSecondHand}
                      onClick={() => setIsSecondHand(!isSecondHand)}
                    />
                  </FilterBySecondHand>
                  <SeeAllProductText>{t("seeAll")}</SeeAllProductText>
                </FilteredProductHeader>

                {products.length === 0 ? (
                  <>
                    <NoProductFindContainer>
                      <img
                        src={"assets/no-product-find.jpg"}
                        alt={"product can not find"}
                        style={{
                          maxWidth: mobileMode ? "330px" : "430px",
                        }}
                      />
                      <NoProductFindText mobileMode={mobileMode}>
                        {t("productCanNotFind")}...
                      </NoProductFindText>
                    </NoProductFindContainer>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      marginTop: "30px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        maxHeight: "380px",
                        overflow: "auto",
                      }}
                    >
                      {products.map((item, index) => {
                        return <FilteredProductCard key={index} item={item} />;
                      })}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <SeeMoreButton
                        loading={loading}
                        totalAmount={total}
                        currentAmount={products.length}
                        onClick={() => setPage(page + 1)}
                      />
                    </div>
                  </div>
                )}
              </FilteredProductsContainer>
            ) : (
              <>
                {recentProducts.length !== 0 && (
                  <StyledRecentContainer>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <StyledRecentHeaderTypography>
                        {t("lastSearched")}
                      </StyledRecentHeaderTypography>
                      <Box
                        sx={{
                          display: "flex",
                          cursor: "pointer",
                          alignItems: "center",
                        }}
                        onClick={handleClearRecentSearches}
                      >
                        <DeleteIcon />
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "12px",
                            lineHeight: "14px",
                          }}
                        >
                          {t("clear")}
                        </Typography>
                      </Box>
                    </Box>
                    <StyledRecentContent>
                      {recentProducts.map((item, index) => (
                        <ProductCard
                          key={`${item}-id-${index}`}
                          item={item}
                          handleRemoveSearchedProd={handleRemoveSearchedProd}
                          setSearchValue={setSearchValue}
                          GetSearchedProducts={GetSearchedProducts}
                        />
                      ))}
                    </StyledRecentContent>
                  </StyledRecentContainer>
                )}

                <StyledDefaultProductsContainer
                  style={{
                    marginTop: "15px",
                  }}
                >
                  <StyledRecentHeaderTypography>
                    {t("popularSearch")}
                  </StyledRecentHeaderTypography>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    {PopularSearches.map((item, index) => {
                      return (
                        <PopularSearchedItem
                          key={index}
                          onClick={() => {
                            setSearchValue(item);
                            GetSearchedProducts(item);
                          }}
                        >
                          {item}
                        </PopularSearchedItem>
                      );
                    })}
                  </div>
                </StyledDefaultProductsContainer>
              </>
            )}
          </StyledModalContent>
        </StyledModalRoot>
      </StyledModal>
    </Root>
  );
};

export default MainSearchField;
