import { styled } from "@mui/material";
import { EmptyBasketIcon } from "../svgIcons";
import { useTranslation } from "react-i18next";
import CustomButton from "./Buttons/CustomButton";
import { useNavigate } from "react-router-dom";
import CartItem from "./Cart/cartItem";
import CustomScrollbar from "./Common/CustomScrollbar";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "350px",
  // padding: "8px 8px 14px 8px",
}));

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "15px",
  padding: "12px 18px",
}));

const Content = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  minHeight: "200px",
  alignItems: "center",
  justifyContent: "center",
}));

const BasketText = styled("p")(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 600,
}));

const ProductText = styled("p")(({ theme }) => ({
  fontSize: "12px",
}));

const SvgContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
}));

const ProductsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  maxHeight: "200px",
  flexDirection: "column",
  gap: "5px",
}));

const FooterContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "0px 18px 12px 18px",
}));

const TotalPriceContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  marginBottom: "20px",
  paddingTop: "15px",
}));

const TotalPriceText = styled("p")(({ theme }) => ({
  fontSize: "14px",
  paddingRight: "10px",
}));

const TotalPriceNumber = styled("p")(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 600,
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  padding: "0px 35px",
}));

const BasketPopover = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const productAmount = 10;

  const handleCartOpenButtonClick = () => {
    navigate("/cart");
  };

  return (
    <Root>
      <Header>
        <BasketText>{t("basket")}</BasketText>
        <ProductText>{productAmount + " " + t("product")}</ProductText>
      </Header>
      <Content>
        {productAmount === 1 ? (
          <SvgContainer>
            <EmptyBasketIcon />
          </SvgContainer>
        ) : (
          <CustomScrollbar>
            <ProductsContainer>
              {Array(10)
                .fill(0)
                .map((_, i) => (
                  <CartItem
                    imgSrc={`./assets/testing_imgs/${i === 1 ? "prod1.png" : i === 2 ? "prod3.png" : i === 3 ? "prod8.png" : "prod2.png"}`}
                    title={"Samsung Galaxy S21 Ultra WGR PGR"}
                    price={34}
                    key={i}
                  />
                ))}
            </ProductsContainer>
          </CustomScrollbar>
        )}
      </Content>
      <FooterContainer>
        <TotalPriceContainer>
          <TotalPriceText>{t("totalPrice")}:</TotalPriceText>
          <TotalPriceNumber>245₾</TotalPriceNumber>
        </TotalPriceContainer>
        <ButtonContainer>
          <CustomButton
            onClick={handleCartOpenButtonClick}
            textStyles={{ fontSize: "12px" }}
            text={t("openCart")}
          />
        </ButtonContainer>
      </FooterContainer>
    </Root>
  );
};

export default BasketPopover;
