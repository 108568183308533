import { Popover, styled } from "@mui/material";
import { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useTranslation } from "react-i18next";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ChangeLanguageContainer from "../Language/ChangeLanguageContainer";
import { connect, useDispatch } from "react-redux";
import { LogOutDispatch, openLoginModal } from "../../redux/actions/mainAction";
import { LogOut } from "../../api/services/Auth.api";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import BasketPopover from "../BasketPopover";
import AuthorizedUserMenu from "./AuthorizedUserMenu";
import MainSearchField from "../MainSearchField";

const Root = styled("div")(({ theme, isSticky }) => ({
  height: "70px",
  minHeight: "70px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  background: "rgba(237, 235, 235, 0.74)",
  backdropFilter: "blur(6px)",
  position: isSticky ? "fixed" : "static",
  top: isSticky ? "0" : "-40px",
  zIndex: 1000,
  transition: "top 0.3s ease-in-out",
  justifyContent: "center",
  padding: "0px 15px",
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  padding: "10px",
  background: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "background 0.3s ease-in-out",
  cursor: "pointer",
  filter: "drop-shadow(rgba(0, 0, 0, 0.1) 0px 2px 10px)",
  width: "130px",
  gap: "10px",
  borderRadius: "12px",
  userSelect: "none",
  "&:hover": {
    background: "#f2f2f2",
  },
}));

const ButtonText = styled("span")(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "24px",
}));

const MainHeader = ({ cardContainerRef, isUserAuthorized }) => {
  const { t } = useTranslation();
  const [isSticky, setIsSticky] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isBasketPopoverOpen, setIsBasketPopoverOpen] = useState(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsBasketPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setIsBasketPopoverOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setIsSticky(currentScrollPos >= 40);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const logOutSystem = () => {
    setLoading(true);
    LogOut()
      .then(() => {
        dispatch(LogOutDispatch());
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Root isSticky={isSticky}>
      <div
        style={{
          maxWidth: "1200px",
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <Box
          style={{
            fontSize: "30px",
            display: "flex",
            alignItems: "center",
            userSelect: "none",
            cursor: "pointer",
          }}
          sx={{
            transition: "transform 0.3s ease-in-out",
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
          onClick={() => navigate("/")}
        >
          <span
            style={{
              fontWeight: 900,
              color: "#000",
              fontFamily: "cursive",
            }}
          >
            MOBILE
          </span>
          <span
            style={{
              fontWeight: 900,
              color: "#3281fed9",
              fontFamily: "cursive",
            }}
          >
            STORE
          </span>
        </Box>

        <MainSearchField />

        <div
          style={{
            display: "flex",
            gap: "20px",
          }}
        >
          <ButtonContainer ref={cardContainerRef} onClick={handlePopoverOpen}>
            <ShoppingCartIcon
              style={{
                color: "#125799",
              }}
            />
            <ButtonText>{t("basket")}</ButtonText>
          </ButtonContainer>
          {!isUserAuthorized && (
            <ButtonContainer onClick={() => dispatch(openLoginModal(true))}>
              <AccountCircleIcon
                style={{
                  color: "#125799",
                }}
              />
              <ButtonText>{t("logIn")}</ButtonText>
            </ButtonContainer>
          )}
          {isUserAuthorized && <AuthorizedUserMenu />}
          <ChangeLanguageContainer />
        </div>
      </div>
      <Popover
        anchorEl={anchorEl}
        open={isBasketPopoverOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disableRestoreFocus
        onClose={() => handlePopoverClose()}
        sx={{
          ".MuiPaper-root": {
            marginTop: "8px",
            borderRadius: "16px",
          },
        }}
      >
        <BasketPopover />
      </Popover>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  isUserAuthorized: state.main.isUserAuthorized,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(MainHeader);
