import { Navigate, Route, Routes } from "react-router-dom";
import HomeContainer from "./Home/HomeContainer";
import FacebookAuthContainer from "./Auth/FacebookAuthContainer";
import GoogleAuthContainer from "./Auth/GoogleAuthContainer";
import LinkedInAuthContainer from "./Auth/LinkedInAuthContainer";
import XAuthContainer from "./Auth/XAuthContainer";
import TermsAndConditionsContainer from "./TermsAndConditions/TermsAndConditionsContainer";
import CartContainer from "./Cart/CartContainer";
import PromotionContainer from "./Promotion/PromotionContainer";
import TradePolicyContainer from "./TradePolicy/TradePolicyContainer";
import BranchesContainer from "./Branches/BranchesContainer";
import CareerContainer from "./Career/CareerContainer";
import TradeInContainer from "./TradeIn/TradeInContainer";
import UserInfoContainer from "./UserInfo/UserInfoContainer";
import PromoCodeContainer from "./PromoCode/PromoCodeContainer";
import ReferralUser from "../components/ReferralUser/ReferralUser";
import MobilesPage from "../components/Mobiles/MobilesPage";
import MobileItemPage from "../components/Mobiles/MobileItemPage";
import MobileProfileMenu from "../components/Profile/MobileProfileMenu";
import LaptopPage from "../components/Laptop/LaptopPage";
import SmartGadgetPage from "../components/SmartGadget/SmartGadgetPage";
import AudioPage from "../components/Audio/AudioPage";
import GamePage from "../components/Game/GamePage";
import MonitorPage from "../components/Monitor/MonitorPage";
import PhotoVideoPage from "../components/PhotoVideo/PhotoVideoPage";
import CategoryMobilePage from "../components/CategoryMobile/CategoryMobilePage";

const AppRoutes = ({
  cardContainerRef,
  isUserAuthorized,
  setMainLoading,
  isMobileVersion,
}) => {
  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <HomeContainer
            cardContainerRef={cardContainerRef}
            setMainLoading={setMainLoading}
            isMobileVersion={isMobileVersion}
          />
        }
      />
      <Route
        path={"/mobiles"}
        element={<MobilesPage isMobileVersion={isMobileVersion} />}
      />
      <Route
        path={"/mobiles/:productId"}
        element={<MobileItemPage isMobileVersion={isMobileVersion} />}
      />
      <Route
        path={"/laptops"}
        element={<LaptopPage isMobileVersion={isMobileVersion} />}
      />
      <Route
        path={"/smart-gadgets"}
        element={<SmartGadgetPage isMobileVersion={isMobileVersion} />}
      />
      <Route
        path={"/audio"}
        element={<AudioPage isMobileVersion={isMobileVersion} />}
      />
      <Route
        path={"/gaming"}
        element={<GamePage isMobileVersion={isMobileVersion} />}
      />
      <Route
        path={"/tv"}
        element={<MonitorPage isMobileVersion={isMobileVersion} />}
      />
      <Route
        path={"/photo-video"}
        element={<PhotoVideoPage isMobileVersion={isMobileVersion} />}
      />
      {isMobileVersion && (
        <Route path={"/profile"} element={<MobileProfileMenu />} />
      )}
      {isMobileVersion && (
        <Route path={"/category"} element={<CategoryMobilePage />} />
      )}
      <Route path={"/auth/facebook"} element={<FacebookAuthContainer />} />
      <Route path={"/auth/google"} element={<GoogleAuthContainer />} />
      <Route path={"/auth/x"} element={<XAuthContainer />} />
      <Route path={"/auth/linkedin"} element={<LinkedInAuthContainer />} />
      <Route
        path={"/terms-and-conditions"}
        element={<TermsAndConditionsContainer />}
      />
      <Route
        path={"/cart"}
        element={<CartContainer isMobileVersion={isMobileVersion} />}
      />
      <Route
        path={"/promotion"}
        element={<PromotionContainer cardContainerRef={cardContainerRef} />}
      />
      <Route path={"/tradePolicy"} element={<TradePolicyContainer />} />
      <Route path={"/branches"} element={<BranchesContainer />} />
      <Route path={"/career"} element={<CareerContainer />} />
      <Route path={"/tradeIn"} element={<TradeInContainer />} />
      <Route
        path={"/user-info"}
        element={<UserInfoContainer isMobileVersion={isMobileVersion} />}
      />
      <Route
        path={"/promo-code"}
        element={<PromoCodeContainer isMobileVersion={isMobileVersion} />}
      />
      <Route path={"/referral-user/:referralUser"} element={<ReferralUser />} />
      <Route path="*" element={<Navigate to={"/"} />} />
    </Routes>
  );
};

export default AppRoutes;
