import { styled, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SidebarOpenIcon from "../../api/ui/svgIcons/SidebarOpenIcon";
import MenuMobileIcon from "../../api/ui/svgIcons/MenuMobileIcon";
import MenuLaptopIcon from "../../api/ui/svgIcons/MenuLaptopIcon";
import MenuSmartGadgetIcon from "../../api/ui/svgIcons/MenuSmartGadgetIcon";
import MenuAudioIcon from "../../api/ui/svgIcons/MenuAudioIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  minWidth: "60px",
  position: "fixed",
  height: "100vh",
  background: "#ededed",
  width: "60px",
  left: 0,
  top: 0,
  zIndex: 1000,
  transition: "width 0.3s ease-in-out, min-width 0.3s ease-in-out",
  flexDirection: "column",
  padding: "0px 11px",
  webkitBoxShadow: "7px 0px 5px -3px rgb(215 219 226)",
  mozBoxShadow: "7px 0px 5px -3px rgb(215 219 226)",
  boxShadow: "7px 0px 5px -3px rgb(215 219 226)",
  userSelect: "none",
}));

const MenuOpen = styled("div")(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  height: "70px",
  minHeight: "70px",
  minWidth: "0px",
  marginTop: "40px",
  marginLeft: "5px",

  "& span": {
    marginLeft: "8px",
  },

  "& svg": {
    minWidth: "24px",
    transition: "transform 0.3s ease-in-out",
    transform: "scaleX(1)",
    "& path": {
      fill: "#1875CC",
    },
  },
}));

const MenuContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  overflow: "auto",
  flexDirection: "column",
  gap: "4px",
  paddingTop: "10px",
}));

const MenuContainerItem = styled("div")(({ isSelected }) => ({
  display: "flex",
  alignItems: "center",
  height: "32px",
  width: "100%",
  background: isSelected ? "#3e6ac8" : "transparent",
  borderRadius: "4px",
  minWidth: "0px",
  cursor: "pointer",
  paddingLeft: "10px",
  transition: "background 0.3s ease-in-out",
  "&:hover": {
    background: "#3e6ac8",
    "& span": {
      color: "#fff",
    },
    "& svg": {
      fill: "#fff",
    },
  },

  "& span": {
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "24px",
    marginLeft: "8px",
    lineBreak: "anywhere",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "auto",
    transition: "color 0.3s ease-in-out",
    color: isSelected ? "#fff" : "#003e9b",
  },
  "& svg": {
    width: "16px",
    height: "16px",
    transition: "fill 0.3s ease-in-out",
    fill: isSelected ? "#fff" : "#003e9b",
    minWidth: "16px",
  },
}));

export const MenuItemsInfo = [
  {
    title: t("mobiles"),
    icon: <MenuMobileIcon />,
    path: "/mobiles",
  },
  {
    title: t("laptops"),
    icon: <MenuLaptopIcon />,
    path: "/laptops",
  },
  {
    title: t("smartGadget"),
    icon: <MenuSmartGadgetIcon />,
    path: "/smart-gadgets",
  },
  {
    title: t("audio"),
    icon: <MenuAudioIcon />,
    path: "/audio",
  },
  {
    title: t("gaming"),
    icon: <MenuMobileIcon />,
    path: "/gaming",
  },
  {
    title: t("monitors"),
    icon: <MenuLaptopIcon />,
    path: "tv",
  },
  {
    title: t("photo-video"),
    icon: <MenuSmartGadgetIcon />,
    path: "photo-video",
  },
];

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos <= 40 && menuRef && menuRef.current) {
        menuRef.current.style.marginTop = `${40 - currentScrollPos}px`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Root
      style={{
        minWidth: open ? "250px" : "60px",
        width: open ? "250px" : "60px",
      }}
    >
      <MenuOpen ref={menuRef}>
        <SidebarOpenIcon
          onClick={() => setOpen(!open)}
          style={{
            transform: open ? "scaleX(1)" : "scaleX(-1)",
          }}
        />
        {open && (
          <span
            style={{
              lineBreak: "anywhere",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "auto",
              fontWeight: 600,
              paddingBottom: "4px",
              color: "#1875CC",
            }}
          >
            {t("category")}
          </span>
        )}
      </MenuOpen>
      <MenuContainer>
        {MenuItemsInfo.map((item, index) => {
          return (
            <Tooltip
              key={index}
              title={open ? "" : item.title}
              placement="right"
            >
              <MenuContainerItem
                isSelected={location.pathname === item.path}
                key={index}
                onClick={() => navigate(item.path)}
              >
                {item.icon} {open && <span>{item.title}</span>}
              </MenuContainerItem>
            </Tooltip>
          );
        })}
      </MenuContainer>
    </Root>
  );
};

export default SideBar;
