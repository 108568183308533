import { Checkbox, Divider, styled, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useState } from "react";
import CustomButton from "../Buttons/CustomButton";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)(({ isMobileVersion }) => ({
  width: "100%",
  padding: isMobileVersion ? "10px 24px 15px 24px" : "30px 44px 15px 44px",
  background: "#f3f3f6",
  marginTop: "50px",
  borderRadius: "20px",
}));

const StyledContent = styled(Box)(({ isMobileVersion }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  flexDirection: isMobileVersion ? "column" : "row",
  gap: isMobileVersion ? "30px" : "0",
}));

const StyledLeftWrapper = styled(Box)({ flex: 1 });

const StyledRightWrapper = styled(Box)({ flex: 1 / 2 });

const StyledChooseModeText = styled(Typography)({
  fontWeight: 700,
  fontSize: "18px",
});

const StyledModeItemWrapper = styled(Box)({
  display: "flex",
  cursor: "pointer",
});

const StyledModeItemText = styled("span")(({ active }) => ({
  fontSize: "12px",
  display: "flex",
  alignItems: "center",
  paddingLeft: "10px",
  opacity: active ? 1 : 0.5,
  pointerEvents: "none",
}));

const StyledCheckbox = styled(Checkbox)(({ active }) => ({
  padding: "0px",
  "& svg": { width: "18px", height: "18px" },
  opacity: active ? 1 : 0.5,
}));

const StyledPrimaryLabel = styled("span")({
  fontSize: "12px",
  opacity: 0.6,
});

const StyledSecondaryLabel = styled("span")({
  fontSize: "13px",
});

const CartFooter = ({ isMobileVersion }) => {
  const { t } = useTranslation();
  const [shippingMode, setShippingMode] = useState({
    pickUp: true,
    delivery: false,
  });

  const handleShippingModeClick = (key) => {
    if (shippingMode[key]) return;

    setShippingMode({
      pickUp: key === "pickUp",
      delivery: key === "delivery",
    });
  };

  return (
    <StyledRoot isMobileVersion={isMobileVersion}>
      <StyledContent isMobileVersion={isMobileVersion}>
        <StyledLeftWrapper>
          <StyledChooseModeText>Choose shipping mode:</StyledChooseModeText>
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <StyledModeItemWrapper
              onClick={() => handleShippingModeClick("pickUp")}
            >
              <StyledCheckbox
                active={shippingMode.pickUp}
                icon={<RadioButtonUncheckedIcon />} // Circle outline for unchecked state
                checkedIcon={<CheckCircleIcon />} // Filled circle for checked state
                checked={shippingMode.pickUp}
              />
              <StyledModeItemText active={shippingMode.pickUp}>
                Store pickup ( In 20 min )
                <span style={{ padding: "0px 8px" }}>•</span>
                FREE
              </StyledModeItemText>
            </StyledModeItemWrapper>
            <StyledModeItemWrapper
              onClick={() => handleShippingModeClick("delivery")}
            >
              <StyledCheckbox
                active={shippingMode.delivery}
                sx={{ alignItems: "flex-start", width: "18px", height: "18px" }}
                icon={<RadioButtonUncheckedIcon />} // Circle outline for unchecked state
                checkedIcon={<CheckCircleIcon />} // Filled circle for checked state
                checked={shippingMode.delivery}
              />
              <StyledModeItemText
                active={shippingMode.delivery}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "5px",
                }}
              >
                <span>Delivery at home ( Under 2 - 4 day ) - 9.90 ₾</span>
                <span>At 45 WolfStreet Ave. Brooklyn, NY 11220</span>
              </StyledModeItemText>
            </StyledModeItemWrapper>
          </Box>
        </StyledLeftWrapper>
        <StyledRightWrapper>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <StyledPrimaryLabel>SUBTOTAL TTC</StyledPrimaryLabel>
            <StyledSecondaryLabel>1956.97 ₾</StyledSecondaryLabel>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 0",
              borderBottom: "1px solid #cececece",
            }}
          >
            <StyledPrimaryLabel>SHIPPING</StyledPrimaryLabel>
            <StyledSecondaryLabel>Free</StyledSecondaryLabel>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 0",
            }}
          >
            <StyledPrimaryLabel>TOTAL</StyledPrimaryLabel>
            <StyledSecondaryLabel>1956.97 ₾</StyledSecondaryLabel>
          </Box>
          <CustomButton buttonStyles={{ marginTop: 15 }} text={t("next")} />
        </StyledRightWrapper>
      </StyledContent>
    </StyledRoot>
  );
};

export default CartFooter;
