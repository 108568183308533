const MenuMobileIcon = () => {
  return (
    <svg
      fill="#000000"
      width="64px"
      height="64px"
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path d="M16 27a1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1 1 1 0 0 1 1 1zM13.5 2h3c.277 0 .5.223.5.5s-.223.5-.5.5h-3c-.277 0-.5-.223-.5-.5s.223-.5.5-.5zm-6-2C6.678 0 6 .678 6 1.5v27c0 .822.678 1.5 1.5 1.5h15c.822 0 1.5-.678 1.5-1.5v-27c0-.822-.678-1.5-1.5-1.5h-15zm0 1h15c.286 0 .5.214.5.5v27c0 .286-.214.5-.5.5h-15c-.286 0-.5-.214-.5-.5v-27c0-.286.214-.5.5-.5z"></path>
      </g>
    </svg>
  );
};

export default MenuMobileIcon;
