const HeaderMobileIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 1024 1024"
      className="icon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d="M512 512m-480 0a480 480 0 1 0 960 0 480 480 0 1 0-960 0Z"
          fill="#7ebdfb"
        ></path>
        <path
          d="M678.4 780.8H345.6c-25.6 0-44.8-19.2-44.8-44.8V249.6c0-25.6 19.2-44.8 44.8-44.8h332.8c25.6 0 44.8 19.2 44.8 44.8v486.4c0 25.6-19.2 44.8-44.8 44.8z"
          fill="#1875CC"
        ></path>
        <path
          d="M633.6 608H390.4c-19.2 0-32-12.8-32-32V294.4c0-19.2 12.8-32 32-32H640c19.2 0 32 12.8 32 32V576c-6.4 19.2-19.2 32-38.4 32z"
          fill="#5c90c1"
        ></path>
        <path
          d="M512 697.6m-38.4 0a38.4 38.4 0 1 0 76.8 0 38.4 38.4 0 1 0-76.8 0Z"
          fill="#FFFFFF"
        ></path>
      </g>
    </svg>
  );
};

export default HeaderMobileIcon;
