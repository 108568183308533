import { styled } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";

const Root = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  height: "350px",
  background: "#fff",
  borderRadius: "16px",
  padding: "8px 16px",
  outline: "none !important",
  WebkitBoxShadow: "0px 0px 16px 4px rgba(161,166,255,1)",
  MozBoxShadow: "0px 0px 16px 4px rgba(161,166,255,1)",
  boxShadow: "0px 0px 16px 4px rgba(161,166,255,1)",
  paddingTop: "20px",
}));

const PromoCodeModal = ({ open, handleClose, messageInfo }) => {
  const { t } = useTranslation();

  const imageSrc = messageInfo
    ? `data:${messageInfo.FileFormat};base64,${messageInfo.Image}`
    : "";

  const getText = () => {
    if (!messageInfo) return;
    if (messageInfo.PrizeByPromoCodeEnum === 0) {
      return `${t("youWin")} ${messageInfo.Count} ${t("wonTicket")}`;
    } else if (messageInfo.PrizeByPromoCodeEnum === 1) {
      return `${t("youWin")} ${messageInfo.Count} ${t("wonCoins")}`;
    } else {
      return `${t("youWin")} ${messageInfo.Count}% ${t("wonOffer")}`;
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiBackdrop-root": {
          border: "none",
        },
        "& .MuiPaper-root": {
          border: "none",
        },
      }}
    >
      <Root>
        <p
          style={{
            textAlign: "center",
            fontWeight: 600,
            fontSize: "18px",
          }}
        >
          {t("congratulation")}
        </p>
        <p
          style={{
            textAlign: "center",
            fontWeight: 400,
            fontSize: "14px",
            margin: "10px 0px 30px 0px",
          }}
        >
          {" "}
          {getText()}
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={imageSrc}
            alt={"prize image"}
            width={"200px"}
            height={"200px"}
          />
        </div>
      </Root>
    </Modal>
  );
};

export default PromoCodeModal;
