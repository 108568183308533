import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

function useAnimatedTitle() {
  const { t } = useTranslation();
  const originalTitle = "MobileStore.ge";
  const hiddenTitle = "👀 " + t("comeBackPlease");
  const intervalRef = useRef(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        document.title = hiddenTitle;
      } else {
        // User came back to the tab, stop animation and reset title
        clearInterval(intervalRef.current);
        document.title = originalTitle;
      }
    };

    // Listen for visibility change event
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener and interval on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearInterval(intervalRef.current);
    };
  }, []);

  return null;
}

export default useAnimatedTitle;
