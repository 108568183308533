import { IconButton, styled } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { DeleteIcon } from "../../svgIcons";
import { useState } from "react";

const Root = styled("div")(({ isMobileVersion }) => ({
  display: "flex",
  alignItems: "center",
  // background: "rgba(237, 235, 235, 0.74)",
  padding: "0px 8px 8px 8px",
  gap: "10px",
  height: "90px",
  borderBottom: isMobileVersion ? "1px solid #cececece" : "",
}));

const LeftContainer = styled("div")(({}) => ({
  flex: 0.3,
  display: "flex",
  height: "100%",
}));

const CenterContainer = styled("div")(() => ({
  flex: 0.4,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "space-between",
  paddingTop: "15px",
  gap: "2px",
}));

const RightContainer = styled("div")(({}) => ({
  flex: 0.3,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const CustomImg = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
}));

const ProductText = styled("p")(({ theme }) => ({
  fontSize: "12px",
  color: "#313131cc",
  fontWeight: "600",
  width: "100%",
  overflow: "hidden",
  display: "-webkit-box", // This is correct
  WebkitBoxOrient: "vertical", // Capitalize 'W' in 'Webkit'
  WebkitLineClamp: 2, // Capitalize 'W' in 'Webkit'
}));

const ProductPrice = styled("p")(({ isMobileVersion }) => ({
  fontSize: "12px",
  fontWeight: isMobileVersion ? 500 : "800",
  // marginTop: isMobileVersion ? "15px" : 0,
}));

const DeleteBtnContainer = styled("div")(({}) => ({
  display: "flex",
  justifyContent: "end",
}));

const ActionsContainer = styled("div")(({ theme }) => ({
  background: "#3281FE",
  borderRadius: "100px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "30px",
}));

const MinusContainer = styled("div")(({ theme }) => ({
  flex: 1,
  background: "#3281FE",
  borderRadius: "100px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "96%",
  transition: "background 0.3s ease",
  "& svg": {
    fontSize: "16px",
    color: "#FFFFFF",
  },
  "&:hover": {
    background: "#0748a93b",
  },
}));

const PlusContainer = styled(MinusContainer)(({ theme }) => ({}));

const ProductAmount = styled("p")({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "14px",
  fontWeight: "400",
  color: "#FFFFFF",
});

const CartItem = ({
  imgSrc,
  title,
  price,
  isMobileVersion = false,
  onClickDelete,
}) => {
  const [quantityValue, setQuantityValue] = useState(0);

  const handleIncrease = () => {
    setQuantityValue(quantityValue + 1);
  };

  const handleDecrease = () => {
    if (quantityValue === 0) return;
    setQuantityValue(quantityValue - 1);
  };
  return (
    <Root isMobileVersion={isMobileVersion}>
      <LeftContainer>
        <CustomImg src={imgSrc} alt="photo" />
      </LeftContainer>
      <CenterContainer>
        <ProductText>{title}</ProductText>
        <ProductPrice isMobileVersion={isMobileVersion}>
          {price + "    ₾"}
        </ProductPrice>
      </CenterContainer>
      <RightContainer>
        <DeleteBtnContainer>
          <IconButton onClick={onClickDelete} sx={{ padding: "5px" }}>
            <DeleteIcon />
          </IconButton>
        </DeleteBtnContainer>
        <ActionsContainer>
          <MinusContainer onClick={handleDecrease} sx={{ cursor: "pointer" }}>
            <RemoveIcon />
          </MinusContainer>
          <ProductAmount>{quantityValue}</ProductAmount>
          <PlusContainer onClick={handleIncrease} sx={{ cursor: "pointer" }}>
            <AddIcon />
          </PlusContainer>
        </ActionsContainer>
      </RightContainer>
    </Root>
  );
};

export default CartItem;
