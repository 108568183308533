import { styled, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PasswordTextFieldIconContainer from "./PasswordTextFieldIconContainer";
import { useEffect, useState } from "react";

const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
    {
      WebkitAppearance: "none",
      margin: 0,
    },
  display: "flex",
  alignItems: "center",
}));

const TextFieldCustomStyle = styled(TextField)(({ theme, size }) => ({
  width: "100%",
  "& .MuiFilledInput-root": {
    borderRadius: "8px",
    transition: "box-shadow 0.3s ease-in-out, background 0.3s ease-in-out",
    "&:before, &:hover:before, &:after": {
      borderBottom: "none !important",
    },
    "&:hover": {
      borderBottom: "none",
    },
    "&.Mui-focused": {
      borderBottom: "none",
    },
  },
  "& .MuiInputLabel-root": {
    fontWeight: 600,
    fontSize: "14px",
  },
  "& .MuiInputBase-input": {
    fontSize: "16px",
    fontWeight: 400,
  },
}));

const CustomTextFields = ({
  placeholder,
  type = "text",
  showPassword = false,
  onPasswordShow,
  passwordIcon = false,
  onChange = () => {},
  defaultValue = "",
  onKeyDown = () => {},
  size = "default",
  disabled,
  value,
}) => {
  const [fieldValue, setFieldValue] = useState("");

  useEffect(() => {
    setFieldValue(value || "");
  }, [value]);

  return (
    <Root>
      <TextFieldCustomStyle
        disabled={disabled}
        type={type}
        label={placeholder}
        variant="filled"
        autoComplete="off"
        onChange={(event) => {
          onChange(event.target.value);
          setFieldValue(event.target.value);
        }}
        defaultValue={defaultValue}
        value={fieldValue}
        size={size}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onKeyDown();
          }
        }}
        InputProps={{
          endAdornment: passwordIcon ? (
            showPassword ? (
              <PasswordTextFieldIconContainer>
                <VisibilityIcon
                  onClick={() => onPasswordShow(!showPassword)}
                  style={{
                    cursor: "pointer",
                    color: "grey",
                    width: "20px",
                  }}
                />
              </PasswordTextFieldIconContainer>
            ) : (
              <PasswordTextFieldIconContainer>
                <VisibilityOffIcon
                  onClick={() => onPasswordShow(!showPassword)}
                  style={{
                    cursor: "pointer",
                    color: "grey",
                    width: "20px",
                  }}
                />
              </PasswordTextFieldIconContainer>
            )
          ) : null,
        }}
      />
    </Root>
  );
};

export default CustomTextFields;
