import { CircularProgress, Divider, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UpdateUserInfo } from "../../api/services/user.api";
import { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import CustomButton from "../Buttons/CustomButton";
import CustomTextFields from "../Fields/CustomTextFields";
import CustomDateField from "../Fields/CustomDateField";
import { connect } from "react-redux";
import QRCodeDisplay from "./QRCodeDisplay";
import UserProfileImage from "./UserProfileImage";
import Box from "@mui/material/Box";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import UserPrizeCard from "./UserPrizeCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { NavigateBefore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
  marginTop: "16px",
  borderColor: "#cccccc",
  marginBottom: "30px",
}));

const HeaderText = styled("p")({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
});

const Content = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "20px", // Default gap for large screens
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const KeyText = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontSize: "12px",
}));

const ValueText = styled("span")(({ theme }) => ({
  fontWeight: 500,
  fontSize: "13px",
  color: "cadetblue",
}));

const ShowMoreContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

const UserInfoPage = ({
  userInfoReducer,
  mainLoading,
  loadingPrize,
  myPrizes,
  isMobileVersion,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [hover, setHover] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState({
    isMore: null,
    isExpand: false,
  });
  const [userInfo, setUserInfo] = useState({
    firstName: null,
    secondName: null,
    birthdayDate: null,
    userImage: null,
    fileFormat: null,
  });

  const imageInputRef = useRef();

  useEffect(() => {
    setUserInfo({
      ...userInfo,
      firstName: userInfoReducer.firstName,
      secondName: userInfoReducer.secondName,
      birthdayDate: userInfoReducer.birthdayDate,
      userImage: userInfoReducer.userImage,
      fileFormat: userInfoReducer.fileFormat,
    });
  }, [userInfoReducer]);

  useEffect(() => {
    setShowMoreInfo({
      isMore: myPrizes.length > 6 ? true : null,
      isExpand: false,
    });
  }, [myPrizes]);

  const handleInputChange = (key, value) => {
    setUserInfo({
      ...userInfo,
      [key]: value,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      const fileFormat = file.type;
      setUserInfo({
        ...userInfo,
        userImage: base64String,
        fileFormat: fileFormat,
      });
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  const handleSubmit = async () => {
    const { firstName, secondName, birthdayDate, userImage, fileFormat } =
      userInfo;
    const payload = {
      FirstName: firstName.trim() || null,
      SecondName: secondName.trim() || null,
      BirthdayDate: birthdayDate ? birthdayDate : null,
      UserImage: userImage ? userImage : null,
      FileFormat: fileFormat || null,
    };

    setLoading(true);
    UpdateUserInfo(payload)
      .then((resp) => {
        if (resp.data.message === "FileIsMoreThan1MB") {
          enqueueSnackbar(t("FileIsMoreThan1MB"), {
            variant: "warning",
          });
        } else {
          enqueueSnackbar(t("profileSuccessUpdate"), {
            variant: "success",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(t("errorPleaseContactWebsiteAdministrator"), {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
        setEditMode(false);
      });
  };

  return (
    <Root>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {isMobileVersion ? (
          <Box display={"flex"}>
            <NavigateBefore
              onClick={() => {
                navigate("/profile");
              }}
              sx={{ marginLeft: "-8px" }}
            />
            <HeaderText>{t("userInfo")}</HeaderText>
          </Box>
        ) : (
          <HeaderText>{t("userInfo")}</HeaderText>
        )}
        <CustomButton
          onClick={() => (editMode ? handleSubmit() : setEditMode(true))}
          text={editMode ? t("save") : t("edit")}
          loading={loading}
          buttonStyles={{
            maxWidth: "fit-content",
            minWidth: isMobileVersion ? "80px" : "150px",
            padding: "0px 6px",
            background: editMode ? "green" : "",
          }}
        />
      </div>
      <CustomDivider />

      <Content>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            maxWidth: !isMobileVersion && "200px",
            width: isMobileVersion && "100%",
          }}
        >
          <Box
            style={{
              display: "flex",
              position: "relative",
              maxHeight: "190px",
              justifyContent: isMobileVersion && "center",
            }}
            onMouseEnter={() => editMode && setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Box
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: hover ? "rgba(0,0,0,0.8)" : "transparent",
                borderRadius: "16px",
                cursor: "pointer",
                transition: "background 0.3s ease-in-out",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => editMode && imageInputRef.current.click()}
              sx={{
                "& svg": {
                  opacity: 0,
                  width: "25px",
                  height: "25px",
                  transition:
                    "opacity 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out",
                },
                "&:hover": {
                  "& svg": {
                    opacity: editMode ? 1 : 0,
                    width: "50px",
                    height: "50px",
                  },
                },
              }}
            >
              <AddPhotoAlternateOutlinedIcon sx={{ color: "#FFFFFF" }} />
            </Box>
            {mainLoading ? (
              <div
                style={{
                  display: "flex",
                  height: "190px",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CircularProgress size={50} />
              </div>
            ) : (
              <UserProfileImage
                FileFormat={userInfo.fileFormat}
                Image={userInfo.userImage}
              />
            )}

            <input
              ref={imageInputRef}
              style={{ display: "none" }}
              type={"file"}
              onChange={handleFileChange}
              accept="image/*"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: isMobileVersion ? "15px" : "3px",
              "& div": {
                display: "flex",
                justifyContent: "space-between",
              },
            }}
          >
            <div>
              <KeyText>{t("invitedUsers")}</KeyText>
              <ValueText>{userInfoReducer.promoUserCount || 0} </ValueText>
            </div>
            <div>
              <KeyText>{t("invationCode")}</KeyText>
              <ValueText>{userInfoReducer.promoCode || 0} </ValueText>
            </div>
            <div>
              <KeyText>{t("tickets")} </KeyText>
              <ValueText>{userInfoReducer.tickets || 0} </ValueText>
            </div>
            <div>
              <KeyText>{t("coins")}</KeyText>
              <ValueText>{userInfoReducer.points || 0} </ValueText>
            </div>
            <div>
              <KeyText>{t("rank")}</KeyText>
              <ValueText>
                {userInfoReducer.rank
                  ? t(userInfoReducer.rank)
                  : t("rank_beginner")}{" "}
              </ValueText>
            </div>
          </Box>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            gap: "10px",
            flexDirection: "column",
          }}
        >
          <CustomTextFields
            disabled={!editMode}
            placeholder={t("name")}
            onChange={(val) => handleInputChange("firstName", val)}
            value={userInfo.firstName}
          />

          <CustomTextFields
            disabled={!editMode}
            placeholder={t("surname")}
            onChange={(val) => handleInputChange("secondName", val)}
            value={userInfo.secondName}
          />
          <CustomDateField
            disabled={!editMode}
            placeholder={t("birthdayDate")}
            onChange={(date) => handleInputChange("birthdayDate", date)}
            value={userInfo.birthdayDate}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: isMobileVersion && "center",
            }}
          >
            {mainLoading ? (
              <div
                style={{
                  display: "flex",
                  height: "190px",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "200px",
                }}
              >
                <CircularProgress size={50} />
              </div>
            ) : (
              <QRCodeDisplay qrCode={userInfoReducer.qrCode} />
            )}
          </div>
        </div>
      </Content>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            minWidth: "0px",
            alignItems: "center",
          }}
        >
          <CustomDivider />
        </div>
        <div
          style={{
            minWidth: "200px",
            paddingBottom: "15px",
            textAlign: "center",
            fontWeight: 500,
          }}
        >
          <p>{t("receivedPrizes")}</p>
        </div>
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            minWidth: "0px",
            alignItems: "center",
          }}
        >
          <CustomDivider />
        </div>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        {loadingPrize ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              marginTop: "30px",
            }}
          >
            <CircularProgress size={50} />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {myPrizes.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <p>{t("doNotHaveAnyPrizeYet")}</p>
                <img
                  src={"assets/empty-prizes.png"}
                  alt={"empty prize image"}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "10px",
                  marginTop: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  {!showMoreInfo.isExpand
                    ? myPrizes.slice(0, 6).map((item, index) => {
                        return <UserPrizeCard key={index} item={item} />;
                      })
                    : myPrizes.map((item, index) => {
                        return <UserPrizeCard key={index} item={item} />;
                      })}
                </div>
                {showMoreInfo.isMore !== null && (
                  <ShowMoreContainer>
                    <div
                      style={{ display: "flex", cursor: "pointer" }}
                      onClick={() =>
                        setShowMoreInfo({
                          isMore: !showMoreInfo.isMore,
                          isExpand: !showMoreInfo.isExpand,
                        })
                      }
                    >
                      <Typography>
                        {showMoreInfo.isMore ? t("showMore") : t("showLess")}
                      </Typography>
                      <KeyboardArrowDownIcon />
                    </div>
                  </ShowMoreContainer>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  userInfoReducer: state.main.userInfo,
});

const dispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, dispatchToProps)(UserInfoPage);
