export const CheckEmailValidation = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const getLocalStorageValue = (key, defaultValue) => {
  const currValue = window.localStorage.getItem(key);
  return currValue !== null && currValue !== undefined
    ? currValue
    : defaultValue;
};

export const setLocalStorageValue = (key, value) => {
  if (key) {
    window.localStorage.setItem(key, value);
  }
};

export const getUserLanguage = () => {
  return getLocalStorageValue("language", "ge");
};

export const saveArrayToLocalStorage = (key, array) => {
  if (Array.isArray(array)) {
    localStorage.setItem(key, JSON.stringify(array));
  } else {
    console.error("Provided value is not an array");
  }
};

export const getArrayFromLocalStorage = (key) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : [];
};

export const PopularSearches = [
  "iPhone 15",
  "iPhone 15 Pro",
  "Galaxy S24",
  "iPhone 11",
  "iPhone 12",
  "iPhone 14",
  "iPhone 13",
  "Laptops",
  "PS5",
  "JBL",
  "MacBook",
  "Pixel",
  "Polaroid",
  "iPad",
  "Dyson Hair Dryer",
  "Xiaomi 14",
  "Redmi Note 12",
  "Galaxy S23",
  "AirPods",
  "TV",
];

export const ProductOfferCategory = {
  TopOffers: "TopOffers",
  BestOffer: "BestOffer",
  NewModels: "NewModels",
  SecondHandModels: "SecondHandModels",
  Mobiles: "Mobiles",
};

export const BrandEnumArray = [
  {
    Name: "Apple",
    value: 0,
  },
  {
    Name: "Nokia",
    value: 1,
  },
  {
    Name: "OnePluse",
    value: 2,
  },
  {
    Name: "Samsung",
    value: 3,
  },

  {
    Name: "Xiaomi",
    value: 4,
  },
  {
    Name: "Google",
    value: 5,
  },
  {
    Name: "Realme",
    value: 6,
  },
  {
    Name: "Motorola",
    value: 7,
  },
  {
    Name: "Nothing",
    value: 8,
  },
];

export const MobileSystemEnumArray = [
  {
    Name: "Ios",
    value: 0,
  },
  {
    Name: "Android",
    value: 1,
  },
];
