import { styled } from "@mui/material";

const Root = styled("div")(({ theme }) => ({
  width: "160px",
  height: "80px",
  display: "flex",
  boxSizing: "border-box",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  background: "#f2f2f2",
  borderRadius: "20px",
  transition: "background 0.3s ease-in-out",
  "&:hover": {
    background: "#d9d9d9",
  },
  "& svg": {
    width: "50px",
    height: "50px",
  },
}));

const BrandItem = ({ item }) => {
  return <Root>{item.icon}</Root>;
};

export default BrandItem;
