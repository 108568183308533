import { styled } from "@mui/material";
import { useState } from "react";

const Root = styled("div")(({ theme }) => ({
  right: 20,
  bottom: 90,
  borderRadius: "50%",
  width: "50px",
  height: "50px",
  background: "#fff",
  transition: "transform 0.3s ease-in-out",
  cursor: "pointer",
  zIndex: 20,
  position: "fixed",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  WebkitBoxShadow: "0px 0px 8px 3px rgba(180,208,237,1)",
  MozBoxShadow: "0px 0px 8px 3px rgba(180,208,237,1)",
  boxShadow: "0px 0px 8px 3px rgba(180,208,237,1)",
  "&:hover": {
    transform: "scale(1.2)",
  },
}));

const PopoverBox = styled("div")(({ theme, open }) => ({}));

const Gift = ({ openPrizeModal, setOpenPrizeModal }) => {
  const [showGIF, setShowGIF] = useState(false);

  const handleClick = () => {
    setOpenPrizeModal(true);
  };

  return (
    <Root
      onClick={handleClick}
      onMouseEnter={() => setShowGIF(true)}
      onMouseLeave={() => setShowGIF(false)}
    >
      <img
        src={showGIF ? "assets/gifs/gift.gif" : "assets/gifs/gift.png"}
        alt={"notification-icon"}
        width={30}
        height={30}
      />
    </Root>
  );
};

export default Gift;
