const UserProfileImage = ({ FileFormat, Image }) => {
  const imageSrc = Image
    ? `data:${FileFormat};base64,${Image}`
    : `assets/user-profile-img.webp`;
  return (
    <div
      style={{
        width: "200px",
        height: "190px",
        borderRadius: "16px", // Border radius for the container
        overflow: "hidden",
        display: "flex", // Center the image
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover", // Ensure the image covers the container while maintaining aspect ratio
          borderRadius: "20px", // Apply the border radius directly to the image
        }}
        src={imageSrc}
        alt="user profile image"
      />
    </div>
  );
};

export default UserProfileImage;
