import { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import CategoryItem from "./CategoryItem";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Box from "@mui/material/Box";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { GetProductByCategory } from "../../api/services/products.api";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import SwiperCore from "swiper";
import { Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";

SwiperCore.use([Autoplay]);

const Root = styled(Box)(({ theme }) => ({
  width: "100%",
  boxSizing: "border-box",
  height: "323px",
  position: "relative",
  minWidth: "0px",
}));

const Header = styled("div")(({ theme, isMobileVersion }) => ({
  color: "#000",
  fontSize: isMobileVersion ? "11px" : "16px",
  opacity: 0.8,
  fontWeight: 700,
  display: "flex",
  justifyContent: "space-between",
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  padding: "6px",
  borderRadius: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#fff",
  transition: "background 0.3s ease-in-out, opacity 0.3s ease-in-out",
  cursor: "pointer",
  filter: "drop-shadow(rgba(0, 0, 0, 0.1) 0px 2px 10px)",
  gap: "10px",
  position: "absolute",
  zIndex: 2,
  opacity: 0,
  height: "32px",
  width: "32px",
  top: "100px",
  "&:hover": {
    background: "#f2f2f2",
  },
  "& svg": {
    width: "16px",
    height: "16px",
  },
}));

const StyledSeeFull = styled("span")(() => ({
  cursor: "pointer",
  transition: "transform 0.3s ease-in-out",
  color: "#1875CC",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const CategorySwiper = ({
  title,
  cardContainerRef,
  categoryName,
  showFull = "",
  isMobileVersion,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isAtBeginning, setIsAtBeginning] = useState(false);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [partiallyVisibleSlides, setPartiallyVisibleSlides] = useState(
    new Set(),
  );
  const [visibleSlidesCount, setVisibleSlidesCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    GetProductByCategory(categoryName)
      .then((resp) => {
        if (resp.data.data) setData(JSON.parse(resp.data.data).Products);
      })
      .catch(() => {
        enqueueSnackbar(t("errorPleaseContactWebsiteAdministrator"), {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [categoryName]);

  useEffect(() => {
    const updateButtonStates = () => {
      if (swiperRef.current) {
        setIsAtBeginning(swiperRef.current.isBeginning);
        setIsAtEnd(swiperRef.current.isEnd);
      }
    };

    updateButtonStates();

    const swiperInstance = swiperRef.current;
    swiperInstance.on("slideChange", updateButtonStates);

    return () => {
      swiperInstance.off("slideChange", updateButtonStates);
    };
  }, [data]);

  useEffect(() => {
    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      swiperInstance.on("slideChangeTransitionEnd", updateSlideVisibility);
      swiperInstance.on("init", updateSlideVisibility);
    }

    updateSlideVisibility();

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChangeTransitionEnd", updateSlideVisibility);
        swiperInstance.off("init", updateSlideVisibility);
      }
    };
  }, [data]);

  useEffect(() => {
    const updateSlideVisibility = () => {
      const swiper = swiperRef.current;
      if (!swiper || !swiper.slides) return;

      const newPartiallyVisibleSlides = new Set();
      swiper.slides.forEach((slide, index) => {
        const slideRect = slide.getBoundingClientRect();
        const swiperRect = swiper.el.getBoundingClientRect();

        const isPartiallyVisible =
          slideRect.left < swiperRect.left ||
          slideRect.right > swiperRect.right;

        if (isPartiallyVisible) {
          newPartiallyVisibleSlides.add(index);
        }
      });

      setPartiallyVisibleSlides(newPartiallyVisibleSlides);
    };

    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      swiperInstance.on("init", updateSlideVisibility);
      swiperInstance.on("slideChangeTransitionEnd", updateSlideVisibility);

      updateSlideVisibility();
    }

    window.addEventListener("resize", updateSlideVisibility);

    return () => {
      if (swiperInstance) {
        swiperInstance.off("init", updateSlideVisibility);
        swiperInstance.off("slideChangeTransitionEnd", updateSlideVisibility);
        window.removeEventListener("resize", updateSlideVisibility);
      }
    };
  }, [data]);

  useEffect(() => {
    const handleResizeOrInit = () => {
      const swiper = swiperRef.current;
      if (swiper) {
        swiper.update();
        updateVisibleSlidesCount(swiper);
      }
    };

    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      swiperInstance.on("init", handleResizeOrInit);
    }
    window.addEventListener("resize", handleResizeOrInit);

    if (swiperInstance) {
      handleResizeOrInit();
    }

    return () => {
      window.removeEventListener("resize", handleResizeOrInit);
      if (swiperInstance) {
        swiperInstance.off("init", handleResizeOrInit);
      }
    };
  }, [data]);

  const updateVisibleSlidesCount = (swiper) => {
    if (!swiper || !swiper.slides.length || !swiper.slides[0]) return;

    const slideWidth =
      swiper.slides[0].offsetWidth + swiper.params.spaceBetween;
    const count = Math.floor(swiper.width / slideWidth);
    setVisibleSlidesCount(count);
  };

  const updateSlideVisibility = () => {
    const swiper = swiperRef.current;
    if (!swiper) return;

    const newPartiallyVisibleSlides = new Set();
    swiper.slides.forEach((slide, index) => {
      const slideLeftEdge = slide.offsetLeft;
      const slideRightEdge = slideLeftEdge + slide.offsetWidth;
      const swiperVisibleLeftEdge = swiper.translate;
      const swiperVisibleRightEdge = swiperVisibleLeftEdge + swiper.width;

      if (
        slideLeftEdge < swiperVisibleLeftEdge ||
        slideRightEdge > swiperVisibleRightEdge
      ) {
        newPartiallyVisibleSlides.add(index);
      }
    });

    setPartiallyVisibleSlides(newPartiallyVisibleSlides);
  };

  const goLeft = () => {
    if (!swiperRef.current) return;
    const swiper = swiperRef.current;
    const currentIndex = swiper.activeIndex;
    const newIndex = Math.max(currentIndex - 1, 0);
    swiper.slideTo(newIndex);
  };

  const goRight = () => {
    if (!swiperRef.current) return;
    const swiper = swiperRef.current;
    const currentIndex = swiper.activeIndex;
    const slidesCount = swiper.slides.length;
    const newIndex = Math.min(currentIndex + 1, slidesCount - 1);
    swiper.slideTo(newIndex);
  };

  return (
    <Root
      sx={{
        "&:hover": {
          "& #banner_left_arrow": {
            opacity: 1,
          },
          "& #banner_right_arrow": {
            opacity: 1,
          },
        },
      }}
    >
      <Header isMobileVersion={isMobileVersion}>
        <span>{title}</span>
        {showFull && (
          <StyledSeeFull onClick={() => navigate(showFull)}>
            {t("seeFull")}
          </StyledSeeFull>
        )}
      </Header>
      {!isMobileVersion && (
        <ButtonContainer
          onClick={() => goLeft()}
          style={{
            left: "30px",
            opacity: loading ? 0 : 1,
          }}
          id={"banner_left_arrow"}
        >
          <ArrowBackIosNewIcon
            style={{
              color: "#1875CC",
            }}
          />
        </ButtonContainer>
      )}

      {!isMobileVersion && (
        <ButtonContainer
          onClick={() => goRight()}
          style={{
            right: "30px",
            opacity: loading ? 0 : 1,
          }}
          id={"banner_left_arrow"}
        >
          <ArrowForwardIosIcon
            style={{
              color: "#1875CC",
            }}
          />
        </ButtonContainer>
      )}
      <Swiper
        spaceBetween={isMobileVersion ? 10 : 35}
        slidesPerView={"auto"}
        onSlideChange={() => {}}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        style={{
          padding: isMobileVersion ? "10px 0px" : "10px 15px",
          height: "100%",
          cursor: "pointer",
        }}
        speed={1000}
        autoplay={{ delay: 4000 }}
        modules={[Autoplay]}
      >
        {loading
          ? Array.from({ length: 10 }).map((_, index) => (
              <SwiperSlide
                key={index}
                style={{
                  color: "#fff",
                  minWidth: "0px",
                  width: "fit-content",
                  transition: "opacity 0.2s ease-in-out",
                  opacity: 1,
                }}
              >
                <Skeleton variant="rounded" width={"160px"} height="100%" />
              </SwiperSlide>
            ))
          : data.map((item, index) => {
              const isPartiallyVisible = partiallyVisibleSlides.has(index);
              return (
                <SwiperSlide
                  style={{
                    color: "#fff",
                    minWidth: "0px",
                    width: "fit-content",
                    transition: "opacity 0.2s ease-in-out",
                    opacity: isPartiallyVisible ? 0.1 : 1,
                  }}
                  key={index}
                >
                  <CategoryItem
                    categoryInfo={item}
                    cardContainerRef={cardContainerRef}
                    isMobileVersion={isMobileVersion}
                  />
                </SwiperSlide>
              );
            })}
      </Swiper>
    </Root>
  );
};

export default CategorySwiper;
