const MenuSmartGadgetIcon = () => {
  return (
    <svg
      fill="#000000"
      width="64px"
      height="64px"
      viewBox="0 0 64 64"
      version="1.1"
      xmlSpace={"preserve"}
      xmlns={"http://www.w3.org/2000/svg"}
      xmlnsXlink={"http://www.w3.org/1999/xlink"}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g id="smart_lamp"></g>
        <g id="e-wallet"></g>
        <g id="virtual_reality"></g>
        <g id="payment"></g>
        <g id="cloud_storage"></g>
        <g id="security_camera"></g>
        <g id="smart_home">
          {" "}
          <g>
            {" "}
            <path d="M32,33c-2.7,0-5.2,1.3-6.7,3.6c-0.3,0.5-0.2,1.1,0.3,1.4s1.1,0.2,1.4-0.3c1.1-1.7,3-2.7,5-2.7s3.9,1,5,2.7 c0.2,0.3,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2c0.5-0.3,0.6-0.9,0.3-1.4C37.2,34.3,34.7,33,32,33z"></path>
            <path d="M32,29c-3.8,0-7.2,1.7-9.5,4.7c-0.3,0.4-0.3,1.1,0.2,1.4c0.4,0.3,1.1,0.3,1.4-0.2C26,32.4,28.9,31,32,31s6,1.4,7.9,3.9 c0.2,0.3,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2c0.4-0.3,0.5-1,0.2-1.4C39.2,30.7,35.8,29,32,29z"></path>
            <path d="M19.7,30.8c-0.4,0.4-0.3,1.1,0.1,1.4c0.4,0.4,1.1,0.3,1.4-0.1C23.9,28.9,27.8,27,32,27s8.1,1.9,10.8,5.1 c0.2,0.2,0.5,0.4,0.8,0.4c0.2,0,0.5-0.1,0.6-0.2c0.4-0.4,0.5-1,0.1-1.4C41.3,27.1,36.8,25,32,25S22.7,27.1,19.7,30.8z"></path>
            <path d="M60.5,18.1l-28-15c-0.3-0.2-0.6-0.2-0.9,0l-28,15C3.2,18.3,3,18.6,3,19v5c0,0.3,0.2,0.6,0.4,0.8C3.7,25,4,25.1,4.3,24.9 L7,24v31H6c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1h52c0.6,0,1-0.4,1-1v-4c0-0.6-0.4-1-1-1h-1V24l2.7,1c0.1,0,0.2,0.1,0.3,0.1 c0.2,0,0.4-0.1,0.6-0.2c0.3-0.2,0.4-0.5,0.4-0.8v-5C61,18.6,60.8,18.3,60.5,18.1z M57,59H7v-2h1h22h4h22h1V59z M32,45 c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S33.7,45,32,45z M31,46.9c0.3,0.1,0.7,0.1,1,0.1s0.7,0,1-0.1V55h-2V46.9z M55,55H35v-9 c0,0,0,0,0,0c1.2-0.9,2-2.3,2-4c0-2.8-2.2-5-5-5s-5,2.2-5,5c0,1.6,0.8,3.1,2,4c0,0,0,0,0,0v9H9V23.3l23-8.2l23,8.2V55z M59,22.6 l-26.7-9.5C32.2,13,32.1,13,32,13s-0.2,0-0.3,0.1L5,22.6v-3L32,5.1l27,14.5V22.6z"></path>{" "}
          </g>{" "}
        </g>
        <g id="connection"></g>
        <g id="game"></g>
        <g id="drone"></g>
        <g id="smart_car"></g>
        <g id="camera"></g>
        <g id="online_business"></g>
        <g id="smartwatch"></g>
        <g id="online_shopping"></g>
        <g id="smart_Television"></g>
        <g id="security"></g>
        <g id="communication"></g>
        <g id="remote_control"></g>
        <g id="satelite_dish"></g>{" "}
      </g>
    </svg>
  );
};

export default MenuSmartGadgetIcon;
