import { Popover, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { getUserLanguage, setLocalStorageValue } from "../../util/appUtil";
import i18n from "../../locale/i18n";

const ButtonContainer = styled("div")(({ isMobileVersion }) => ({
  transition: "background 0.3s ease-in-out",
  padding: "10px",
  background: isMobileVersion ? "" : "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  filter: "drop-shadow(rgba(0, 0, 0, 0.1) 0px 2px 10px)",
  width: "fit-content",
  borderRadius: "12px",
  gap: "10px",
  "&:hover": {
    background: isMobileVersion ? "" : "#f2f2f2",
  },
}));

const CustomPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "12px", // Custom border radius
  },
}));

const ImgContainer = styled("div")(({ theme }) => ({
  cursor: "pointer",
  opacity: 0.5,
  transition: "opacity 0.3s ease-in-out",
  "&:hover": {
    opacity: 1,
  },
}));

const ChangeLanguageContainer = ({ isMobileVersion }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  useEffect(() => {
    setSelectedLanguage(getUserLanguage());
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getFlagResource = () => {
    if (selectedLanguage === "en") {
      return "assets/flags/united_states.png";
    } else if (selectedLanguage === "ru") {
      return "assets/flags/russia.png";
    } else {
      return "assets/flags/georgia.png";
    }
  };

  const onChangeLanguage = (lang) => {
    i18n.changeLanguage(lang).then(() => {
      setSelectedLanguage(lang);
      setLocalStorageValue("language", lang);
      setAnchorEl(null);
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      <ButtonContainer isMobileVersion={isMobileVersion} onClick={handleClick}>
        <img
          src={getFlagResource()}
          alt={"flag"}
          style={{
            ...(isMobileVersion && {
              width: "18px",
              height: "18px",
            }),
          }}
        />
      </ButtonContainer>
      <CustomPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          top: "4px",
        }}
        disableScrollLock={true}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "44px",
            padding: "10px",
          }}
        >
          <ImgContainer onClick={() => onChangeLanguage("ge")}>
            <img src={"assets/flags/georgia.png"} alt={"flag"} />
          </ImgContainer>
          <ImgContainer onClick={() => onChangeLanguage("en")}>
            <img src={"assets/flags/united_states.png"} alt={"flag"} />
          </ImgContainer>
          <ImgContainer onClick={() => onChangeLanguage("ru")}>
            <img src={"assets/flags/russia.png"} alt={"flag"} />
          </ImgContainer>
        </div>
      </CustomPopover>
    </div>
  );
};

export default ChangeLanguageContainer;
