import { Divider, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { GetPrizeByPromoCode } from "../../api/services/promo-code.api";
import { useSnackbar } from "notistack";
import CustomTextFields from "../Fields/CustomTextFields";
import CustomButton from "../Buttons/CustomButton";
import CustomConfetti from "../CustomConfetti";
import PromoCodeModal from "./PromoCodeModal";
import { isMobile } from "react-device-detect";
import Box from "@mui/material/Box";
import { NavigateBefore } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: isMobile ? "calc(100dvh - 470px)" : "calc(100vh - 470px)",
  display: "flex",
  flexDirection: "column",
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
  marginTop: "16px",
  borderColor: "#cccccc",
  marginBottom: "60px",
}));

const HeaderText = styled("p")({
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
});

const PromoCodePage = ({ isMobileVersion }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [promoCode, setPromoCode] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const buttonRef = useRef();
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(null);
  const onClick = () => {
    if (!promoCode || promoCode.trim().length === 0) {
      enqueueSnackbar(t("fillPromoCode"), {
        variant: "warning",
      });
    } else {
      setLoading(true);
      GetPrizeByPromoCode(promoCode)
        .then((resp) => {
          if (resp.data.message === "notFound") {
            enqueueSnackbar(t("promoCodeNotFound"), {
              variant: "warning",
            });
          } else if (resp.data.message === "alreadyClaimed") {
            enqueueSnackbar(t("alreadyClaimedPromoCode"), {
              variant: "warning",
            });
          } else {
            buttonRef.current.click();
            setOpen(true);
            setMessageInfo(JSON.parse(resp.data.message));
          }
        })
        .catch(() => {
          enqueueSnackbar(t("errorPleaseContactWebsiteAdministrator"), {
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Root>
      <CustomConfetti buttonRef={buttonRef} />
      {isMobileVersion ? (
        <Box display="flex">
          <NavigateBefore
            onClick={() => {
              navigate("/profile");
            }}
            sx={{ marginLeft: "-8px" }}
          />
          <HeaderText>{t("PromoCodeAndPrize")}</HeaderText>
        </Box>
      ) : (
        <HeaderText>{t("PromoCodeAndPrize")}</HeaderText>
      )}
      <CustomDivider />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: isMobileVersion && "column",
          gap: isMobileVersion && "20px",
        }}
      >
        <CustomTextFields
          placeholder={t("writePromoCodeAndWinPrize")}
          onChange={(val) => setPromoCode(val)}
          onKeyDown={() => onClick()}
          value={promoCode}
        />
        <div
          style={{
            paddingLeft: !isMobileVersion && "50px",
            minWidth: isMobileVersion ? "150px" : "200px",
          }}
        >
          <CustomButton
            onClick={() => onClick()}
            text={t("activation")}
            loading={loading}
            buttonStyles={{
              height: "50px",
            }}
          />
        </div>
      </div>
      <PromoCodeModal
        open={open}
        handleClose={() => {
          setOpen(false);
          setMessageInfo(null);
          setPromoCode("");
        }}
        messageInfo={messageInfo}
      />
    </Root>
  );
};

export default PromoCodePage;
