import { Box, CircularProgress, styled } from "@mui/material";
import { useState } from "react";
import { isMobile } from "react-device-detect";

const RootButton = styled(Box)(({ type, isMobileVersion }) => ({
  display: "flex",
  width: "100%",
  height: isMobileVersion ? "35px !important" : "40px",
  justifyContent: "center",
  alignItems: "center",
  gap: "24px",
  borderRadius: "12px",
  borderTop: "1px solid rgba(255, 255, 255, 0.30)",
  transition: "background 0.3s ease-in-out",
  boxSizing: "border-box",
  cursor: "pointer",
  background: "#3281FE",
  position: "relative",
  "&:hover": {
    background: "#3C8BFF",
  },
}));

const Text = styled("span")(({ isMobileVersion }) => ({
  textShadow: "0px 2px 0px rgba(0, 0, 0, 0.20)",
  color: "#fff",
  fontWeight: 700,
  fontSize: isMobileVersion ? "14px" : "16px",
  lineHeight: "24px",
}));

const CustomButton = ({
  onClick,
  text,
  disabled = false,
  loading = false,
  textStyles,
  buttonStyles = {},
  children,
}) => {
  const [isMobileVersion] = useState(isMobile || window.innerWidth < 1000);

  return (
    <RootButton
      isMobileVersion={isMobileVersion}
      onClick={() => !disabled && onClick()}
      style={{
        opacity: disabled ? 0.3 : 1,
        cursor: !disabled || loading ? "pointer" : "default",
        ...buttonStyles,
      }}
    >
      {!loading && (
        <Text isMobileVersion={isMobileVersion} style={{ ...textStyles }}>
          {text}
        </Text>
      )}
      {loading && <CircularProgress size={20} style={{ color: "#fff" }} />}
      {!loading && children}
    </RootButton>
  );
};

export default CustomButton;
