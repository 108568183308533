import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import ShareIcon from "@mui/icons-material/Share";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate, useParams } from "react-router-dom";
import { GetProductById } from "../../api/services/products.api";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BrandEnumArray } from "../../util/appUtil";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useSnackbar } from "notistack";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { isMobile } from "react-device-detect";
import { NavigateBefore } from "@mui/icons-material";
import ProductItemPageFooter from "./MobileMode/ProductItemPageFooter";
import CustomAccordion from "../Common/CustomAccordion";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "space-between",
  borderBox: "box-sizing",
  gap: "50px",
}));

const Content = styled("div")(({ theme }) => ({
  width: "100%",
}));

const NavigateContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "5px",
}));

const NavigateItem = styled("div")(({ theme }) => ({
  fontWeight: 600,
  cursor: "pointer",
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
}));

const Divider = styled("div")(({ theme }) => ({
  width: "100%",
  height: "2px",
  background: "#f2f2f2",
}));

const ProductMainInfoItem = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "0px",
  boxSizing: "border-box",
}));

const ProductMainInfoItemKey = styled("div")(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "20px",
  color: "rgba(0,0,0,.6)",
}));

const ProductMainInfoItemValue = styled("div")(({ theme }) => ({
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#000",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "auto",
  maxWidth: isMobile ? "200px" : "150px",
}));

const ByItemContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  background: "#E5F5FF",
  borderRadius: "12px",
  flexDirection: "column",
  gap: "20px",
}));

const ByItemContainerTitle = styled("div")(({ theme }) => ({
  padding: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "2px solid #fff",
  width: "100%",
  height: "50px",
  minWidth: "0px",
  boxSizing: "border-box",
}));

const Price = styled("div")(() => ({
  fontWeight: 600,
  fontSize: "20px",
  lineHeight: "24px",
}));

const PriceWithBank = styled("div")(({ theme }) => ({
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "14px",
}));

const BuyButton = styled("div")(() => ({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  minWidth: "0px",
  background: "#1875CC",
  userSelect: "none",
  cursor: "pointer",
  borderRadius: "12px",
  color: "#fff",
  fontSize: "16px",
  fontWeight: 600,
  width: "100%",
  gap: "10px",
}));

const StyledImageContainer = styled("div")(({ isSelected }) => ({
  borderRadius: "8px",
  border: isSelected ? "1px solid #1875CC" : "1px solid transparent",
  cursor: "pointer",
  transition: "border 0.3s ease-in-out",
  padding: "2px",
  "&:hover": {
    border: "1px solid #1875CC",
  },
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  padding: "6px",
  borderRadius: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#fff",
  transition: "background 0.3s ease-in-out, opacity 0.3s ease-in-out",
  cursor: "pointer",
  filter: "drop-shadow(rgba(0, 0, 0, 0.1) 0px 2px 10px)",
  gap: "10px",
  position: "absolute",
  zIndex: 2,
  height: "32px",
  width: "32px",
  top: "100px",
  "&:hover": {
    background: "#f2f2f2",
  },
  "& svg": {
    width: "16px",
    height: "16px",
  },
}));

const TECHNICAL_CHARACTERISTICS_LABELS = [
  "technicalCharacteristicsSimCard",
  "technicalCharacteristicsESim",
  "technicalCharacteristics5G",
  "technicalCharacteristicsBuild",
  "technicalCharacteristicsIPProtection",
  "technicalCharacteristicsChipset",
  "technicalCharacteristicsSystem",
  "technicalCharacteristicsSystemVersion",
  "technicalCharacteristicsStereoSpeaker",
  "technicalCharacteristicsBluetooth",
  "technicalCharacteristicsGraphicProcessor",
];

const TECHNICAL_CHARACTERISTICS_KEYS = [
  "simCard",
  "eSim",
  "fiveG",
  "build",
  "ipProtection",
  "chipset",
  "system",
  "systemVersion",
  "stereoSpeaker",
  "bluetooth",
  "graphicProcessor",
];

const BATTERY_DETAILS_LABELS = [
  "battery",
  "chargingSpeed",
  "elementType",
  "wirelessCharging",
  "wirelessChargingSpeed",
];

const BATTERY_DETAILS_KEYS = [
  "battery",
  "chargingSpeed",
  "elementType",
  "wirelessCharging",
  "wirelessChargingSpeed",
];

const MEMORY_DETAILS_LABELS = [
  "internalMemory",
  "memoryStandard",
  "ram",
  "microSDSlot",
];

const MEMORY_DETAILS_KEYS = [
  "internalMemory",
  "memoryStandard",
  "ram",
  "microSDSlot",
];

const CAMERA_DETAILS_LABELS = [
  "additionalCamera",
  "mainCamera",
  "frontCamera",
  "videoResolutionOfTheMainCamera",
  "frontCameraVideoResolution",
];

const CAMERA_DETAILS_KEYS = [
  "additionalCamera",
  "mainCamera",
  "frontCamera",
  "videoResolutionOfTheMainCamera",
  "frontCameraVideoResolution",
];

const MobileItemPage = ({ isMobileVersion }) => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(1);

  useEffect(() => {
    GetProductById(productId)
      .then((resp) => {
        if (resp.data.data) setData(JSON.parse(resp.data.data));
      })
      .catch(() => {
        navigate("/");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getFirstImage = useCallback(() => {
    if (data.ProductImages && data.ProductImages.length > 0) {
      return `data:${data.ProductImages[0].FileFormat};base64,${data.ProductImages[0].Image}`;
    }
    return null;
  }, [data]);

  const getSecondImage = useCallback(() => {
    if (data.ProductImages && data.ProductImages.length > 1) {
      return `data:${data.ProductImages[1].FileFormat};base64,${data.ProductImages[1].Image}`;
    }
    return null;
  }, [data]);

  const getThirdImage = useCallback(() => {
    if (data.ProductImages && data.ProductImages.length > 2) {
      return `data:${data.ProductImages[2].FileFormat};base64,${data.ProductImages[2].Image}`;
    }
    return null;
  }, [data]);

  const convertScreeDetailStringToObject = useMemo(() => {
    const obj = {};

    if (data && data.MobileDetailInfo) {
      data.MobileDetailInfo.ScreeDetail.split("\n").forEach((line) => {
        const [key, value] = line.split(":");
        if (key && value) {
          obj[key.trim()] = isNaN(value.trim())
            ? value.trim()
            : parseFloat(value.trim());
        }
      });
    }

    return obj;
  }, [data && data.MobileDetailInfo]);

  const getScreenSize = () => {
    return data && data.MobileDetailInfo
      ? convertScreeDetailStringToObject.screenSize
      : "";
  };

  const getRefreshRate = () => {
    return data && data.MobileDetailInfo
      ? convertScreeDetailStringToObject.refreshRate
      : "";
  };

  const getScreenType = () => {
    return data && data.MobileDetailInfo
      ? convertScreeDetailStringToObject.screenType
      : "";
  };

  const getScreenResolution = () => {
    return data && data.MobileDetailInfo
      ? convertScreeDetailStringToObject.resolution
      : "";
  };

  const getScreenBrightness = () => {
    return data && data.MobileDetailInfo
      ? convertScreeDetailStringToObject.screenBrightness
      : "";
  };

  const getScreenProtection = () => {
    return data && data.MobileDetailInfo
      ? convertScreeDetailStringToObject.screenProtection
      : "";
  };

  const getMemory = () => {
    return data && data.MobileDetailInfo ? data.MobileDetailInfo.Memory : "";
  };

  const getRam = () => {
    return data && data.MobileDetailInfo ? data.MobileDetailInfo.Ram : "";
  };

  const getWeight = () => {
    return data && data.MobileDetailInfo
      ? `${data.MobileDetailInfo.Weight} g`
      : "";
  };

  const getColor = () => {
    return data && data.MobileDetailInfo ? data.MobileDetailInfo.Color : "";
  };

  const getBrand = () => {
    if (data && data.MobileDetailInfo) {
      return BrandEnumArray.find(
        (item) => item.value === data.MobileDetailInfo.BrandEnum,
      ).Name;
    }
    return "";
  };

  const onCopyProductId = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        enqueueSnackbar(t("linkCopied"), {
          variant: "success",
        });
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const convertTechnicalCharacteristicsDetailsStringToObject = useMemo(() => {
    const obj = {};

    if (data && data.MobileDetailInfo) {
      data.MobileDetailInfo.TechnicalCharacteristics.split("\n").forEach(
        (line) => {
          const [key, value] = line.split(":");
          if (key && value) {
            obj[key.trim()] = isNaN(value.trim())
              ? value.trim()
              : parseFloat(value.trim());
          }
        },
      );
    }

    return obj;
  }, [data.MobileDetailInfo]);

  const getTechnicalCharacteristicsDetails = (key) => {
    if (
      data &&
      data.MobileDetailInfo &&
      convertTechnicalCharacteristicsDetailsStringToObject[key]
    ) {
      return convertTechnicalCharacteristicsDetailsStringToObject[key];
    }
    return "";
  };

  const convertMemotyDetailsStringToObject = useMemo(() => {
    const obj = {};

    if (data && data.MobileDetailInfo) {
      data.MobileDetailInfo.MemoryInfo.split("\n").forEach((line) => {
        const [key, value] = line.split(":");
        if (key && value) {
          obj[key.trim()] = isNaN(value.trim())
            ? value.trim()
            : parseFloat(value.trim());
        }
      });
    }

    return obj;
  }, [data.MobileDetailInfo]);

  const getMemoryDetail = (key) => {
    if (
      data &&
      data.MobileDetailInfo &&
      convertMemotyDetailsStringToObject[key]
    ) {
      return convertMemotyDetailsStringToObject[key];
    }
    return "";
  };

  const convertCameraDetailsStringToObject = useMemo(() => {
    const obj = {};

    if (data && data.MobileDetailInfo) {
      data.MobileDetailInfo.CameraInfo.split("\n").forEach((line) => {
        const [key, value] = line.split(":");
        if (key && value) {
          obj[key.trim()] = isNaN(value.trim())
            ? value.trim()
            : parseFloat(value.trim());
        }
      });
    }

    return obj;
  }, [data.MobileDetailInfo]);

  const getCameraDetail = (key) => {
    if (
      data &&
      data.MobileDetailInfo &&
      convertCameraDetailsStringToObject[key]
    ) {
      return convertCameraDetailsStringToObject[key];
    }
    return "";
  };

  const convertElementDetailsStringToObject = useMemo(() => {
    const obj = {};

    if (data && data.MobileDetailInfo) {
      data.MobileDetailInfo.BatteryInfo.split("\n").forEach((line) => {
        const [key, value] = line.split(":");
        if (key && value) {
          obj[key.trim()] = isNaN(value.trim())
            ? value.trim()
            : parseFloat(value.trim());
        }
      });
    }

    return obj;
  }, [data.MobileDetailInfo]);

  const getElementDetails = (key) => {
    if (
      data &&
      data.MobileDetailInfo &&
      convertElementDetailsStringToObject[key]
    ) {
      return convertElementDetailsStringToObject[key];
    }
    return "";
  };

  const goLeft = () => {
    if (selectedImage === 1) {
      setSelectedImage(3);
    } else {
      setSelectedImage(selectedImage - 1);
    }
  };

  const goRight = () => {
    if (selectedImage === 3) {
      setSelectedImage(1);
    } else {
      setSelectedImage(selectedImage + 1);
    }
  };

  const getTechnicalCharacteristics = () => {
    return TECHNICAL_CHARACTERISTICS_LABELS.map((newKey, index) => ({
      [newKey]: getTechnicalCharacteristicsDetails(
        TECHNICAL_CHARACTERISTICS_KEYS[index],
      ),
    }));
  };

  const getBatteryDetails = () => {
    return BATTERY_DETAILS_LABELS.map((newKey, index) => ({
      [newKey]: getElementDetails(BATTERY_DETAILS_KEYS[index]),
    }));
  };

  const getMemoryDetails = () => {
    return MEMORY_DETAILS_LABELS.map((newKey, index) => ({
      [newKey]: getMemoryDetail(MEMORY_DETAILS_KEYS[index]),
    }));
  };

  const getCameraDetails = () => {
    return CAMERA_DETAILS_LABELS.map((newKey, index) => ({
      [newKey]: getCameraDetail(CAMERA_DETAILS_KEYS[index]),
    }));
  };

  const getScreenDetails = () => {
    return [
      { screenSize: getScreenSize() },
      { refreshRate: getRefreshRate() },
      { screenType: getScreenType() },
      { resolution: getScreenResolution() },
      { screenBrightness: getScreenBrightness() },
      { screenProtection: getScreenProtection() },
    ];
  };

  const getWeightDetails = () => {
    return [{ weight: getWeight() }];
  };

  const getBrandDetails = () => {
    return [{ brand: getBrand() }];
  };

  const getColorDetails = () => {
    return [{ color: getColor() }];
  };

  return (
    <Root>
      <Content>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <NavigateContainer>
            {isMobile ? (
              <>
                <NavigateBefore
                  onClick={() => navigate("/mobiles")}
                  sx={{ marginLeft: "-8px" }}
                />
                <NavigateItem>
                  {
                    BrandEnumArray.find(
                      (item) => item.value === data.ProductTypeEnum,
                    )?.Name
                  }
                </NavigateItem>
              </>
            ) : (
              <>
                <NavigateItem onClick={() => navigate("/")}>
                  {t("main")}
                </NavigateItem>
                <NavigateNextIcon />
                <NavigateItem onClick={() => navigate("/mobiles")}>
                  {t("mobiles")}
                </NavigateItem>
                <NavigateNextIcon />
                <NavigateItem>
                  {
                    BrandEnumArray.find(
                      (item) => item.value === data.ProductTypeEnum,
                    )?.Name
                  }
                </NavigateItem>
              </>
            )}
          </NavigateContainer>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              gap: "10px",
            }}
            sx={{
              color: "#1875CC",
            }}
            onClick={() => onCopyProductId()}
          >
            <ShareIcon
              style={{
                width: "20px",
                height: "20px",
              }}
            />
            <span>MobileStore-{productId}</span>
          </Box>
        </div>

        <Divider
          style={{
            margin: isMobileVersion ? "10px 0px" : "20px 0px",
          }}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          {loading ? (
            <Skeleton
              variant="rounded"
              style={{
                minWidth: "380px",
              }}
              width={"100%"}
              height={"380px"}
            />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: isMobile ? "auto" : "400px",
              }}
            >
              <div
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "auto",
                  fontWeight: "600",
                  fontSize: "16px",
                  maxWidth: "400px",
                }}
              >
                {data.Name} | {data.Description}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                {getFirstImage() && (
                  <img
                    width={300}
                    height={300}
                    src={
                      selectedImage === 1
                        ? getFirstImage()
                        : selectedImage === 2
                          ? getSecondImage()
                          : getThirdImage()
                    }
                    alt={"image"}
                  />
                )}

                <ButtonContainer
                  onClick={() => goLeft()}
                  style={{
                    left: "30px",
                  }}
                  id={"banner_left_arrow"}
                >
                  <ArrowBackIosNewIcon
                    style={{
                      color: "#1875CC",
                    }}
                  />
                </ButtonContainer>

                <ButtonContainer
                  onClick={() => goRight()}
                  style={{
                    right: "30px",
                  }}
                  id={"banner_left_arrow"}
                >
                  <ArrowForwardIosIcon
                    style={{
                      color: "#1875CC",
                    }}
                  />
                </ButtonContainer>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  justifyContent: "center",
                }}
              >
                <div>
                  {getFirstImage() && (
                    <StyledImageContainer
                      isSelected={selectedImage === 1}
                      onClick={() => setSelectedImage(1)}
                    >
                      <img
                        width={50}
                        height={50}
                        src={getFirstImage()}
                        alt={"image"}
                      />
                    </StyledImageContainer>
                  )}
                </div>
                <div>
                  {getSecondImage() && (
                    <StyledImageContainer
                      isSelected={selectedImage === 2}
                      onClick={() => setSelectedImage(2)}
                    >
                      <img
                        width={50}
                        height={50}
                        src={getSecondImage()}
                        alt={"image"}
                      />
                    </StyledImageContainer>
                  )}
                </div>
                <div>
                  {getThirdImage() && (
                    <StyledImageContainer
                      isSelected={selectedImage === 3}
                      onClick={() => setSelectedImage(3)}
                    >
                      <img
                        width={50}
                        height={50}
                        src={getThirdImage()}
                        alt={"image"}
                      />
                    </StyledImageContainer>
                  )}
                </div>
              </div>
            </div>
          )}
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "20px",
              }}
            >
              <Skeleton
                variant="rounded"
                style={{
                  minWidth: "250px",
                }}
                width={"100%"}
                height={"20px"}
              />
              <Skeleton
                variant="rounded"
                style={{
                  minWidth: "230px",
                }}
                width={"100%"}
                height={"20px"}
              />
              <Skeleton
                variant="rounded"
                style={{
                  minWidth: "230px",
                }}
                width={"100%"}
                height={"20px"}
              />
              <Skeleton
                variant="rounded"
                style={{
                  minWidth: "230px",
                }}
                width={"100%"}
                height={"20px"}
              />
              <Skeleton
                variant="rounded"
                style={{
                  minWidth: "230px",
                }}
                width={"100%"}
                height={"20px"}
              />
              <Skeleton
                variant="rounded"
                style={{
                  minWidth: "230px",
                }}
                width={"100%"}
                height={"20px"}
              />
              <Skeleton
                variant="rounded"
                style={{
                  minWidth: "230px",
                }}
                width={"100%"}
                height={"20px"}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: isMobile ? "100%" : "250px",
                gap: "20px",
              }}
            >
              <ProductMainInfoItem>
                <ProductMainInfoItemKey>
                  {t("screenSize")}
                </ProductMainInfoItemKey>
                <ProductMainInfoItemValue>
                  {getScreenSize()}
                </ProductMainInfoItemValue>
              </ProductMainInfoItem>
              <ProductMainInfoItem>
                <ProductMainInfoItemKey>
                  {t("refreshRate")}
                </ProductMainInfoItemKey>
                <ProductMainInfoItemValue>
                  {getRefreshRate()}
                </ProductMainInfoItemValue>
              </ProductMainInfoItem>
              <ProductMainInfoItem>
                <ProductMainInfoItemKey>
                  {t("screenType")}
                </ProductMainInfoItemKey>
                <ProductMainInfoItemValue>
                  {getScreenType()}
                </ProductMainInfoItemValue>
              </ProductMainInfoItem>
              <ProductMainInfoItem>
                <ProductMainInfoItemKey>{t("memory")}</ProductMainInfoItemKey>
                <ProductMainInfoItemValue>
                  {getMemory()}
                </ProductMainInfoItemValue>
              </ProductMainInfoItem>
              <ProductMainInfoItem>
                <ProductMainInfoItemKey>{t("ram")}</ProductMainInfoItemKey>
                <ProductMainInfoItemValue>{getRam()}</ProductMainInfoItemValue>
              </ProductMainInfoItem>
              <ProductMainInfoItem>
                <ProductMainInfoItemKey>{t("weight")}</ProductMainInfoItemKey>
                <ProductMainInfoItemValue>
                  {getWeight()}
                </ProductMainInfoItemValue>
              </ProductMainInfoItem>
              <ProductMainInfoItem>
                <ProductMainInfoItemKey>{t("color")}</ProductMainInfoItemKey>
                <ProductMainInfoItemValue>
                  {getColor()}
                </ProductMainInfoItemValue>
              </ProductMainInfoItem>
            </div>
          )}
        </div>
        <Divider
          style={{
            margin: "20px 0px",
          }}
        />

        <div
          style={{
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "20px",
          }}
        >
          {t("additionalFeatures")}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            marginTop: "20px",
          }}
        >
          {/* #region Color Details */}
          <CustomAccordion
            title={"color"}
            keyValueTypeData={getColorDetails()}
            isExpand={true}
          />
          {/* #endregion */}

          {/* #region Brand Details */}
          <CustomAccordion
            title={"brand"}
            keyValueTypeData={getBrandDetails()}
            isExpand={true}
          />
          {/* #endregion */}

          {/* #region Technical Characteristics Details */}
          <CustomAccordion
            title={"technicalCharacteristics"}
            keyValueTypeData={getTechnicalCharacteristics()}
            isExpand={true}
          />
          {/* #endregion */}

          {/* #region Weight Details */}
          <CustomAccordion
            title={"weight"}
            keyValueTypeData={getWeightDetails()}
            isExpand={true}
          />
          {/* #endregion */}

          {/* #region Screen Details */}
          <CustomAccordion
            title={"screen"}
            keyValueTypeData={getScreenDetails()}
            isExpand={true}
          />
          {/* #endregion */}

          {/* #region Memory Details */}
          <CustomAccordion
            title={"memory"}
            keyValueTypeData={getMemoryDetails()}
            isExpand={true}
          />
          {/* #endregion */}

          {/* #region Camera Details */}
          <CustomAccordion
            title={"camera"}
            keyValueTypeData={getCameraDetails()}
            isExpand={true}
          />
          {/* #endregion */}

          {/* #region Element Details */}
          <CustomAccordion
            title={"battery"}
            keyValueTypeData={getBatteryDetails()}
            isExpand={true}
          />
          {/* #endregion */}
        </div>
      </Content>
      {isMobile ? (
        <ProductItemPageFooter />
      ) : (
        <Content>
          <ByItemContainer>
            <ByItemContainerTitle>
              <Price>{data && `${data.Price} ₾`}</Price>
              <PriceWithBank>
                {t("fromMonthPrice")} <span>1000 ₾</span>
              </PriceWithBank>
            </ByItemContainerTitle>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px",
                gap: "10px",
              }}
            >
              <BuyButton>
                <ShoppingBasketIcon /> {t("buy")}
              </BuyButton>
              <BuyButton>
                <ShoppingCartIcon /> {t("addToCart")}
              </BuyButton>
            </div>
          </ByItemContainer>
        </Content>
      )}
    </Root>
  );
};

export default MobileItemPage;
