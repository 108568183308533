import Box from "@mui/material/Box";
import {
  Button,
  CircularProgress,
  Drawer,
  Grid,
  MenuItem,
  Popover,
  Slide,
  Slider,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import {
  BackIcon,
  DeleteIcon,
  FilterIcon,
  ViewGridIconPrimary,
  ViewGridIconSecondary,
} from "../../svgIcons";
import React, { useEffect, useState } from "react";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import CategoryItem from "../CategorySwiper/CategoryItem";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SeeMoreButton from "../Buttons/SeeMoreButton";
import { isMobile } from "react-device-detect";
import CloseIcon from "@mui/icons-material/Close";

const StyledRoot = styled(Box)(() => ({}));

const StyledHeader = styled(Box)(({ isMobileVersion }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: isMobileVersion ? "10px" : "30px",
  borderBottom: "2px solid #f2f2f2",
}));

const StyledFilterBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  flex: 1 / 2,
}));

const StyledContent = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
}));

const StyledProductTypeName = styled(Typography)(() => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "10px",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "19px",
}));

const StyledSortButton = styled(Button)(() => ({
  width: "130px",
  textTransform: "none",
  justifyContent: "space-between",
  display: "flex",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "14px",
  padding: "0px 10px",
  backgroundColor: "#f5f5f5",
  borderRadius: "20px",
  color: "#000000",
  "&:hover": {
    backgroundColor: "#ebebeb",
  },
  "& svg": {
    marginLeft: "10px",
  },
}));

const StyledFilterButton = styled(StyledSortButton)({});

const StyledFilterBoxHeader = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  paddingBottom: "10px",
  borderBottom: "2px solid #f2f2f2",
}));

const StyledRangeSliderWrapper = styled(Box)(() => ({
  padding: "20px 10px",
}));

const StyledMinMaxAdornment = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: isMobile ? "14px" : "16px",
  lineHeight: "20px",
  opacity: 0.6,
  color: "#000",
}));

const StyledPriceField = styled(TextField)(() => ({
  "& .MuiInputBase-input": {
    textAlign: "right",
    paddingRight: "5px",
    fontSize: isMobile && "18px",
  },
  "& .MuiInputBase-root": {
    height: "36px",
    paddingLeft: isMobile && "6px",
    paddingRight: isMobile && "6px",
  },
  "& fieldset": {
    border: "1px solid rgb(102, 179, 255) !important",
    "&:hover": {
      border: "1px solid rgb(102, 179, 255) !important",
    },
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid rgb(102, 179, 255) !important",
  },
}));

const StyledFilterClearButton = styled(Button)(() => ({
  borderRadius: "15px",
  display: "flex",
  gap: "5px",
  fontSize: isMobile && "10px",
}));

const StyledSeeMoreContainer = styled(Box)(() => ({
  margin: "10px 0px",
  display: "flex",
  justifyContent: "center",
}));

const RangeSlider = ({ min, max, changePriceCallBack }) => {
  const { t } = useTranslation();
  const [hide, setHide] = useState(false);
  const [priceRange, setPriceRange] = useState([min, max]);

  const handleSliderChange = (event, newValue) => {
    setPriceRange(newValue);
    changePriceCallBack(newValue);
  };

  const handleFieldChange = (value, type) => {
    switch (type) {
      case "MIN":
        setPriceRange([value, priceRange[1]]);
        changePriceCallBack([value, priceRange[1]]);
        break;
      case "MAX":
        setPriceRange([priceRange[0], value]);
        changePriceCallBack([priceRange[0], value]);
        break;
      default:
        return;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Typography>{t("price")}</Typography>
        <ExpandMoreOutlinedIcon
          onClick={() => setHide(!hide)}
          sx={{
            cursor: "pointer",
            transform: hide ? "rotate(180deg)" : "",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </Box>
      {!hide && (
        <>
          <Slider
            getAriaLabel={() => "Temperature range"}
            value={priceRange}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            max={max}
          />
          <Box sx={{ display: "flex", gap: "20px" }}>
            <PriceField
              label={"MIN"}
              value={priceRange[0]}
              onChange={handleFieldChange}
            />
            <PriceField
              label={"MAX"}
              value={priceRange[1]}
              onChange={handleFieldChange}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

const PriceField = ({ label, value, onChange }) => {
  const [price, setPrice] = useState(value);

  useEffect(() => {
    setPrice(value);
  }, [value]);

  const handleChange = (e) => {
    let price = Number(e.target.value);
    setPrice(price);
    onChange(price, label);
  };

  return (
    <StyledPriceField
      value={price}
      onChange={handleChange}
      InputProps={{
        startAdornment: (
          <StyledMinMaxAdornment position="start">
            {label}:
          </StyledMinMaxAdornment>
        ),
        endAdornment: (
          <Typography
            sx={{ fontSize: isMobile ? "14px" : "18px", fontWeight: 400 }}
          >
            ₾
          </Typography>
        ),
      }}
    />
  );
};

const StyledPopoverMenuItem = styled(MenuItem)({
  fontWeight: 500,
  fontSize: "13px",
  color: "#000",
  opacity: 0.6,
  transition: "0.1s",
  lineHeight: "14px",
  padding: "4px 15px 4px 15px",
  "&:hover": {
    background: "unset",
    opacity: 1,
  },
});

const ProductsGrid = ({
  children,
  title,
  minAmount,
  maxAmount,
  loading,
  data,
  total = 0,
  seeMoreCallBack = () => {},
  changePriceCallBack = () => {},
  isMobileVersion,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [openFilterSlide, setOpenFilterSlide] = useState(false);
  const [viewGridAs, setViewGridAs] = useState({ row: false, column: true });
  const [sortBtnAnchorEl, setSortBtnAnchorEl] = useState(null);
  const openSortBtnPopover = Boolean(sortBtnAnchorEl);

  const handleSortBtnClick = (event) => {
    setSortBtnAnchorEl(event.currentTarget);
  };

  const handleSortBtnPopClose = () => {
    setSortBtnAnchorEl(null);
  };

  const handleViewGrid = (key) => {
    if (viewGridAs[key]) return;

    setViewGridAs({
      row: key === "row",
      column: key === "column",
    });
  };

  const handleToggleFilterSlide = () => {
    setOpenFilterSlide((prev) => !prev);
  };

  const renderFilterBox = () => {
    return (
      <>
        {isMobile ? (
          <Drawer
            anchor="right"
            open={openFilterSlide}
            onClose={handleToggleFilterSlide}
            sx={{
              "& .MuiDrawer-paper": {
                width: "80%", // Set full width for the drawer
              },
            }}
          >
            <Slide
              direction="left"
              in={openFilterSlide}
              mountOnEnter
              unmountOnExit
            >
              <div style={{ padding: "10px 15px" }}>{getFilterBox()}</div>
            </Slide>
          </Drawer>
        ) : (
          getFilterBox()
        )}
      </>
    );
  };

  const getFilterBox = () => {
    return (
      <StyledFilterBox>
        <StyledFilterBoxHeader>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isMobile && <CloseIcon onClick={handleToggleFilterSlide} />}
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: 600,
                fontSize: isMobile ? "12px" : "16px",
                lineHeight: "19px",
                letterSpacing: "0.5px",
              }}
            >
              {t("filter")}
            </Typography>
          </Box>
          <StyledFilterClearButton>
            <DeleteIcon /> {t("clear")}
          </StyledFilterClearButton>
        </StyledFilterBoxHeader>
        <StyledRangeSliderWrapper>
          <RangeSlider
            min={minAmount}
            max={maxAmount}
            changePriceCallBack={changePriceCallBack}
          />
        </StyledRangeSliderWrapper>
        {children}
      </StyledFilterBox>
    );
  };

  return (
    <StyledRoot>
      <StyledHeader isMobileVersion={isMobileVersion}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <BackIcon onclick={() => navigate("/category")} />
          {isMobile ? null : (
            <StyledProductTypeName>{title}</StyledProductTypeName>
          )}
        </Box>
        <Box sx={{ display: "flex", gap: isMobile ? "5px" : "20px" }}>
          <>
            {isMobile && (
              <StyledFilterButton
                startIcon={<FilterIcon />}
                onClick={handleToggleFilterSlide}
              >
                {t("filter")}
              </StyledFilterButton>
            )}
            <StyledSortButton onClick={handleSortBtnClick}>
              {t("sort-template")}
              <ExpandMoreOutlinedIcon
                sx={{
                  transform: openSortBtnPopover ? "rotate(180deg)" : "",
                  transition: "transform 0.3s ease-in-out",
                }}
              />
            </StyledSortButton>
            <Popover
              open={openSortBtnPopover}
              anchorEl={sortBtnAnchorEl}
              onClose={handleSortBtnPopClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box
                sx={{
                  padding: "5px",
                }}
              >
                <StyledPopoverMenuItem>
                  {t("priceDecreasing")}
                </StyledPopoverMenuItem>
                <StyledPopoverMenuItem>
                  {t("priceIncreases")}
                </StyledPopoverMenuItem>
              </Box>
            </Popover>
          </>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: isMobile ? "5px" : "15px",
            }}
          >
            <ViewGridIconPrimary
              onClick={() => handleViewGrid("column")}
              style={{
                cursor: "pointer",
                stroke: viewGridAs.column ? "#1976D2" : "",
              }}
            />
            <ViewGridIconSecondary
              onClick={() => handleViewGrid("row")}
              style={{
                cursor: "pointer",
                stroke: viewGridAs.row ? "#1976D2" : "",
              }}
            />
          </Box>
        </Box>
      </StyledHeader>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginTop: "20px",
          gap: "20px",
          // height: "calc(100vh - 549px)",
        }}
      >
        {renderFilterBox()}
        <StyledContent
          style={{
            position: "relative",
            minHeight: loading ? (isMobileVersion ? "250px" : "500px") : "",
          }}
        >
          {loading && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                zIndex: 999,
                display: "flex",
                justifyContent: "center",
                top: "100px",
              }}
            >
              <CircularProgress size={50} />
            </div>
          )}
          <Box
            style={{
              opacity: loading ? 0.6 : 1,
              transform: "opacity 0.3s ease-in-out",
            }}
          >
            {!loading && data.length === 0 && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={"assets/no-product-find.jpg"}
                  alt={"product can not find"}
                  style={{
                    maxWidth: "50%",
                  }}
                />
                <span>{t("productCanNotFind")}</span>
              </div>
            )}
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              sx={{ marginBottom: "40px" }}
            >
              {data.map((item, index) => (
                <Grid
                  item
                  xs={2}
                  sm={4}
                  md={viewGridAs.row ? 12 : 4}
                  key={index}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <CategoryItem
                    viewMode={viewGridAs.row ? "row" : "column"}
                    categoryInfo={item}
                    // cardContainerRef={cardContainerRef}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <StyledSeeMoreContainer>
            {!loading && data.length > 0 && (
              <SeeMoreButton
                loading={false}
                currentAmount={data.length}
                totalAmount={total}
                onClick={() => seeMoreCallBack && seeMoreCallBack()}
              />
            )}
          </StyledSeeMoreContainer>
        </StyledContent>
      </Box>
    </StyledRoot>
  );
};

export default ProductsGrid;
