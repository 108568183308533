import { keyframes, styled } from "@mui/material";
import { useState } from "react";

const impulse = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
`;

const Root = styled("div")(({ theme, isClicked }) => ({
  borderRadius: "100%",
  background: "transparent",
  display: "flex",
  alignItems: "center",
  transition: "background 0.3s ease-in-out",
  justifyContent: "center",
  width: "35px",
  height: "30px",
  "&:hover": {
    background: "rgba(0, 0, 0, 0.04)",
  },
  ...(isClicked && {
    animation: `${impulse} 0.5s ease-in-out`,
  }),
}));

const PasswordTextFieldIconContainer = ({ children }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 500); // Reset animation state after 500ms
  };
  return (
    <Root isClicked={isClicked} onClick={handleClick}>
      {children}
    </Root>
  );
};

export default PasswordTextFieldIconContainer;
