import * as i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ge from "./ge.json";
import en from "./en.json";
import ru from "./ru.json";
import { getUserLanguage } from "../util/appUtil";

const resources = {
  ge: {
    translation: ge,
  },
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: getUserLanguage(),
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
