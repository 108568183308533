import {
  CHANGE_SHARE_MODAL_INFO,
  CLOSE_AUTH_MODAL,
  LOG_OUT,
  OPEN_LOGIN_MODAL,
  OPEN_REGISTRATION_MODAL,
  UPDATE_COIN_COUNT,
  UPDATE_COOKIE_FILE_IS_OK,
  UPDATE_MAINTENANCE_MODAL_OPEN,
  UPDATE_TICKET_COUNT,
  UPDATE_WHEEL_PRIZES,
  USER_AUTHORIZATION,
} from "../constants/mainConstants";
import { getLocalStorageValue, setLocalStorageValue } from "../../util/appUtil";

const initialState = {
  isUserAuthorized: false,
  userInfo: {
    firstName: null,
    secondName: null,
    birthdayDate: null,
    userImage: null,
    fileFormat: null,
    points: null,
    tickets: null,
    promoCode: null,
    promoUserCount: null,
    qrCode: null,
    authenticationMethod: null,
    isFullyRegistered: null,
  },
  isLoginOpen: false,
  isRegisterOpen: false,
  isCookieFileOk: getLocalStorageValue("isCookieFileOk", false),
  shareModalInfo: null,
  wheelPrizes: [],
  isMaintenanceModalOpen: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_LOGIN_MODAL:
      return {
        ...state,
        isLoginOpen: action.payload,
        isRegisterOpen: false,
      };
    case OPEN_REGISTRATION_MODAL:
      return {
        ...state,
        isLoginOpen: false,
        isRegisterOpen: action.payload,
      };
    case CLOSE_AUTH_MODAL:
      return {
        ...state,
        isLoginOpen: false,
        isRegisterOpen: false,
      };
    case USER_AUTHORIZATION:
      setLocalStorageValue("isUserAuthorized", true);
      return {
        ...state,
        isUserAuthorized: true,
        userInfo: {
          ...state.userInfo,
          ...action.payload,
        },
      };
    case LOG_OUT:
      setLocalStorageValue("isUserAuthorized", false);
      return {
        ...state,
        isUserAuthorized: false,
        userInfo: {},
      };
    case UPDATE_COOKIE_FILE_IS_OK:
      setLocalStorageValue("isCookieFileOk", action.payload);
      return {
        ...state,
        isCookieFileOk: action.payload,
      };
    case CHANGE_SHARE_MODAL_INFO:
      return {
        ...state,
        shareModalInfo: action.payload,
      };
    case UPDATE_WHEEL_PRIZES:
      return {
        ...state,
        wheelPrizes: action.payload,
      };
    case UPDATE_TICKET_COUNT: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          tickets: state.userInfo.tickets + action.payload,
        },
      };
    }
    case UPDATE_COIN_COUNT: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          points: state.userInfo.points + action.payload,
        },
      };
    }
    case UPDATE_MAINTENANCE_MODAL_OPEN: {
      return {
        ...state,
        isMaintenanceModalOpen: action.payload,
      };
    }
    default:
      return state;
  }
}
