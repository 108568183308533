import Box from "@mui/material/Box";
import { styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BasketWithPlusIcon } from "../../../svgIcons";

const StyledFooter = styled(Box)({
  width: "100%",
  display: "flex",
  // justifyContent: "flex-end",
  position: "fixed",
  left: 0,
  right: 0,
  bottom: 68,
  backgroundColor: "#1b82e6",
  padding: "5px 7px",
  alignItems: "center",
});

const BuyButton = styled("div")(() => ({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  minWidth: "0px",
  background: "#FFF",
  userSelect: "none",
  cursor: "pointer",
  borderRadius: "4px",
  color: "#007ef5",
  fontSize: "14px",
  fontWeight: 600,
  width: "100%",
  gap: "10px",
  height: "35px",
}));

const ProductItemPageFooter = ({ price = 1299, onClickBuy, onClickAdd }) => {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <Box>
        <Typography
          sx={{ fontSize: "20px", fontWeight: 700, color: "#FFFFFF" }}
        >
          {price} ₾
        </Typography>
      </Box>
      <Box marginLeft="auto" display="flex" gap="10px" marginLeft="auto">
        <Box>
          <BuyButton onClick={onClickAdd}>
            <BasketWithPlusIcon />
          </BuyButton>
        </Box>
        <BuyButton onClick={onClickBuy}>{t("buy")}</BuyButton>
      </Box>
    </StyledFooter>
  );
};

export default ProductItemPageFooter;
