import { Button, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BackIcon2 } from "../../svgIcons";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(Button)({
  textTransform: "capitalize",
  height: "30px",
  color: "#000000c7",
  border: "1px solid #57637530",
  fontSize: "13px",
  "&:hover": {
    border: "1px solid #57637530",
    backgroundColor: "rgba(0,0,0,0.03)",
  },
  "&:active": {
    // backgroundColor: "transparent !important", // Remove background on click
    // boxShadow: "none !important", // Optional: Remove any shadow effect
  },
  "& .MuiTouchRipple-ripple": {
    backgroundColor: "rgba(255, 0, 0, 0.5) !important", // Change ripple color
  },
});

const ContinueShoppingButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledButton
      variant="outlined"
      disableRipple
      startIcon={<BackIcon2 style={{ width: 22, height: 22 }} />}
      onClick={() => navigate("/")}
    >
      {`${t("continue")} ${t("shopping")}`}
    </StyledButton>
  );
};

export default ContinueShoppingButton;
