import Box from "@mui/material/Box";
import { IconButton, styled, useMediaQuery } from "@mui/material";
import PrimaryButton from "./Buttons/CustomButton";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { cookieFileIsOk } from "../redux/actions/mainAction";
import { bindActionCreators } from "redux";
import { useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  position: "fixed",
  left: 0,
  right: 0,
  bottom: 20,
  zIndex: 999,
  height: "auto",
}));

const Banner = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  flexDirection: "row",
  backgroundColor: "rgb(18, 87, 153,0.8)",
  height: "auto",
  width: "800px",
  margin: "auto 25%",
  borderRadius: "10px",
  padding: "24px",
  marginRight: "auto",
  marginLeft: "auto",
  backdropFilter: "blur(2px)",
  [theme.breakpoints.down("1000")]: {
    marginLeft: "100px",
    marginRight: "100px",
    padding: "16px",
    flexDirection: "column",
    gap: "4px",
  },
  [theme.breakpoints.down("600")]: {
    marginLeft: "20px",
    marginRight: "20px",
    padding: "16px",
    flexDirection: "column",
    gap: "4px",
  },
}));

const HeaderText = styled("p")(({ theme }) => ({
  fontSize: "17.5px",
  fontWeight: 600,
  color: "#FFF",
  [theme.breakpoints.down("1000")]: {
    display: "none",
  },
}));

const MainText = styled("p")(({ theme }) => ({
  paddingTop: "10px",
  fontSize: "12px",
  lineHeight: "17.5px",
  color: "#FFF",
  paddingRight: "8px",
  [theme.breakpoints.down("1000")]: {
    fontSize: "10px",
    paddingTop: "0px",
  },
}));

const Hyperlink = styled("a")(({ theme }) => ({
  color: "#FFF",
}));

const IconBtn = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: -10,
  right: -10,
  background: "#3281FE",
  width: "26px",
  height: "26px",
  border: "1px solid #A1D4FF",
  transition: "0.3s ease",
  "&:hover": {
    background: "#3C8BFF",
    transform: "scale(1.1)",
  },
  "& svg": {
    color: "#A1D4FF",
    fontSize: "16px",
  },
  [theme.breakpoints.down("1000")]: {
    width: "21px",
    height: "21px",
    top: -8,
    right: -8,
    "& svg": {
      fontSize: "12px",
    },
  },
}));

const CookieComponent = ({ isCookieFileOk, updateCookieIsOk }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isClose, setIsClose] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("1000"));

  const primaryBtnStyles = useMemo(() => {
    return {
      width: isSmallScreen && "100px",
      height: isSmallScreen && "36px",
    };
  }, [isSmallScreen]);

  const primaryBtnTextStyles = useMemo(() => {
    return {
      fontSize: isSmallScreen && "13px",
    };
  }, [isSmallScreen]);

  if (!isCookieFileOk && !isClose) {
    return (
      <Root>
        <Banner>
          <Box flex={1}>
            <HeaderText>{t("cookiePolitic")}</HeaderText>
            <MainText>
              {t("cookiePolicy")} &nbsp;
              <Hyperlink href="/terms-and-conditions">
                {t("confidentialityPolicy")}
              </Hyperlink>
            </MainText>
          </Box>
          <Box
            display={"flex"}
            flex={1 / 4}
            alignItems={"center"}
            justifyContent={"end"}
          >
            <PrimaryButton
              textStyles={primaryBtnTextStyles}
              buttonStyles={primaryBtnStyles}
              onClick={() => {
                updateCookieIsOk(true);
                setIsClose(true);
              }}
              text={t("itIsClear")}
            />
          </Box>
          <IconBtn
            onClick={() => {
              setIsClose(true);
            }}
          >
            <CloseIcon />
          </IconBtn>
        </Banner>
      </Root>
    );
  }
};

const mapStateToProps = (state) => ({
  isCookieFileOk: state.main.isCookieFileOk,
});

const dispatchToProps = (dispatch) => ({
  updateCookieIsOk: bindActionCreators(cookieFileIsOk, dispatch),
});

export default connect(mapStateToProps, dispatchToProps)(CookieComponent);
