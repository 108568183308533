const MenuAudioIcon = () => {
  return (
    <svg
      width="64px"
      height="64px"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <defs></defs>
        <title></title>
        <g data-name="Layer 23" id="Layer_23">
          <rect
            className="cls-1"
            height="35.28"
            rx="5.79"
            ry="5.79"
            width="15.25"
            x="24.28"
            y="1.79"
          ></rect>
          <path
            className="cls-1"
            d="M13.07,26.62a18.93,18.93,0,0,0,37.86,0l-5,.28a13.9,13.9,0,0,1-27.8,0Z"
          ></path>
          <line className="cls-1" x1="32" x2="32" y1="45.55" y2="62.67"></line>
          <line
            className="cls-1"
            x1="22.87"
            x2="40.85"
            y1="62.67"
            y2="62.67"
          ></line>
          <line
            className="cls-1"
            x1="24.28"
            x2="28.42"
            y1="16.17"
            y2="16.17"
          ></line>
          <line
            className="cls-1"
            x1="24.28"
            x2="28.42"
            y1="23.17"
            y2="23.17"
          ></line>
          <line
            className="cls-1"
            x1="24.28"
            x2="28.42"
            y1="30.17"
            y2="30.17"
          ></line>
          <line
            className="cls-1"
            x1="35.28"
            x2="39.42"
            y1="16.17"
            y2="16.17"
          ></line>
          <line
            className="cls-1"
            x1="35.28"
            x2="39.42"
            y1="23.17"
            y2="23.17"
          ></line>
          <line
            className="cls-1"
            x1="35.28"
            x2="39.42"
            y1="30.17"
            y2="30.17"
          ></line>
          <line
            className="cls-1"
            x1="29.42"
            x2="29.42"
            y1="2.79"
            y2="7.92"
          ></line>
          <line
            className="cls-1"
            x1="34.42"
            x2="34.42"
            y1="2.79"
            y2="7.92"
          ></line>
        </g>
      </g>
    </svg>
  );
};

export default MenuAudioIcon;
