import React, { useCallback, useRef } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100vw",
  height: "100vh",
  top: 0,
  left: 0,
  zIndex: 999999999,
};

const CustomConfetti = ({ buttonRef }) => {
  const animationInstance = useRef(null);

  const makeShot = useCallback((particleRatio, opts, origin) => {
    if (animationInstance.current) {
      animationInstance.current({
        ...opts,
        origin: origin,
        particleCount: Math.floor(200 * particleRatio),
      });
    }
  }, []);

  const fire = () => {
    // Fire from the left
    makeShot(0.25, { spread: 26, startVelocity: 55 }, { x: 0.1, y: 0.7 });
    makeShot(0.2, { spread: 60 }, { x: 0.1, y: 0.7 });
    makeShot(
      0.35,
      { spread: 100, decay: 0.91, scalar: 0.8 },
      { x: 0.1, y: 0.7 },
    );
    makeShot(
      0.1,
      { spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2 },
      { x: 0.1, y: 0.7 },
    );
    makeShot(0.1, { spread: 120, startVelocity: 45 }, { x: 0.1, y: 0.7 });

    // Fire from the right
    makeShot(0.25, { spread: 26, startVelocity: 55 }, { x: 0.9, y: 0.7 });
    makeShot(0.2, { spread: 60 }, { x: 0.9, y: 0.7 });
    makeShot(
      0.35,
      { spread: 100, decay: 0.91, scalar: 0.8 },
      { x: 0.9, y: 0.7 },
    );
    makeShot(
      0.1,
      { spread: 120, startVelocity: 25, decay: 0.92, scalar: 1.2 },
      { x: 0.9, y: 0.7 },
    );
    makeShot(0.1, { spread: 120, startVelocity: 45 }, { x: 0.9, y: 0.7 });
  };

  const getInstance = (instance) => {
    animationInstance.current = instance;
  };

  return (
    <>
      <button
        onClick={fire}
        style={{
          visibility: "hidden",
          display: "none",
        }}
        ref={buttonRef}
      >
        Fire
      </button>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    </>
  );
};

export default CustomConfetti;
