import { styled } from "@mui/material";
import PrimaryButton from "./Buttons/CustomButton";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import React, { useMemo } from "react";

const Root = styled("div")(({ image, isMobileVersion }) => ({
  position: "relative",
  width: "50%",
  padding: isMobileVersion ? "10px" : "28px",
  borderRadius: "10px",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "10px",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    zIndex: -1,
    transition: "transform 1s ease",
  },
  "&:hover::before": {
    transform: "scale(1.2)",
  },
}));

const HeaderText = styled("p")(({ theme, isMobileVersion }) => ({
  fontSize: isMobileVersion ? "11px" : "18px",
  color: "#FFF",
}));

const MainText = styled("p")(({ theme }) => ({
  fontSize: "15px",
  color: "#FFF",
}));

const SimpleBanner = ({ loading, data, index, isMobileVersion }) => {
  const { t } = useTranslation();

  const getInfo = useMemo(() => {
    const filteredData = data.filter((item) => item.IsMiniBanner);
    const result = {
      name: "",
      imageSrc: "",
    };

    if (filteredData && filteredData.length > 0) {
      result.name =
        filteredData.length > index
          ? filteredData[index].Name
          : filteredData[0].Name;

      result.imageSrc =
        filteredData.length > index
          ? `data:${filteredData[index].FileFormat};base64,${filteredData[index].Image}`
          : `data:${filteredData[0].FileFormat};base64,${filteredData[0].Image}`;
    }

    return result;
  }, [data, index]);

  return loading ? (
    <Skeleton variant="rounded" width={"287px"} height="156px" />
  ) : (
    <Root image={getInfo.imageSrc} isMobileVersion={isMobileVersion}>
      <HeaderText isMobileVersion={isMobileVersion}>{getInfo.name}</HeaderText>
      <PrimaryButton
        buttonStyles={{
          height: "36px",
          backgroundColor: "#FFF",
          borderRadius: "4px",
          width: "90px",
        }}
        textStyles={{ fontSize: "12px", color: "black" }}
        text={t("viewDetails")}
      />
    </Root>
  );
};

export default SimpleBanner;
