import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Autoplay, Pagination } from "swiper/modules";
import { useRef } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Skeleton from "@mui/material/Skeleton";

SwiperCore.use([Pagination, Autoplay]);

const StyledSwiper = styled(Swiper)(({ theme }) => ({
  maxWidth: "1308px",
  marginRight: "10px",
  marginLeft: "10px",
  width: "100%",
}));

const PaginationContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "8px",
  width: "100%",
  height: "30px",
  "& .swiper-pagination-bullet": {
    display: "inline-block",
    width: "8px",
    height: "8px",
    borderRadius: "100%",
    cursor: "pointer",
    background: "#1875CC",
    marginRight: "5px",
  },
  "& .swiper-pagination-bullet-active": {
    background: "#1875CC",
  },
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  padding: "6px",
  background: "#fff",
  borderRadius: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "background 0.3s ease-in-out, opacity 0.3s ease-in-out",
  cursor: "pointer",
  filter: "drop-shadow(rgba(0, 0, 0, 0.1) 0px 2px 10px)",
  gap: "10px",
  position: "absolute",
  zIndex: 2,
  opacity: 0,
  width: "32px",
  height: "32px",
  "&:hover": {
    background: "#f2f2f2",
  },
  "& svg": {
    width: "16px",
    height: "16px",
  },
}));

const Banner = ({ data, loading, isMobileVersion }) => {
  const swiperRef = useRef(null);
  const pagination = {
    clickable: true,
    el: ".custom-pagination",
  };
  const handleLeftButtonClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleRightButtonClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div
      style={{
        width: "100%",
        background: "transparent",
      }}
    >
      <Box
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
        sx={{
          "&:hover": {
            "& #banner_left_arrow": {
              opacity: 1,
            },
            "& #banner_right_arrow": {
              opacity: 1,
            },
          },
        }}
      >
        <ButtonContainer
          onClick={() => handleLeftButtonClick()}
          style={{
            left: "30px",
          }}
          id={"banner_left_arrow"}
        >
          <ArrowBackIosNewIcon
            style={{
              color: "#1875CC",
            }}
          />
        </ButtonContainer>
        <ButtonContainer
          onClick={() => handleRightButtonClick()}
          style={{
            right: 30,
          }}
          id={"banner_right_arrow"}
        >
          <ArrowForwardIosIcon
            style={{
              color: "#1875CC",
            }}
          />
        </ButtonContainer>
        {loading ? (
          <Box
            style={{
              display: "flex",
              gap: "24px",
              width: "100%",
              margin: "0px 10px",
            }}
          >
            <Box sx={{ width: "100%", aspectRatio: "16 / 7.23" }}>
              <Skeleton variant="rounded" width={"100%"} height="100%" />
            </Box>
            <Box sx={{ width: "100%", aspectRatio: "16 / 7.23" }}>
              <Skeleton variant="rounded" width={"100%"} height="100%" />
            </Box>
            <Box sx={{ width: "100%", aspectRatio: "16 / 7.23" }}>
              <Skeleton variant="rounded" width={"100%"} height="100%" />
            </Box>
          </Box>
        ) : (
          <StyledSwiper
            slidesPerView={isMobileVersion ? 2 : 3}
            spaceBetween={24}
            ref={swiperRef}
            pagination={pagination}
            loop={true}
            speed={1000}
            autoplay={{ delay: 7000 }}
            modules={[Pagination, Autoplay]}
          >
            {data.map((item, index) => {
              const imageSrc = `data:${item.FileFormat};base64,${item.Image}`;
              return (
                <SwiperSlide key={index}>
                  <img
                    onClick={() => window.open(item.Link, "_blank")}
                    src={imageSrc}
                    alt="logo"
                    style={{
                      width: "100%",
                      height: "100%",
                      maxHeight: "220px",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                  />
                </SwiperSlide>
              );
            })}
          </StyledSwiper>
        )}
      </Box>
      <PaginationContainer>
        <Box className="custom-pagination" width={"auto !important"}></Box>
      </PaginationContainer>
    </div>
  );
};

export default Banner;
