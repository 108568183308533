import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

const Root = styled(Box)(({ theme, disabled }) => ({
  width: "100%",
  '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
    {
      WebkitAppearance: "none",
      margin: 0,
    },
  display: "flex",
  alignItems: "center",
  "& .MuiTextField-root": {
    width: "100%",
    background: disabled ? "rgb(0,0,0,0.12)" : "rgb(0,0,0,0.06)",
    borderRadius: "8px",
    transition: "background 0.3s ease-in-out",
    "&:hover": {
      background: disabled ? "rgb(0,0,0,0.12)" : "rgb(0,0,0,0.09)",
    },
    "& fieldSet": {
      border: "unset",
    },
  },
}));

const CustomDateField = ({ onChange, disabled, value }) => {
  const [dateValue, setDateValue] = useState(null);

  useEffect(() => {
    if (value) {
      const parsedDate = dayjs(value);
      setDateValue(parsedDate);
    }
  }, [value]);
  return (
    <Root disabled={disabled}>
      <DatePicker
        value={dateValue}
        disabled={disabled}
        onChange={(newValue) => onChange(new Date(newValue))}
      />
    </Root>
  );
};

export default CustomDateField;
