import { Modal, styled } from "@mui/material";
import { isMobile } from "react-device-detect";
import { bindActionCreators } from "redux";
import { updateMaintenanceModalOpen } from "../redux/actions/mainAction";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const Root = styled("div")(({ theme }) => ({
  transform: "translate(-50%, -50%)",
  background: "#ffffff",
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "16px",
  padding: "20px",
  width: isMobile ? "90%" : 400,
  border: "1px solid #1E90FF",
  outline: "none !important",
}));

const MaintenanceModal = ({
  updateMaintenanceModal,
  isMaintenanceModalOpen,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={isMaintenanceModalOpen}
      onClose={() => updateMaintenanceModal(false)}
      sx={{
        "& .MuiBackdrop-root": {
          border: "none",
        },
        "& .MuiPaper-root": {
          border: "none",
        },
      }}
    >
      <Root>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "420",
            gap: "20px",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            {t("weAreInMaintenance")}
          </span>
          <span
            style={{
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            {t("weWillBackSoon")}
          </span>
          <img
            src={"assets/maintenance.png"}
            alt={"maintenance"}
            width={390}
            style={{
              minHeight: 270,
            }}
          />
        </div>
      </Root>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isMaintenanceModalOpen: state.main.isMaintenanceModalOpen,
});

const dispatchToProps = (dispatch) => ({
  updateMaintenanceModal: bindActionCreators(
    updateMaintenanceModalOpen,
    dispatch,
  ),
});

export default connect(mapStateToProps, dispatchToProps)(MaintenanceModal);
