import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { setLocalStorageValue } from "../../util/appUtil";

const ReferralUser = () => {
  const { referralUser } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (referralUser) {
      const result = {
        code: referralUser,
        date: Date.now(),
      };
      setLocalStorageValue("referral-code", JSON.stringify(result));
    }
    navigate("/");
  }, []);
  return <div>test</div>;
};

export default ReferralUser;
