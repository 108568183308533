import UserInfoPage from "../../components/UserInfo/UserInfoPage";
import { useEffect, useState } from "react";
import { GetUserFullInfo } from "../../api/services/Auth.api";
import { userAuthorization } from "../../redux/actions/mainAction";
import { useDispatch } from "react-redux";
import { GetCurrentUsersAllPrize } from "../../api/services/user.api";

const UserInfoContainer = ({ isMobileVersion }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingPrize, setLoadingPrize] = useState(false);
  const [myPrizes, setMyPrizes] = useState([]);

  useEffect(() => {
    setLoading(true);
    GetUserFullInfo()
      .then((resp) => {
        dispatch(userAuthorization(resp.data));
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });

    setLoadingPrize(true);
    GetCurrentUsersAllPrize()
      .then((resp) => {
        setMyPrizes(resp.data.data.value);
      })
      .finally(() => {
        setLoadingPrize(false);
      });
  }, []);
  return (
    <UserInfoPage
      mainLoading={loading}
      myPrizes={myPrizes}
      loadingPrize={loadingPrize}
      isMobileVersion={isMobileVersion}
    />
  );
};

export default UserInfoContainer;
