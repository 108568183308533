import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { LinkedInResponse } from "../../api/services/Auth.api";

const LinkedInAuthContainer = () => {
  const navigate = useNavigate();
  useEffect(() => {
    LinkedInResponse()
      .then(() => {})
      .finally(() => {
        navigate("/");
      });
  }, []);
  return <div></div>;
};

export default LinkedInAuthContainer;
