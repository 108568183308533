import { useTranslation } from "react-i18next";

const QRCodeDisplay = ({ qrCode }) => {
  const { t } = useTranslation();
  const qrCodeDataUrl = `data:image/png;base64,${qrCode}`;

  return (
    <div
      style={{
        width: "200px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2 style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        {t("yourQRCode")}
      </h2>
      <img style={{ width: "100%" }} src={qrCodeDataUrl} alt="QR Code" />
    </div>
  );
};

export default QRCodeDisplay;
