import { SnackbarProvider } from "notistack";

const SnackBar = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={5}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      autoHideDuration={3000}
      style={{
        fontWeight: 600,
        fontSize: "12px",
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default SnackBar;
