import { Grid, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import FacebookFooterIcon from "../../api/ui/svgIcons/FacebookFooterIcon";
import InstagramFooterIcon from "../../api/ui/svgIcons/InstagramFooterIcon";
import TiktokFooterIcon from "../../api/ui/svgIcons/TiktokFooterIcon";
import TwitterFooterIcon from "../../api/ui/svgIcons/TwitterFooterIcon";
import EmailFooterIcon from "../../api/ui/svgIcons/EmailFooterIcon";
import PhoneFooterIcon from "../../api/ui/svgIcons/PhoneFooterIcon";
import BranchFooterIcon from "../../api/ui/svgIcons/BranchFooterIcon";

const Root = styled("div")(({ theme }) => ({
  width: "100%",
  // height: "270px",
  display: "flex",
  flexDirection: "column",
  background: "#E5F5FF",
  marginTop: "30px",
  justifyContent: "center",
  alignItems: "center",
}));

const MainGridContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  maxWidth: "1200px",
  display: "flex",
  boxSizing: "border-box",
  justifyContent: "space-between",
  padding: "20px 0px",
}));

const MainGridItemContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
}));

const MainHeader = styled("div")(({ theme }) => ({
  color: "rgba(0,0,0,.8)",
  fontSize: "12px",
  fontWeight: 700,
  paddingBottom: "15px",
  borderBottom: "1px solid #1B82E6",
}));

const MainItemText = styled("div")(({ theme }) => ({
  color: "rgba(0,0,0,.8)",
  fontSize: "12px",
  fontWeight: 500,
  cursor: "pointer",
  transition: "transform 0.3s ease-in-out",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const CopyRightContainer = styled("div")(({ theme }) => ({
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  color: "rgba(0,0,0,.6)",
  fontWeight: 400,
  height: "35px",
  maxWidth: "1200px",
  width: "100%",
}));

const HorizontalLine = styled("div")(({ theme }) => ({
  height: "1px",
  width: "100%",
  background: "#fff",
}));

const IconContainer = styled("div")(({ theme }) => ({
  width: "20px",
  height: "20px",
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#1B82E6",
  marginRight: "10px",
  "& svg": {
    color: "#fff",
    fill: "#fff",
    padding: "2px",
  },
}));

const Footer = ({ isMobileVersion, footerRef }) => {
  const { t } = useTranslation();
  return (
    <Root
      style={{
        minHeight: isMobileVersion ? "" : "",
        padding: isMobileVersion ? "0px 10px 40px 10px" : "0px 30px",
      }}
      ref={footerRef}
    >
      <MainGridContainer
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 12, sm: 12, md: 12 }}
      >
        <MainGridItemContainer item xs={6} sm={6} md={2}>
          <MainHeader>{t("navigation")}</MainHeader>
          <MainItemText>{t("aboutUs")}</MainItemText>
          <MainItemText>{t("termsAndConditions")}</MainItemText>
          <MainItemText>{t("deliveryService")}</MainItemText>
          <MainItemText>{t("career")}</MainItemText>
        </MainGridItemContainer>
        <MainGridItemContainer item xs={6} sm={6} md={2}>
          <MainHeader>{t("payment")}</MainHeader>
          <MainItemText>{t("paymentMethods")}</MainItemText>
          <MainItemText>{t("warranty")}</MainItemText>
          <MainItemText>{t("returnPolicy")}</MainItemText>
          <MainItemText>{t("howToBuyOnline")}</MainItemText>
        </MainGridItemContainer>
        <MainGridItemContainer item xs={6} sm={6} md={2}>
          <MainHeader>{t("followUs")}</MainHeader>
          <MainItemText>
            <IconContainer>
              <FacebookFooterIcon />
            </IconContainer>{" "}
            Facebook
          </MainItemText>
          <MainItemText>
            <IconContainer>
              <InstagramFooterIcon />
            </IconContainer>{" "}
            Instagram
          </MainItemText>
          <MainItemText>
            <IconContainer>
              <TiktokFooterIcon />
            </IconContainer>{" "}
            Tiktok
          </MainItemText>
          <MainItemText>
            <IconContainer>
              <TwitterFooterIcon />
            </IconContainer>
            Twitter
          </MainItemText>
        </MainGridItemContainer>
        <MainGridItemContainer item xs={6} sm={6} md={2}>
          <MainHeader>{t("contacts")}</MainHeader>
          <MainItemText>
            <IconContainer>
              <EmailFooterIcon />
            </IconContainer>
            MobileStore@gmail.com
          </MainItemText>
          <MainItemText>
            <IconContainer>
              <PhoneFooterIcon />
            </IconContainer>{" "}
            +995 599 00 00 00
          </MainItemText>
          <MainItemText>
            <IconContainer>
              <BranchFooterIcon />
            </IconContainer>
            {t("branches")}
          </MainItemText>
        </MainGridItemContainer>
      </MainGridContainer>
      <HorizontalLine />
      <CopyRightContainer>
        Copyright © 2024 MobileStore.ge All rights reserved.
      </CopyRightContainer>
    </Root>
  );
};

export default Footer;
