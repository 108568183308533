import { styled } from "@mui/material";
import { isMobile } from "react-device-detect";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { SpinWheel } from "../../api/services/wheel.api";
import {
  updateCoinCount,
  updateTicketCount,
  userAuthorization,
} from "../../redux/actions/mainAction";
import CustomConfetti from "../CustomConfetti";

const MainContainerWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: !isMobile ? "600px" : "90%",
  minWidth: !isMobile ? "340px" : "",
  height: !isMobile ? "725px" : "auto",
  background: "#111827",
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  outline: "none !important",
  alignItems: "center",
  paddingTop: "10px",
  paddingBottom: isMobile ? "10px" : "0px",
}));

const MainContainerWrapperRoot = styled("div")(({ theme }) => ({
  marginTop: "40px",
  display: "flex",
  boxSizing: "border-box",
  position: "relative",
  transition: "5s",
}));

const OutlineContainer = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  border: "3px solid #E2B776",
  borderRadius: "50%",
  position: "relative",
}));

const RotateContainer = styled("div")(({ theme }) => ({
  borderRadius: "50%",
  width: "100%",
  height: "100%",
  position: "relative",
  overflow: "hidden",
  transition: "5s",
  boxSizing: "border-box",
  boxShadow: "0 0 0 5px #E2B776, 0 0 0 18px #F2D9AE, 0 0 0 23px #F7E4C6",
  background: "#E2B776",
}));

const WheelSector = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  position: "absolute",
  minWidth: "0px",
  minHeight: "0px",
  border: "3px solid #E2B776",
  transformOrigin: "bottom right",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  userSelect: "none",
  cursor: "pointer",
}));

const PrizeSectorWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  transform: "rotate(45deg)",
  top: !isMobile ? "10px" : "7px",
  left: !isMobile ? "10px" : "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "5px",
  width: !isMobile ? "100px" : "88px",
}));

const PrizeName = styled("span")(({ theme }) => ({
  lineHeight: "24px",
  textAlign: "right",
  textShadow: "0px 2px 0px 0px rgba(0, 0, 0, 0.25)",
  fontSize: !isMobile ? "12px" : "10px",
  fontStyle: "normal",
  fontWeight: 600,
  background:
    "linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.64) 100%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  maxWidth: !isMobile ? "80px" : "70px",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "auto",
}));

const SpinContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: 10,
  border: "4px solid #E2B776",
  borderRadius: "50%",
}));

const Spin = styled("button")(({ theme }) => ({
  background: "linear-gradient(180deg, #2178FB 0%, #103CA9 100%)",
  border: "1px solid #1241AF",
  borderRadius: "50%",
  cursor: "pointer",
  width: "72px",
  height: "72px",
  boxShadow:
    "0px 4px 0px 0px rgba(255, 255, 255, 0.35) inset, 0px 12px 2px 0px rgba(255, 255, 255, 0.25) inset",
}));

const SpinText = styled("span")(({ theme }) => ({
  background: "linear-gradient(180deg, #FBFFFF 0%, #BBDFEA 100%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  lineHeight: "24px",
  fontWeight: 900,
  fontSize: "20px",
  textAlign: "center",
  textShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
}));

const WinBox = styled("div")(({ theme }) => ({
  marginTop: isMobile ? "30px" : "60px",
  minWidth: "257px",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  background: "rgba(1, 6, 23, 0.25)",
  gap: "16px",
  padding: isMobile ? "16px" : "16px 32px",
  "& :nth-child(1)": {
    fontSize: isMobile ? "10px" : "14px",
    fontWeight: 600,
    lineHeight: isMobile ? "10px" : "16px",
    background: "rgba(255, 255, 255, 0.5)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  "& :nth-child(2)": {
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "24px",
    background: "linear-gradient(180deg, #ECCF9C 1.5%, #E2B776 101.5%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

const BonusWheelModal = ({
  openPrizeModal,
  setOpenPrizeModal,
  wheelPrizes,
  userInfo,
  isUserAuthorized,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const borderWidth = 2.6;
  const startLocation = 60 + borderWidth / 2;
  const [rotate, setRotate] = useState(startLocation);
  const width = isMobile ? 300 : 500;
  const height = isMobile ? 300 : 500;
  const eachItemDeg = 360 / wheelPrizes.length;
  const [spinClickedCount, setSpinClickedCount] = useState(0);
  const [disableSpin, setDisableSpin] = useState(false);
  const [winInfo, setWinInfo] = useState(null);
  const buttonRef = useRef();

  const showSpin = () => {
    if (userInfo.tickets === 0) {
      enqueueSnackbar(t("youDontHaveTickets"), {
        variant: "warning",
      });
      return;
    }

    const calculateStopSpin = (gainPrize) => {
      return spinClickedCount === 0
        ? (spinClickedCount + 1) * 1440 - gainPrize + 15
        : (spinClickedCount + 2) * 1080 - gainPrize + 15;
    };

    if (!isUserAuthorized) {
      enqueueSnackbar(t("pleaseMakeAuthorization"), {
        variant: "warning",
      });
    } else {
      setDisableSpin(true);
      SpinWheel()
        .then((resp) => {
          if (Number(resp.data.message) === 0) {
            window.location.reload();
          }

          setWinInfo(null);
          const findWonSector =
            wheelPrizes
              .sort((a, b) => a.Id - b.Id)
              .findIndex((item) => item.Id === Number(resp.data.message)) + 1;
          const gainPrize = eachItemDeg * findWonSector;
          const stopSpin = calculateStopSpin(gainPrize);
          ShowWinNotification(findWonSector - 1);
          setRotate(Number(stopSpin) + startLocation);

          setSpinClickedCount((prev) => prev + 1);
          dispatch(
            userAuthorization({
              ...userInfo,
              tickets: userInfo.tickets - 1,
            }),
          );
          setTimeout(() => {
            setDisableSpin(false);
          }, 5000);
        })
        .finally(() => {});
    }
  };

  const ShowWinNotification = (winIndex) => {
    setTimeout(() => {
      setWinInfo(wheelPrizes[winIndex]);
      if (wheelPrizes[winIndex].WheelPrizesEnum !== 3) {
        buttonRef.current.click();
      }

      if (wheelPrizes[winIndex].WheelPrizesEnum === 0) {
        dispatch(updateCoinCount(wheelPrizes[winIndex].Count));
      }

      if (wheelPrizes[winIndex].WheelPrizesEnum === 1) {
        dispatch(updateTicketCount(wheelPrizes[winIndex].Count));
      }
    }, 5000);
    setTimeout(() => {
      setWinInfo(null);
    }, 10000);
  };

  const backgroundArray = [
    "linear-gradient(187.63deg, #0A34BC 10.32%, #0A34BC 79.89%)",
    "linear-gradient(205.33deg, #016FFF 19.09%, #016FFF 81.28%)",
    "linear-gradient(110.32deg, #007257 39.63%, #007257 83.45%)",
    "linear-gradient(312.76deg, #00CE02 24.51%, #00CE02 83.84%)",
    "linear-gradient(294.6deg, #FFBC00 21.49%, #FFBC00 82.26%)",
    "linear-gradient(180.73deg, #FF8E00 22.51%, #FF8E00 95.26%)",
    "linear-gradient(54.97deg, #FF5400 31%, #FF5400 83.79%)",
    "linear-gradient(58.28deg, #E41300 19%, #E41300 81.72%)",
    "linear-gradient(90.08deg, #FF0092 6.17%, #FF0092 78.24%)",
    "linear-gradient(249.33deg, #FD02FF 39.73%, #FD02FF 83.94%)",
    "linear-gradient(157.53deg, #6C00D8 19.05%, #6C00D8 82.08%)",
    "linear-gradient(171.78deg, #3200A7 10.02%, #3200A7 79.18%)",
  ];

  const winImageSrc = winInfo
    ? `data:${winInfo.FileFormat};base64,${winInfo.Image}`
    : "";

  const getWinInfoText = () => {
    if (winInfo) {
      if (winInfo.WheelPrizesEnum === 3) {
        return t("unfortunatelyYouLost");
      }

      if (winInfo.WheelPrizesEnum === 0) {
        return `${t("youWin")} ${winInfo.Count} ${t("wonCoins")}`;
      }

      if (winInfo.WheelPrizesEnum === 1) {
        return `${t("youWin")} ${winInfo.Count} ${t("wonTicket")}`;
      }

      if (winInfo.WheelPrizesEnum === 2) {
        return `${t("youWin")} ${winInfo.Count}% ${t("wonOffer")}`;
      }

      return "";
    }
    return "";
  };

  return (
    <>
      <CustomConfetti buttonRef={buttonRef} />
      <Modal
        open={openPrizeModal}
        onClose={() => !disableSpin && setOpenPrizeModal(false)}
        sx={{
          "& .MuiBackdrop-root": {
            border: "none",
          },
          "& .MuiPaper-root": {
            border: "none",
          },
        }}
      >
        <MainContainerWrapper>
          <MainContainerWrapperRoot
            style={{
              width: width,
              height: height,
            }}
          >
            <OutlineContainer>
              <Box
                style={{
                  position: "absolute",
                  top: "25px",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 3,
                }}
              >
                <img src={"assets/testing_imgs/Arrow.png"} alt="arrow" />
              </Box>
              <RotateContainer style={{ transform: `rotate(${rotate}deg)` }}>
                <Box
                  width={width}
                  height={height}
                  position={"relative"}
                  borderRadius={"50%"}
                >
                  {wheelPrizes.map((item, index) => {
                    const imageSrc = `data:${item.FileFormat};base64,${item.Image}`;
                    return (
                      <WheelSector
                        key={index}
                        style={{
                          width: `${width / 2}px`,
                          height: `${height / 2}px`,
                          maxWidth: `${width / 2}px`,
                          maxHeight: `${height / 2}px`,
                          background: backgroundArray[index],
                          transform: `rotate(${index * eachItemDeg - 0.7}deg)`,
                          zIndex: wheelPrizes.length - index,
                          clipPath: `polygon(0 0, ${3.418 * wheelPrizes.length}% 0, 98% 98%, 0 ${3.418 * wheelPrizes.length}%)`,
                        }}
                      >
                        <PrizeSectorWrapper>
                          <img
                            src={imageSrc}
                            alt={"prize_img"}
                            style={{
                              width: !isMobile ? "24px" : "16px",
                              height: !isMobile ? "24px" : "16px",
                              borderRadius: "50%",
                            }}
                          />
                          <PrizeName>{item.Name}</PrizeName>
                        </PrizeSectorWrapper>
                      </WheelSector>
                    );
                  })}
                </Box>
              </RotateContainer>
              <SpinContainer
                style={{
                  opacity: !disableSpin ? 1 : 0.8,
                  transition: "opacity 0.3s ease-in-out",
                }}
              >
                <Spin onClick={() => !disableSpin && showSpin()}>
                  <SpinText>SPIN!</SpinText>
                </Spin>
              </SpinContainer>
            </OutlineContainer>
          </MainContainerWrapperRoot>
          {isUserAuthorized ? (
            <>
              {winInfo ? (
                <WinBox>
                  <span
                    style={{
                      lineHeight: isMobile ? "12px" : "18px",
                    }}
                  >
                    {getWinInfoText()}
                  </span>
                  <img src={winImageSrc} alt={"win image"} width={"30px"} />
                </WinBox>
              ) : (
                <WinBox>
                  <span
                    style={{
                      lineHeight: isMobile ? "12px" : "18px",
                    }}
                  >
                    {t("countOfTicket")}
                  </span>
                  <span>
                    {userInfo && userInfo.tickets ? userInfo.tickets : 0}
                  </span>
                </WinBox>
              )}
            </>
          ) : (
            <WinBox>
              <span
                style={{
                  textAlign: "center",
                  lineHeight: isMobile ? "12px" : "18px",
                }}
              >
                {t("pleaseMakeAuthorization")}
              </span>
            </WinBox>
          )}
        </MainContainerWrapper>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  wheelPrizes: state.main.wheelPrizes,
  userInfo: state.main.userInfo,
  isUserAuthorized: state.main.isUserAuthorized,
});

const dispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, dispatchToProps)(BonusWheelModal);
