import AppRoutes from "./AppRoutes";
import { useEffect } from "react";
import { getLocalStorageValue } from "../util/appUtil";
import { GetUserFullInfo } from "../api/services/Auth.api";
import {
  ChangeWheelPrizes,
  LogOutDispatch,
  userAuthorization,
} from "../redux/actions/mainAction";
import { connect, useDispatch } from "react-redux";
import { restoreSavedProductFromLocalStorage } from "../redux/actions/savedProductsAction";
import { GetAllWheelPrizes } from "../api/services/wheel.api";
import { useLocation } from "react-router-dom";

const ApplicationContainer = ({
  setMainLoading,
  cardContainerRef,
  isUserAuthorized,
  isMobileVersion,
  footerRef,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const isUserAuthorized = getLocalStorageValue("isUserAuthorized", false);
    if (isUserAuthorized === "true") {
      GetUserFullInfo()
        .then((resp) => {
          dispatch(userAuthorization(resp.data));
          getAllBonusPrizes();
        })
        .catch(() => {
          dispatch(LogOutDispatch());
        });
    } else {
      getAllBonusPrizes();
    }

    const savedProductList = getLocalStorageValue("savedProductItems", "");
    if (savedProductList) {
      dispatch(
        restoreSavedProductFromLocalStorage(JSON.parse(savedProductList)),
      );
    }
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (isMobileVersion) {
      if (location.pathname === "/category") {
        footerRef.current.style.display = "none";
      } else {
        footerRef.current.style.display = "block";
      }
    }

    console.log(location);
  }, [location]);

  const getAllBonusPrizes = () => {
    GetAllWheelPrizes().then((resp) => {
      dispatch(ChangeWheelPrizes(JSON.parse(resp.data.data)));
    });
  };

  return (
    <AppRoutes
      cardContainerRef={cardContainerRef}
      isUserAuthorized={isUserAuthorized}
      setMainLoading={setMainLoading}
      isMobileVersion={isMobileVersion}
    />
  );
};

const mapStateToProps = (state) => ({
  isUserAuthorized: state.main.isUserAuthorized,
});

const dispatchToProps = () => ({});

export default connect(mapStateToProps, dispatchToProps)(ApplicationContainer);
