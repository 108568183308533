import TermsAndConditionsPage from "../../components/TermsAndConditions/TermsAndConditionsPage";

const TermsAndConditionsContainer = () => {
  return (
    <>
      <TermsAndConditionsPage />
    </>
  );
};

export default TermsAndConditionsContainer;
