const MenuLaptopIcon = () => {
  return (
    <svg
      fill="#000000"
      width="64px"
      height="64px"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <title></title>
        <g data-name="Layer 2" id="Layer_2">
          <path d="M27.28,28H4.72A2,2,0,0,1,3,25H3l2.85-5a2,2,0,0,1,1.74-1H24.42a2,2,0,0,1,1.74,1L29,25a2,2,0,0,1-1.73,3ZM4.72,26H27.28l-2.86-5H7.58Z"></path>
          <path d="M25,21H7a1,1,0,0,1-1-1V6A2,2,0,0,1,8,4H24a2,2,0,0,1,2,2V20A1,1,0,0,1,25,21ZM8,19H24V6H8Z"></path>
          <path d="M18,25H14a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Z"></path>
        </g>
      </g>
    </svg>
  );
};

export default MenuLaptopIcon;
